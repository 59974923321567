import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IOptPlant } from '../plant/opt_plant';
import { ICompany } from '../company/company';
import { Router, ActivatedRoute } from '@angular/router';
import { TeamService } from '../services/team/team.service';
import { PlantlistService } from '../services/plant/plantlist.service';
import { CompanylistService } from '../services/company/companylist.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {

  message: any;
  pageAct: string = "Create";
  hiddenId: boolean = false;
  teamForm: FormGroup;
  plant: IOptPlant[];
  companies: ICompany[];
  nodata: boolean = false;
  plantselected: number = 0;
  schId: any;

  constructor(private fb: FormBuilder,
              private router: Router,
              private teamapi: TeamService,
              private route: ActivatedRoute,
              private plantservice: PlantlistService, 
              private companylistService: CompanylistService, 
              private toastr: ToastrService) { }

  token: any;
  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.token = window.localStorage.getItem('token');
    if(!this.token){
      this.router.navigate(['']);
    }

    this.companylistService.companyData().subscribe((data: ICompany[]) => {
      this.companies = data;
    });

    if(this.route.snapshot.paramMap.get('actid'))
    {
      let actid = parseInt(this.route.snapshot.paramMap.get('actid'));
      let id = parseInt(this.route.snapshot.paramMap.get('id'));
      this.schId = id;
      this.pageAct = "Update";
      this.hiddenId = true;
      this.teamapi.getTeam(actid).subscribe((data: any) => {
        this.teamForm.patchValue(data);
        this.plantservice.optPlantData(data.companyId).subscribe((data: IOptPlant[]) => {
          this.plant = data;
        }, (error) => {
          this.plant = [];
        });
      })

      this.teamForm = this.fb.group({
        excelUploadId: [''],
        scheduleId: [this.schId],
        projectNo: ['', [Validators.required]],
        qcName: ['', [Validators.required]],
        companyId: ['', [Validators.required]],
        plantId: ['', Validators.required],
        projectTheme: ['', [Validators.required]],
        baseLine: ['', Validators.required],
        target: ['', Validators.required],
        savings: ['', [Validators.required]]
      });
      
    } else {
      let id = parseInt(this.route.snapshot.paramMap.get('id'));
      console.log(id);
      this.schId = id;
      this.pageAct = "Assign";
      this.hiddenId = false;

      this.teamForm = this.fb.group({
        scheduleId: [this.schId],
        projectNo: ['', [Validators.required]],
        qcName: ['', [Validators.required]],
        companyId: ['', [Validators.required]],
        plantId: ['', Validators.required],
        projectTheme: ['', [Validators.required]],
        baseLine: ['', Validators.required],
        target: ['', Validators.required],
        savings: ['', [Validators.required]]
      });

    }

  }

  get errscheduleId() { return this.teamForm.get('scheduleId'); }
  get errqcName() { return this.teamForm.get('qcName'); }
  get errcompanyId() { return this.teamForm.get('companyId'); }
  get errplantId() { return this.teamForm.get('plantId'); }
  get errprojectTheme() { return this.teamForm.get('projectTheme'); }
  get errprojectNo() { return this.teamForm.get('projectNo'); }
  get errbaseLine() { return this.teamForm.get('baseLine'); }
  get errtarget() { return this.teamForm.get('target'); }
  get errsavings() { return this.teamForm.get('savings'); }

  getbusiUnit(event: any){
    this.plantservice.optPlantData(event).subscribe((data: IOptPlant[]) => {
      this.plant = data;
    }, (error) => {
      this.teamForm.get('plantId').patchValue('');
      this.plant = [];
    });
  }

  submitTeam(act){
    if(this.teamForm.invalid)
    {
      return;
    }
    if(act == "Assign") {
      this.teamapi.addTeam(this.teamForm.value)
      .subscribe(data => {
        this.router.navigate(['/ScheduleView/'+data["scheduleId"]]);
        this.toastr.success('Team Added Successfully');
      })
    }
    if(act == "Update") {
      this.teamapi.updateTeam(this.teamForm.value).subscribe(data => {
        this.router.navigate(['/ScheduleView/'+data["scheduleId"]]);
        this.toastr.success('Team Updated Successfully');
      })
    }
  }

}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IBusinessUnit } from 'src/app/business-unit/businessunit';
import { IOptBusinessUnit } from 'src/app/business-unit/opt_businessunit';

@Injectable({
  providedIn: 'root'
})
export class BusinessunitlistService {

  private _url: string = environment.api_url;

  constructor(private _http: HttpClient) { }

  businessUnitData(): Observable<IBusinessUnit[]>{
    return this._http.get<IBusinessUnit[]>(this._url+'/businessUnitList.php');
  }

  optBusinessUnitData(): Observable<IOptBusinessUnit[]>{
    return this._http.get<IOptBusinessUnit[]>(this._url+'/optBusinessUnitList.php');
  }

  deletebusinessUnit(id){
    return this._http.delete<IBusinessUnit>(this._url+'/deleteBusinessUnit.php?id='+id)
  }

}

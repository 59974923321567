import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IDepartment } from 'src/app/department/department';
import { IOptDepartment } from 'src/app/schedule/opt_department';

@Injectable({
  providedIn: 'root'
})
export class DepartmentlistService {

  private _url: string = environment.api_url;
  constructor(private _http: HttpClient) { }

  departmentData(): Observable<IDepartment[]>{
    return this._http.get<IDepartment[]>(this._url+'/departmentList.php');
  }

  optDepartmentData(): Observable<IOptDepartment[]>{
    return this._http.get<IOptDepartment[]>(this._url+'/optDepartmentList.php');
  }

  deleteDepartment(id){
    return this._http.delete<IDepartment>(this._url+'/deleteDepartment.php?id='+id)
  }
}

<div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between">
       <div class="iq-header-title">
          <h4 class="card-title">Schedule View</h4>
       </div>
        <div class="add">
            <a routerLink="/Schedule_List" mat-stroked-button color="warn">Back to Schedule List</a>
        </div>
        <div *ngIf="shEmployee" class="pull-right add">
            <a routerLink="/Employee/{{ shdId }}" mat-stroked-button color="primary">Add Employee</a>
        </div>
        <div *ngIf="shTeam" class="pull-right add">
            <a routerLink="/Team/{{ shdId }}" mat-stroked-button color="primary">Add Team</a>
        </div>
    </div>
    <div class="iq-card-body company-list">
    <div class="form-group row">
        <div class="col-sm-12 col-lg-12">
            <div class="mat-elevation-z8">
                <mat-form-field appearance="outline" class="filter-dt">
                    <mat-label>Filter Data</mat-label>
                    <input (keyup)="applyFilter($event.target.value)" matInput placeholder="Filter Data">
                    <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
                    <!-- <mat-hint>Hint</mat-hint> -->
                  </mat-form-field>

                <table mat-table matSort [dataSource]="dataSource">
              
                  <!-- Position Column -->
                  <ng-container matColumnDef="sno">
                    <th mat-header-cell *matHeaderCellDef> # </th>
                    <td mat-cell *matCellDef="let element; let i = index;"> {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (i + 1) }}. </td>
                  </ng-container>
              
                  <!-- Name Column -->
                  <ng-container matColumnDef="employeeId" *ngIf="shEmployee">
                    <th mat-header-cell *matHeaderCellDef> Employee No. </th>
                    <td mat-cell *matCellDef="let element"> {{element.employeeId}} </td>
                  </ng-container>
              
                  <!-- Weight Column -->
                  <ng-container matColumnDef="employeeName" *ngIf="shEmployee">
                    <th mat-header-cell *matHeaderCellDef> Employee Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.employeeName}} </td>
                  </ng-container>
              
                  <!-- Symbol Column -->
                  <ng-container matColumnDef="companyId">
                    <th mat-header-cell *matHeaderCellDef> Company </th>
                    <td mat-cell *matCellDef="let element"> {{element.companyId}} </td>
                  </ng-container>

                  <!-- Symbol Column -->
                  <ng-container matColumnDef="businessUnitId" *ngIf="shEmployee">
                    <th mat-header-cell *matHeaderCellDef> Business Unit </th>
                    <td mat-cell *matCellDef="let element"> {{element.businessUnitId}} </td>
                  </ng-container>

                  <!-- Symbol Column -->
                  <ng-container matColumnDef="plantId">
                    <th mat-header-cell *matHeaderCellDef> Plant </th>
                    <td mat-cell *matCellDef="let element"> {{element.plantId}} </td>
                  </ng-container>

                  <!-- Symbol Column -->
                  <ng-container matColumnDef="qcName" *ngIf="shTeam">
                    <th mat-header-cell *matHeaderCellDef> QC Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.qcName}} </td>
                  </ng-container>

                  <ng-container matColumnDef="projectTheme">
                    <th mat-header-cell *matHeaderCellDef> Project Theme </th>
                    <td mat-cell *matCellDef="let element"> {{element.projectTheme}} </td>
                  </ng-container>

                  <ng-container matColumnDef="projectNo">
                    <th mat-header-cell *matHeaderCellDef> Project No. </th>
                    <td mat-cell *matCellDef="let element"> {{element.projectNo}} </td>
                  </ng-container>

                  <ng-container matColumnDef="baseLine">
                    <th mat-header-cell *matHeaderCellDef> Base Line </th>
                    <td mat-cell *matCellDef="let element"> {{element.baseLine}} </td>
                  </ng-container>

                  <ng-container matColumnDef="target">
                    <th mat-header-cell *matHeaderCellDef> Target </th>
                    <td mat-cell *matCellDef="let element"> {{element.target}} </td>
                  </ng-container>

                  <ng-container matColumnDef="savings">
                    <th mat-header-cell *matHeaderCellDef> Saving </th>
                    <td mat-cell *matCellDef="let element"> {{element.savings}} </td>
                  </ng-container>

                  <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>Actions </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="shEmployee" class="material-icons icon-dt" title="Edit" routerLink="/Employee/{{ element.excelUploadId }}/{{ element.scheduleId }}">
                          edit
                        </span>
                        <span *ngIf="shTeam" class="material-icons icon-dt" title="Edit" routerLink="/Team/{{ element.excelUploadId }}/{{ element.scheduleId }}">
                          edit
                        </span>&nbsp;&nbsp;
                        <span mwlConfirmationPopover [popoverTitle]="popoverTitle" [popoverMessage]="popoverMessage" placement="left" (confirm)="delete(element)" (cancel)="cancelClicked=true" class="material-icons text-danger icon-dt" title="Delete">
                          delete_outline
                        </span>
                    </td>
                  </ng-container>
                  
                  <div *ngIf="shTeam">
                    <tr mat-header-row *matHeaderRowDef="displayedTeamColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedTeamColumns;"></tr>
                  </div>
                  <div *ngIf="shEmployee">
                    <tr mat-header-row *matHeaderRowDef="displayedEmployeeColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedEmployeeColumns;"></tr>
                  </div>
                </table>

                <div *ngIf="dataSource.data.length === 0">
                    No Records Found!
                </div>
              
                <mat-paginator #paginatorRef [pageSize]="50" [pageSizeOptions]="[50, 100, 150]" showFirstLastButtons></mat-paginator>
              </div>





            <!-- <table class="table">
                <tr>
                    <th>#</th>
                    <th class="text-left">Employee Number</th>
                    <th class="text-left">Employee Name</th>
                    <th class="text-left">Company</th>
                    <th class="text-left">Business Unit</th>
                    <th class="text-left">Plant</th>
                    <th class="text-left">Project Theme</th>
                    <th class="text-center">Project No</th>
                    <th class="text-center">Base Line</th>
                    <th class="text-center">Target</th>
                    <th class="text-center">Savings</th>
                    <th class="text-center">Status</th>
                </tr>
                <tr *ngFor="let scheduleview of schedulesview; let sch_v = index">
                    <td>{{ sch_v+1 }}.</td>
                    <td class="text-left">{{ scheduleview.employeeId }}</td>
                    <td class="text-left">{{ scheduleview.employeeName }}</td>
                    <td class="text-left">{{ scheduleview.companyId }}</td>
                    <td class="text-left">{{ scheduleview.businessUnitId }}</td>
                    <td class="text-left">{{ scheduleview.plantId }}</td>
                    <td class="text-left">{{ scheduleview.projectTheme }}</td>
                    <td class="text-center">{{ scheduleview.projectNo }}</td>
                    <td class="text-center">{{ scheduleview.baseLine }}</td>
                    <td class="text-center">{{ scheduleview.target }}</td>
                    <td class="text-center">{{ scheduleview.savings }}</td>
                    <td class="text-center">
                        <div class="custom-control custom-control-inline">
                            <a routerLink="/ScheduleViewEdit/{{ scheduleview.excelUploadId }}" title="Edit Employee" class="edit"><i class="lar la-edit"></i></a>
                            <a (click)=delete(scheduleview) class="delete" title="Delete Employee"><i class="lar la-trash-alt"></i></a>
                        </div>
                    </td>
                </tr>
            </table> -->
        </div>
    </div>
                                  
    </div>
 </div>
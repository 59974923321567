import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private sessionDisable = new BehaviorSubject<boolean>(false);
  mszSession$ = this.sessionDisable.asObservable();

  private profileMobile = new BehaviorSubject<boolean>(false);
  mszAdminMobile$ = this.profileMobile.asObservable();

  constructor() { }

  sendMessage(message: boolean) {
    this.sessionDisable.next(message);
  }

  sendAdminMessage(message: any) {
    this.profileMobile.next(message);
  }

}

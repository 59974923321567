<div class="iq-card">
  <div class="iq-card-header d-flex justify-content-between">
    <div class="iq-header-title">
      <h4 class="card-title">{{ pageAct }} Company</h4>
    </div>
    <div class="pull-right add">
      <a routerLink="/Company_List" mat-stroked-button color="primary">Company List</a>
   </div>
  </div>
  <div class="iq-card-body">
    <strong class="red">* Fields are Required</strong>
    <div class="form-horizontal" [formGroup]="companyForm">

      <div class="form-group row" [ngClass]="{'has-error': (errCompanyName.touched || errCompanyName.dirty) && errCompanyName.invalid}">
        <label class="control-label col-sm-2 align-self-center mb-0" for="department_name">Company Name <sup class="red">*</sup></label>
        <div class="col-sm-5">
          <mat-form-field class="example-full-width" appearance="outline">
             <mat-label>Company Name</mat-label>
             <input matInput placeholder="Company Name" formControlName="companyName" >
             <mat-hint *ngIf="(errCompanyName.touched || errCompanyName.dirty) && errCompanyName.invalid"><span *ngIf="errCompanyName.errors?.required" class="error-msg">Company Name is Required</span></mat-hint>
           </mat-form-field>
        </div>
     </div>

      <div class="form-group row" [ngClass]="{'has-error': (errCompanyCode.touched || errCompanyCode.dirty) && errCompanyCode.invalid}">
        <label class="control-label col-sm-2 align-self-center mb-0" for="department_name">Company Code <sup class="red">*</sup></label>
        <div class="col-sm-5">
          <mat-form-field class="example-full-width" appearance="outline">
             <mat-label>Company Code</mat-label>
             <input matInput type="text" placeholder="Company Code" formControlName="companyCode" >
             <mat-hint *ngIf="(errCompanyCode.touched || errCompanyCode.dirty) && errCompanyCode.invalid"><span *ngIf="errCompanyCode.errors?.required" class="error-msg">Company Code is required</span></mat-hint>
             <mat-hint *ngIf="(errCompanyCode.touched || errCompanyCode.dirty) && errCompanyCode.errors?.pattern"><span class="error-msg">Company Code can only be Alpha Numeric.</span></mat-hint>
             <mat-hint *ngIf="errCompanyCode.pending"><span class="success-msg">Company Code is Validating.</span></mat-hint>
             <mat-hint *ngIf="errCompanyCode.errors?.codeExists"><span class="error-msg">Company Code is already exists.</span></mat-hint>
           </mat-form-field>
        </div>
     </div>

      <div class="form-group row">
        <div class="col-md-7 text-right">
          <input type="hidden" *ngIf="hiddenId" value="" formControlName="companyId" />
          <button type="button" [disabled]="!companyForm.valid"  (click)="submitCompany(pageAct)"  mat-raised-button color="primary">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  
  public _url: string = environment.api_url;

  constructor(private _http: HttpClient) { }

  addProfile(profile):Observable<any>{
    let httpheaders = new HttpHeaders().set('Content-Type','application/Json');
    let options = {
      headers: httpheaders
    };
    return this._http.post<any>(this._url+'/addProfile.php',profile, options);
  }

  getProfile(id:number){
    return this._http.get<any>(this._url+'/eachProfile.php?id='+id);
  }

  updateProfile(profile: any){
    return this._http.put(this._url+'/updateProfile.php?id='+profile.profileId, profile);
  }

}

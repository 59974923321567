import { Component, OnInit, ViewChild } from '@angular/core';
import { IPlant } from '../plant/plant';
import { Router } from '@angular/router';
import { PlantlistService } from '../services/plant/plantlist.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-plantlist',
  templateUrl: './plantlist.component.html',
  styleUrls: ['./plantlist.component.css']
})
export class PlantlistComponent implements OnInit {

  displayedColumns: string[] = ['sno','companyId', 'businessUnitId', 'plantName', 'plantCode', 'plantLocation', 'plantId'];
  dataSource = new MatTableDataSource;

  plants: IPlant[];
  constructor(private router: Router, private plantlistService: PlantlistService, private toastr: ToastrService) { }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  token: any;
  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.token = window.localStorage.getItem('token');
    if(!this.token){
      this.router.navigate(['']);
    }

    this.dataSource.paginator = this.paginator;

    this.plantlistService.plantData().subscribe((data: IPlant[]) => {
      this.plants = data;
      this.dataSource.data = data;
    });
  }

  delete(plants: IPlant):void {
    this.plantlistService.deletePlant(plants.plantId ).subscribe(data => { 
      this.plants = this.plants.filter(u => u !== plants)

      this.dataSource = new MatTableDataSource(this.plants);
      this.dataSource.paginator = this.paginator;

      this.toastr.success('Plant Deleted Successfully');
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

}

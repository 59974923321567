import { Component, OnInit, ViewChild } from '@angular/core';
import { CompanylistService } from '../services/company/companylist.service';
import { Router } from '@angular/router';
import { ICompany } from '../company/company';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.css']
})
export class CompanyListComponent implements OnInit {
  
  displayedColumns: string[] = ['sno','companyName', 'companyCode', 'companyId'];
  dataSource = new MatTableDataSource;
  companies: ICompany[];
  constructor(private router: Router, private companylistService: CompanylistService, private toastr: ToastrService) { }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  
  token: any;
  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.token = window.localStorage.getItem('token');
    if(!this.token){
      this.router.navigate(['']);
    }

    this.dataSource.paginator = this.paginator;

    this.companylistService.companyData().subscribe((data: ICompany[]) => {
      this.companies = data;
      this.dataSource.data = data;
    });

  }

  delete(companies: ICompany):void {
    this.companylistService.deleteCompany(companies.companyId ).subscribe(data => { 
      this.companies = this.companies.filter(u => u !== companies)

      this.dataSource = new MatTableDataSource(this.companies);
      this.dataSource.paginator = this.paginator;
      
      this.toastr.success('Company Deleted Successfully');
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

}

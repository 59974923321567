<div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between">
      <div class="iq-header-title">
        <h4 class="card-title">Profile</h4>
      </div>
    </div>
    <div class="iq-card-body">
      <strong class="red">* Fields are Required</strong>
      <div class="form-horizontal" [formGroup]="profileForm">
  
        <div class="form-group row" [ngClass]="{'has-error': (errProfileName.touched || errProfileName.dirty) && errProfileName.invalid}">
          <label class="control-label col-sm-2 align-self-center mb-0" for="department_name">Admin Name <sup class="red">*</sup></label>
          <div class="col-sm-5">
            <mat-form-field class="example-full-width" appearance="outline">
               <mat-label>Admin Name</mat-label>
               <input matInput placeholder="Profile Name" formControlName="profileName" >
               <mat-hint *ngIf="(errProfileName.touched || errProfileName.dirty) && errProfileName.invalid"><span *ngIf="errProfileName.errors?.required" class="error-msg">Admin Name is Required</span></mat-hint>
             </mat-form-field>
          </div>
       </div>
  
        <div class="form-group row" [ngClass]="{'has-error': (errProfileMobile.touched || errProfileMobile.dirty) && errProfileMobile.invalid}">
          <label class="control-label col-sm-2 align-self-center mb-0" for="department_name">Profile Mobile <sup class="red">*</sup></label>
          <div class="col-sm-5">
            <mat-form-field class="example-full-width" appearance="outline">
               <mat-label>Profile Mobile</mat-label>
               <input matInput type="number" (keyup)="chkMobile($event)" placeholder="Profile Mobile" formControlName="profileMobile" >
               <mat-hint *ngIf="(errProfileMobile.touched || errProfileMobile.dirty) && errProfileMobile.invalid"><span *ngIf="errProfileMobile.errors?.required" class="error-msg">Profile Mobile is required only with numbers </span></mat-hint>
             </mat-form-field>
          </div>
       </div>

       <div class="form-group row" [ngClass]="{'has-error': (errUsername.touched || errUsername.dirty) && errUsername.invalid}">
        <label class="control-label col-sm-2 align-self-center mb-0" for="department_name">Username <sup class="red">*</sup></label>
        <div class="col-sm-5">
          <mat-form-field class="example-full-width" appearance="outline">
             <mat-label>Username</mat-label>
             <input matInput placeholder="Uesrname" formControlName="profileUsername" >
             <mat-hint *ngIf="(errUsername.touched || errUsername.dirty) && errUsername.invalid"><span *ngIf="errUsername.errors?.required" class="error-msg">Username is Required</span></mat-hint>
           </mat-form-field>
        </div>
     </div>

     <div class="form-group row" [ngClass]="{'has-error': (errPassword.touched || errPassword.dirty) && errPassword.invalid}">
        <label class="control-label col-sm-2 align-self-center mb-0" for="department_name">Password <sup class="red">*</sup></label>
        <div class="col-sm-5">
          <mat-form-field class="example-full-width" appearance="outline">
             <mat-label>Password</mat-label>
             <input matInput placeholder="Passowrd" formControlName="profilePassword" >
             <mat-hint *ngIf="(errPassword.touched || errPassword.dirty) && errPassword.invalid"><span *ngIf="errPassword.errors?.required" class="error-msg">Password is Required</span></mat-hint>
           </mat-form-field>
        </div>
     </div>
  
        <div class="form-group row">
          <div class="col-md-7 text-right">
            <input type="hidden" *ngIf="hiddenId" value="" formControlName="profileId" />
            <button type="button" [disabled]="!profileForm.valid"  (click)="submitProfile(pageAct)"  mat-raised-button color="primary">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { JuryEvaluationService } from '../services/JuryEvaluation/jury-evaluation.service';
import { MessageService } from '../services/JuryEvaluation/message.service';
import { JurylogoutService } from '../services/jurylogout/jurylogout.service';
import { SearchService } from '../services/search/search.service';

@Component({
  selector: 'app-dayendnote',
  templateUrl: './dayendnote.component.html',
  styleUrls: ['./dayendnote.component.css']
})
export class DayendnoteComponent implements OnInit {

  juryScheduleID: any;
  chkev: boolean = false;
  dayEndTxt: any;
  evoId: any;

  constructor(private router: Router, private snackBar: MatSnackBar, private messageData: MessageService, private evaluationapi: JuryEvaluationService, private JurylogoutService: JurylogoutService, private matDialog: MatDialog, private searchapi: SearchService) { }

  public sessionDisable: boolean;

  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.juryScheduleID = window.localStorage.getItem('jsch_j_id');

    this.searchapi.getevaluation(this.juryScheduleID).subscribe((evdata: any) => {
      this.evoId =  evdata.ev;
      if(this.evoId)
      {
        this.dayEndTxt = 'Day End';
      } else {
        this.dayEndTxt = 'Day End & View Results';
      }
    });
  }

  cancel() {
    this.messageData.sendMessage(false);

    this.messageData.mszSession$.subscribe(
      message => {
        this.sessionDisable = message;
      }
    );
    
    this.snackBar.dismiss();
  }

  proceed() {
    this.chkev = true;

    this.juryScheduleID = window.localStorage.getItem('jsch_j_id');
    const formData = new FormData();
    formData.append('jury_schedule_id', this.juryScheduleID);

    if(this.evoId) {
      this.evaluationapi.submitDay(formData).subscribe((data) => {
        let snackBarRef = this.snackBar.open('Day ended successfully.', '', { horizontalPosition: 'center', verticalPosition: 'top', duration: 3000 });
  
        setTimeout(() => {
          this.matDialog.closeAll();
        }, 3000);
      });
    } else {
      

      this.evaluationapi.submitDay(formData).subscribe((data) => {
        let snackBarRef = this.snackBar.open('Your Day evaluation has been submitted', '', { horizontalPosition: 'center', verticalPosition: 'top', duration: 3000 });
  
        setTimeout(() => {
          this.matDialog.closeAll();
          window.localStorage.removeItem('token');
          window.localStorage.removeItem('jtoken');
          if(window.localStorage.getItem('tokenact') == "admin")
          {
            this.router.navigate(['']);
          } else if(window.localStorage.getItem('tokenact') == "jury")
          {
            this.router.navigate(['/EvaluationResults']);
          }
        }, 3000);
      });
    }
    
  }

}

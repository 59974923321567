import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { ResultsummaryComponent } from '../resultsummary/resultsummary.component';
import { JuryEvaluationService } from '../services/JuryEvaluation/jury-evaluation.service';
import { GetemployeeService } from '../services/search/getemployee.service';
import { SearchService } from '../services/search/search.service';
import { ISearchFilter } from './searchFilter';
import * as $ from 'jquery';
import { MessageService } from '../services/JuryEvaluation/message.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HeadermessageService } from '../services/dashboard/headermessage.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-juryevaluation',
  templateUrl: './juryevaluation.component.html',
  styleUrls: ['./juryevaluation.component.css']
})
export class JuryevaluationComponent implements OnInit {

  keyword = 'emp_name';
  data = [];
  juryScheduleID: any;
  searchData: ISearchFilter[];
  shJuryDetails: boolean = false;
  shQueDetails: boolean = false;
  employeeData: any;
  questionData: any;
  summaryData: any;
  title:any = "";
  tot_questions:number = 0;
  totSubQuestions:number = 0;
  givenMarks: number = 0;
  disSearch: boolean = true;
  evoId: any;
  disDayEnd: boolean = false;
  dissummary: boolean = false;
  businessUnitName : any;
  plantName : any;
  evaluationType : any;
  uploadFile : any = false;
  selectedFileName: any = "";
  questionForm: FormGroup;
  pageAct: string = "Create";
  public imagePath;
  msz: boolean;
  employeeID: any = 0;
  which = 'window';
  pageHead: string;
  guideLines: any;
  saveEvaluation: any = false;

  sessionDisable: boolean;

  showSpinner: boolean = true;

  url: string = environment.url;

  constructor(private router:Router, 
    private searchapi: SearchService, 
    private getemplolyeeapi: GetemployeeService, 
    private evaluationapi: JuryEvaluationService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private fb: FormBuilder,
    public dialog: MatDialog, 
    private http: HttpClient, 
    private messageData: MessageService, 
    private snackBar: MatSnackBar, 
    private headerData: HeadermessageService) { }

   // @ViewChild('title0') MyProp: ElementRef; 
  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.juryScheduleID = window.localStorage.getItem('jsch_j_id');

    this.headerData.sendMessage('Manage Evaluation');
    this.headerData.mszHead$.subscribe(
      message => {
        this.pageHead = message;
      }
    );

    this.searchapi.getevaluation(this.juryScheduleID).subscribe((evdata: any) => {
      this.businessUnitName = evdata.businessUnitName;
      this.plantName = evdata.plantName;
      this.evaluationType = evdata.evaluationType;
      this.guideLines = evdata.guidelines;
      this.evoId =  evdata.ev;
      //console.log(this.evoId);

      if(evdata.message == 1)
      {
        this.msz = true;

        if(evdata.ev == 1)
        {
          this.disSearch = false;
          this.getemplolyeeapi.getEmployeeQuestion(evdata.scheduleId).subscribe((data: any) => {
              this.tot_questions = data.all_questions_count['tot_questions'];
              this.givenMarks = data.all_questions_count['awarded_marks'];
              this.questionData = data.questions;
              this.totSubQuestions = data.all_questions_count['tot_subQuestions'];
              this.shQueDetails = true;
              this.disDayEnd = false;
              this.uploadFile = true;
              this.dissummary = true;
              setTimeout(() => {
                this.showSpinner = false;
              }, 1500);
          });
        } else {
          this.disSearch = true;
          this.searchapi.getjuryschedule(this.juryScheduleID).subscribe((data: any) => {
            //console.log(data);
            if(data.other['searchData'])
            {
              this.searchData = data.serachDetails;
              this.disDayEnd = false;
              this.dissummary = false;
              setTimeout(() => {
                this.showSpinner = false;
              }, 1500);
            } else {
              this.disSearch = false;
              if(data.other['sessionend']) {
                this.msz = false;
              } else {
                this.msz = true;
              }
              
              setTimeout(() => {
                this.showSpinner = false;
              }, 1500);
            }
          });
        }
      } else {
        this.msz = false;
        setTimeout(() => {
          this.showSpinner = false;
        }, 1500);
      }
    
    });
    
  }

  get questionMarks() {
    return this.questionForm.get('questionMarks') as FormArray;
  }

  selectEvent(item) {
    this.employeeID = item.id;
    this.getemplolyeeapi.getsingleEmployee(item.id).subscribe((data: any) => {
      this.employeeData = data;
      this.shJuryDetails = true;
      this.getemplolyeeapi.getEmployeeQuestion(data.scheduleId).subscribe((data: any) => {

        this.tot_questions = data.all_questions_count['tot_questions'];
        this.questionData = data.questions;
        this.totSubQuestions = data.all_questions_count['tot_subQuestions'];
        this.shQueDetails = true;
        this.disDayEnd = true;
        this.dissummary = true;
      });
    });
  }

  summary(){
    const formData = new FormData();
    formData.append('jury_schedule_id', this.juryScheduleID);
    formData.append('evId', this.evoId);

    this.evaluationapi.getSummary(formData).subscribe((data) => {
      if(data.sumData)
      {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.width = "80%";
        dialogConfig.height = "70%";
        dialogConfig.data = { summ_data : data.datalist, evoid : data.evid, totalAssigned : data.total, completedAssigned : data.completed, remainAssigned : data.pending };
        this.dialog.open(ResultsummaryComponent,dialogConfig);
        
        setTimeout(() => {
          this.showSpinner = false;
        }, 1500);
      } else {
        let snackBarRef = this.snackBar.open('Evaluation submission has not yet done...','', { duration : 3000, horizontalPosition: 'center', verticalPosition: 'top' });
      }
    });

  }
 
  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }
  
  onFocused(e){
    // do something when input is focused
  }

  q_mark(event,queActualMark,mark)
  {
    if(Number(event.target.value) > 0) {
      if(Number(event.target.value) > Number(queActualMark)) {
        let snackBarRef = this.snackBar.open('Evaluated Mark should not be greater than its Question Mark','', { duration : 3000, horizontalPosition: 'center', verticalPosition: 'top' });
        
        let decimalRemove = mark['QueMark'].split('.00');
        if(event.target.value.length > ((decimalRemove.length)+1)) {
          mark['QueMark'] = '';
          event.target.value = '';
        } else {
          const s = event.target.value;
          const withoutLastChunk = s.slice(0, s.lastIndexOf("_"));
          mark['QueMark'] = withoutLastChunk;
          event.target.value = withoutLastChunk;
        }
        
      }
    }
    let quId = event.target.id.split("_");
    let sum = 0;
    for (let i = 0; i < document.querySelectorAll('.qMark').length; i++) {
      sum+=Number((<HTMLInputElement>document.querySelectorAll('.qMark')[i]).value);
    }
    this.givenMarks = sum;
  }

  sq_mark(event,queActualMark,mark)
  {
    if(Number(event.target.value) > 0) {
      if(Number(event.target.value) > Number(queActualMark)) {
        let snackBarRef = this.snackBar.open('Evaluated Mark should not be greater than its Question Mark','', { duration : 3000, horizontalPosition: 'center', verticalPosition: 'top' });
        
        let decimalRemove = mark['SubQueMark'].split('.00');
        if(event.target.value.length > ((decimalRemove.length)+1)) {
          mark['SubQueMark'] = '';
          event.target.value = '';
        } else {
          const s = event.target.value;
          const withoutLastChunk = s.slice(0, s.lastIndexOf("_"));
          mark['SubQueMark'] = withoutLastChunk;
          event.target.value = withoutLastChunk;
        }
        
      }
    }
    let quId = event.target.id.split("_");
    let sum = 0;
    for (let i = 0; i < document.querySelectorAll('.qMark').length; i++) {
      sum+=Number((<HTMLInputElement>document.querySelectorAll('.qMark')[i]).value);
    }
    this.givenMarks = sum;
  }
    
  selectImage(event) {


    const selectedfile = event.target.files[0];
    this.selectedFileName = selectedfile.name;
    console.log(this.selectedFileName);
    


    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
           this.imagePath = event.target.files;
           const formData = new FormData();
       for (const file of this.imagePath) {
        formData.append('image', file, file.name);
        }
      formData.append('jury_schedule_id', this.juryScheduleID);
      formData.append('que_id', event.target.id);
      formData.append('evId', this.evoId);

        this.evaluationapi.uploadFile(formData).subscribe(() => {
          this.toastr.success('Photo Uploaded Successfully');
        });

      } 
  }

  submitMarks(evaluationForm: NgForm){
    this.saveEvaluation = true;
    this.showSpinner = true;
     
    this.evaluationapi.addScheduleEvaluation(evaluationForm.form.value).subscribe((data) => {
      //console.log(data);
      setTimeout(() => {
        this.showSpinner = false;
      }, 1500);
      this.givenMarks = 0;
      this.tot_questions = 0;
      this.msz = data.take_ev;
      this.router.navigate(['/JuryEvaluation']);
      this.shJuryDetails = false;
      this.shQueDetails = false;
      this.ngOnInit();
      this.toastr.success('Evaluation Saved Successfully');
      this.saveEvaluation = false;
    });
  
  }

}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IPlant } from '../plant/plant';
import { Router, ActivatedRoute } from '@angular/router';
import { EventService } from '../services/event/event.service';
import { ToastrService } from 'ngx-toastr';
import { IEvaluation } from '../evaluation/evaluation';
import { PlantlistService } from '../services/plant/plantlist.service';
import { EvaluationlistService } from '../services/evaluation/evaluationlist.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})
export class EventComponent implements OnInit {

  external: boolean = false;
  internal: boolean = false;
  message: any;
  ec_plant: any = "";
  plant_id: any = "";
  datee: any = "";
  dateYear: any = "";
  send_data: any;
  evaluation_id: any = "";
  ec_evaluation: any = "";
  ec_current_year: any = new Date().getFullYear();
  event_code: any;
  pageAct: string = "Create";
  hiddenId: boolean = false;
  eventForm: FormGroup;
  plant: IPlant[];
  _plant: IPlant[];
  evaluation : IEvaluation[];
  _evaluation : IEvaluation[];
  nodata: boolean = false;
  chk_cou: number = 0;
  maxDate = new Date();
  event_date: any;

  pipe = new DatePipe('en-US');
  
  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private eventapi: EventService, private plantservice: PlantlistService, private evaluationservice: EvaluationlistService, private toastr: ToastrService) {
   
  }

  token: any;

  @ViewChild('evaluationSearch') evaluationSearchInput: ElementRef;
  @ViewChild('plantSearch') plantSearchInput: ElementRef;

  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.token = window.localStorage.getItem('token');
    if(!this.token){
      this.router.navigate(['']);
    }

    //this.event_code = this.ec_plant+"-"+this.ec_evaluation+"-"+this.datee+"-";

    //console.log(this.event_code);

    this.plantservice.plantData().subscribe((data: IPlant[]) => {
      this.plant = data;
      this._plant = data;
    }, (error) => {
      this.plant = [];
      this._plant = [];
    });

    this.evaluationservice.evaluationData().subscribe((data: IEvaluation[]) => {
      this.evaluation = data;
      this._evaluation = data;
    }, (error) => {
      this.evaluation = [];
      this._evaluation = [];
    });


    if(this.route.snapshot.paramMap.get('id'))
    {
      this.chk_cou = this.chk_cou+1;
      let id = parseInt(this.route.snapshot.paramMap.get('id'));
      this.pageAct = "Update";
      this.hiddenId = true;
      this.eventapi.getEvent(id).subscribe((data: any) => {
        this.event_date = data.eventDate
        //this.dateYear = this.pipe.transform(data.eventDate, 'yyyy');
        //this.eventForm.get('eventCode').setValue(data.eventCode);
        //this.eventForm.patchValue(data);

        let divdEvcode =  data.eventCode.split("-");
        this.event_code = divdEvcode[0]+"-"+divdEvcode[1]+"-"+divdEvcode[2]+"-"+divdEvcode[3];
        
        this.eventForm.get('eventCode').setValue(this.event_code);

        this.ec_plant = divdEvcode[0];
        this.ec_evaluation = divdEvcode[1];
        this.datee = divdEvcode[2];

        this.plant_id = data.plantId;
        this.evaluation_id = data.evaluationId;
        this.dateYear = divdEvcode[2];
        

        this.eventForm = this.fb.group({
          eventId: [data.eventId],
          plantId: [data.plantId, Validators.required],
          eventCompetition: [data.eventCompetition, [Validators.required]],
          eventDate: [data.eventDate, [Validators.required]],
          evaluationId: [data.evaluationId, Validators.required],
          eventevaluationtype: [data.eventevaluationtype, Validators.required],
          eventCode: [data.eventCode, Validators.required]
        });

      })

      this.eventForm = this.fb.group({
        eventId: [''],
        plantId: ['', Validators.required],
        eventCompetition: ['', [Validators.required]],
        eventDate: ['', [Validators.required]],
        evaluationId: ['', Validators.required],
        eventevaluationtype: ['', Validators.required],
        eventCode: ['', Validators.required]
      });
    } else {
      this.pageAct = "Create";
      this.hiddenId = false;

      this.eventForm = this.fb.group({
        plantId: ['', Validators.required],
        eventCompetition: ['', [Validators.required]],
        eventDate: ['', [Validators.required]],
        evaluationId: ['', Validators.required],
        eventevaluationtype: ['', Validators.required],
        eventCode: ['', Validators.required]
      });
    }
  }

  get errplantId() { return this.eventForm.get('plantId'); }
  get erreventCompetition() { return this.eventForm.get('eventCompetition'); }
  get erreventDate() { return this.eventForm.get('eventDate'); }
  get errevaluationId() { return this.eventForm.get('evaluationId'); }
  get erreventevaluationtype() { return this.eventForm.get('eventevaluationtype'); }
  get erreventCode() { return this.eventForm.get('eventCode'); }


  onfocusEvaluation() {
    this.evaluationSearchInput.nativeElement.focus();
  }
  onInputChangeEvaluation() {
    const searchInput = this.evaluationSearchInput.nativeElement.value ? this.evaluationSearchInput.nativeElement.value.toLowerCase() : '';
    this.evaluation = this._evaluation.filter(c_data => {
      const c_name: any = c_data.evaluationName.toLowerCase();
      return c_name.indexOf(searchInput) > -1;
    })
  }

  onfocusPlant() {
    this.plantSearchInput.nativeElement.focus();
  }
  onInputChangePlant() {
    const searchInput = this.plantSearchInput.nativeElement.value ? this.plantSearchInput.nativeElement.value.toLowerCase() : '';
    this.plant = this._plant.filter(c_data => {
      const c_name: any = c_data.plantName.toLowerCase();
      return c_name.indexOf(searchInput) > -1;
    })
  }


  getPlantDet(event: any){
    
      this.plant_id = event; 
      if(this.hiddenId == true) {
        this.send_data = event+"**"+this.evaluation_id+"**"+this.event_date+"**"+this.route.snapshot.paramMap.get('id');
      } else {
        this.send_data = event+"**"+this.evaluation_id+"**"+this.dateYear
      }
      this.plantservice.plantCode(this.send_data).subscribe((data: any) => {
        if(data["pname"] == null) { this.ec_plant = ""; } else { this.ec_plant = data["pname"]; }
        this.event_code = this.ec_plant+"-"+this.ec_evaluation+"-"+this.datee+"-"+data["cou"];
        this.eventForm.get('eventCode').setValue(this.event_code);
      });
  }
  getDateDet(event: any){
    
      
      if(this.hiddenId == false) {
        this.dateYear = this.pipe.transform(event.value, 'dd-MM-yyyy');
      } else {
        this.dateYear = this.pipe.transform(event.value, 'dd-MM-yyyy');
      }

      if(this.hiddenId == true) {
        this.send_data = this.plant_id+"**"+this.evaluation_id+"**"+this.event_date+"**"+this.route.snapshot.paramMap.get('id');
      } else {
        this.send_data = this.plant_id+"**"+this.evaluation_id+"**"+this.dateYear
      }
      console.log(this.send_data);
      this.plantservice.plantCode(this.send_data).subscribe((data: any) => {
        if(data["cou"] == null) { this.datee = ""; } else { this.datee = data["fy"]; }
        this.event_code = this.ec_plant+"-"+this.ec_evaluation+"-"+this.datee+"-"+data["cou"];
        this.eventForm.get('eventCode').setValue(this.event_code);
      });
    
  }
  getEvaluationDet(event: any){
    
      this.evaluation_id = event;
      if(this.hiddenId == true) {
        this.send_data = this.plant_id+"**"+event+"**"+this.event_date+"**"+this.route.snapshot.paramMap.get('id');
      } else {
        this.send_data = this.plant_id+"**"+event+"**"+this.dateYear
      }
      this.plantservice.plantCode(this.send_data).subscribe((data: any) => {
        if(data["evcode"] == null) { this.ec_evaluation = ""; } else { this.ec_evaluation = data["evcode"]; }
        this.event_code = this.ec_plant+"-"+this.ec_evaluation+"-"+this.datee+"-"+data["cou"];
        this.eventForm.get('eventCode').setValue(this.event_code);
      });
    
  }



  submitEvent(act){
    if(this.eventForm.invalid)
    {
      return;
    }
    if(act == "Create") {
      this.eventapi.addEvent(this.eventForm.value)
      .subscribe(data => {
        this.router.navigate(['/Event_List']);
        this.toastr.success('Event Added Successfully');
      })
    }
    if(act == "Update") {
      this.eventapi.updateEvent(this.eventForm.value).subscribe(() => {
        this.router.navigate(['/Event_List']);
        this.toastr.success('Event Updated Successfully');
      })
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HeadermessageService } from '../services/dashboard/headermessage.service';
import { JuryscheduleService } from '../services/juryshedule/juryschedule.service';
import { IJurySchedule } from './juryschedule';

@Component({
  selector: 'app-juryschedule',
  templateUrl: './juryschedule.component.html',
  styleUrls: ['./juryschedule.component.css']
})
export class JuryscheduleComponent implements OnInit {

  panelOpenState = false;
  juryScheduleID: any;
  juryschedules: IJurySchedule[];
  pageHead: string;

  constructor(private router:Router, 
    private juryscheduleapi: JuryscheduleService, 
    private route: ActivatedRoute,
    private toastr: ToastrService, 
    private headerData: HeadermessageService) { }

  ngOnInit(): void {

    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.juryScheduleID = window.localStorage.getItem('jsch_j_id'); 
    this.juryscheduleapi.getjuryschedule(this.juryScheduleID).subscribe((data: any) => {
      this.juryschedules = data;
    })
    
    this.headerData.sendMessage('Manage Schedules');
    this.headerData.mszHead$.subscribe(
      message => {
        this.pageHead = message;
      }
    );
    
  }

}

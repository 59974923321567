<div class="clred" mat-dialog-close><strong><i class="las la-times"></i></strong></div>
<!-- <app-spinner *ngIf="showSpinner"></app-spinner> -->
<h4 class="hdng">SMS/Mail Box</h4>
<!-- <mat-card>Simple card</mat-card> -->

<!-- <mat-grid-list cols="3" rowHeight="2:1">
    <mat-grid-tile>1</mat-grid-tile>
    <mat-grid-tile>2</mat-grid-tile>
    <mat-grid-tile>3</mat-grid-tile>
  </mat-grid-list> -->

<mat-dialog-actions>
    <div class="session-popup">
    <!-- <div class="row threepop">
        <div class="col-md-4 assigned"><h4>Assigned - {{ data.tes }}</h4></div>
        <div class="col-md-4 completed"><h4>Completed - {{ data.completedAssigned }}</h4></div>
        <div class="col-md-4 pending"><h4>Pending - {{ data.remainAssigned }}</h4></div>
    </div>
        <div class="btn-grp">
        <div class="pull-left add">
            <button mat-raised-button color="warn" [disabled]='sessionDisable' (click)="SessionEnd('some_text')">Session End</button>
            <span class="info"><i matTooltip="Submit your total schedule evaluation and proceed to grades" [matTooltipPosition]="positionAfter" class="las la-info-circle"></i></span>
        </div>
        <div class="pull-right add">
            <span class="info"><i matTooltip="Submit your day evaluation" [matTooltipPosition]="positionBefore" class="las la-info-circle"></i></span> <button mat-raised-button [disabled]='sessionDisable' color="primary" (click)="DayEnd()">Day End</button>
        </div>
    </div> -->
    
</div>

    <div class="row">
        <div class="col-md-12" [innerText]="copyText"></div>
        <div class="col-md-12">
            <div class="pull-right add">
                <a href="https://web.whatsapp.com/send?text={{ whatsappText }}" target="_blank" mat-stroked-button color="success"><i class="lab la-whatsapp" style="font-size: 35px;"></i></a>
                <a (click)="copyingText()" mat-stroked-button color="primary"><i class="las la-copy" style="font-size: 35px;"></i></a>
             </div>
        </div>

    </div>
   
</mat-dialog-actions>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEmployee } from 'src/app/employee/employee';
import { IQueSchedule } from 'src/app/juryevaluation/question';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GetemployeeService {

  private _url: string = environment.api_url;

  constructor(private _http: HttpClient) { }

  getsingleEmployee(employee_id:number){
    return this._http.get<IEmployee[]>(this._url+'/getEmployee.php?employee_id='+employee_id);
  }

  getEmployeeQuestion(schedule_id:number){
    return this._http.get<IQueSchedule[]>(this._url+'/JuryQuestionEvaluation.php?schedule_id='+schedule_id);
  }

  getEditsingleEmployee(employee_id:number){
    return this._http.get<IEmployee[]>(this._url+'/geteditEmployee.php?employee_id='+employee_id);
  }

  getEditEmployeeQuestion(schedule_id:number){
    return this._http.get<IQueSchedule[]>(this._url+'/JuryEditQuestionEvaluation.php?schedule_id='+schedule_id);
  }
}

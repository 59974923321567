import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginService } from './services/login/login.service';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { MaterialModule } from './material/material.module';
import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { formatDate } from '@angular/common';
import { JurypopupComponent } from './jurypopup/jurypopup.component';

import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { SpinnerComponent } from './spinner/spinner.component';
import { EvaluationrequestComponent } from './evaluationrequest/evaluationrequest.component';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ChartsModule } from 'ng2-charts';
import { StrictNumberOnlyDirective } from './juryevaluation/evaluationdirective';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ClipboardModule } from 'ngx-clipboard';

//import { MAT_DATE_LOCALE } from '@angular/material/core'

export const PICK_FORMATS = {
  parse: {dateInput: {month: 'short', year: 'numeric', day: 'numeric'}},
  display: {
      dateInput: 'input',
      monthYearLabel: {year: 'numeric', month: 'short'},
      dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
      monthYearA11yLabel: {year: 'numeric', month: 'long'}
  }
};

class PickDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
      if (displayFormat === 'input') {
          return formatDate(date,'dd-MM-yyyy',this.locale);
      } else {
          return date.toDateString();
      }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    JurypopupComponent,
    SpinnerComponent,
    EvaluationrequestComponent,
    StrictNumberOnlyDirective
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxMatSelectSearchModule,
    MaterialModule,
    NgxChartsModule,
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: 'toast-top-right',
      preventDuplicates: false
    }),
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger'
    }),
    AutocompleteLibModule,
    ChartsModule,
    ClipboardModule
  ],
  providers: [
    LoginService,
    {provide: DateAdapter, useClass: PickDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS}
  ],
  bootstrap: [AppComponent],
  entryComponents:[JurypopupComponent]
})
export class AppModule { }

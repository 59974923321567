import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IRequest } from 'src/app/request/request';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  private _url: string = environment.api_url;

  constructor(private _http: HttpClient) { }

  requestData(): Observable<IRequest[]>{
    return this._http.get<IRequest[]>(this._url+'/requestList.php');
  }

  changeRequest(id){
    //return this._http.post(this._url+'/deleteCompany.php?id='+id)
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sessiondetail',
  templateUrl: './sessiondetail.component.html',
  styleUrls: ['./sessiondetail.component.css']
})
export class SessiondetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, ElementRef, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { JuryEvaluationService } from '../services/JuryEvaluation/jury-evaluation.service';
import { GetemployeeService } from '../services/search/getemployee.service';
import { SearchService } from '../services/search/search.service';

@Component({
  selector: 'app-editjuryevaluation',
  templateUrl: './editjuryevaluation.component.html',
  styleUrls: ['./editjuryevaluation.component.css']
})
export class EditjuryevaluationComponent implements OnInit {

  employeeID: any = 0;
  employeeData: any;
  juryScheduleID: any;
  shJuryDetails: boolean = false;
  tot_questions:number = 0;
  questionData: any;
  totSubQuestions:number = 0;
  shQueDetails: boolean = false;
  disDayEnd: boolean = false;
  dissummary: boolean = false;
  msz: boolean = false;
  givenMarks: number = 0;
  evoId: any;
  businessUnitName : any;
  guideLines : any;
  plantName : any;
  evaluationType : any;
  showSpinner: boolean = true;
  sch_empId: any;

  url: string = environment.url;

  constructor(private matDialog: MatDialog, private route: ActivatedRoute, private getemplolyeeapi: GetemployeeService, private searchapi: SearchService, private evaluationapi: JuryEvaluationService, private router:Router, private toastr: ToastrService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.matDialog.closeAll();
    let id = parseInt(this.route.snapshot.paramMap.get('id'));
    this.employeeID = id;

    this.juryScheduleID = window.localStorage.getItem('jsch_j_id');


    this.searchapi.getevaluation(this.juryScheduleID).subscribe((evdata: any) => {
      console.log(evdata.guidelines);
      this.businessUnitName = evdata.businessUnitName;
      this.guideLines = evdata.guidelines;
      this.plantName = evdata.plantName;
      this.evaluationType = evdata.evaluationType;
      this.evoId =  evdata.ev;
      //console.log(this.evoId);

      if(evdata.message == 1)
      {
        this.msz = true;
        setTimeout(() => {
          this.showSpinner = false;
        }, 1500);
      } else {
        this.msz = false;
        setTimeout(() => {
          this.showSpinner = false;
        }, 1500);
      }
    
    });


    this.getemplolyeeapi.getEditsingleEmployee(id).subscribe((data: any) => {
      this.employeeData = data;
      this.shJuryDetails = true;
      this.sch_empId = data.scheduleId+'_'+id;
      this.getemplolyeeapi.getEditEmployeeQuestion(this.sch_empId).subscribe((data: any) => {

        this.tot_questions = data.all_questions_count['tot_questions'];
        this.givenMarks = data.all_questions_count['awarded_marks'];
        this.questionData = data.questions;
        this.totSubQuestions = data.all_questions_count['tot_subQuestions'];
        this.shQueDetails = true;
        this.disDayEnd = true;
        this.dissummary = true;
      });
    });



  }

  q_mark(event,mark)
  {
    if(Number(event.target.value) > 0) {
      if(Number(event.target.value) > Number(mark.questionMarks)) {
        let snackBarRef = this.snackBar.open('Evaluated Mark should not be greater than its Question Mark','', { duration : 3000, horizontalPosition: 'center', verticalPosition: 'top' });
        
        let decimalRemove = mark.questionEvMarks.split('.00');
        if(event.target.value.length > ((decimalRemove.length)+1)) {
          mark.questionEvMarks = '';
          event.target.value = '';
        } else {
          const s = event.target.value;
          const withoutLastChunk = s.slice(0, s.lastIndexOf("_"));
          mark.questionEvMarks = withoutLastChunk;
          event.target.value = withoutLastChunk;
        }
        
      }
    }
    let quId = event.target.id.split("_");
    let sum = 0;
    for (let i = 0; i < document.querySelectorAll('.qMark').length; i++) {
      sum+=Number((<HTMLInputElement>document.querySelectorAll('.qMark')[i]).value);
    }
    this.givenMarks = sum;
  }

  submitMarks(evaluationForm: NgForm){
    this.showSpinner = true;
     
    this.evaluationapi.editScheduleEvaluation(evaluationForm.form.value).subscribe((data) => {
      console.log(data);
      setTimeout(() => {
        this.showSpinner = false;
      }, 1500);
      this.msz = data.take_ev;
      this.router.navigate(['/JuryEvaluation']);
      this.shJuryDetails = false;
      this.shQueDetails = false;
      this.ngOnInit();
      this.toastr.success('Evaluation Saved Successfully');
    });
  
  }

}

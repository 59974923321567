<div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between">
       <div class="iq-header-title">
          <h4 class="card-title">{{ pageAct }} Schedule</h4>
       </div>
       <div class="pull-right add">
          <a href="{{ url }}/assets/sample_excel/Individual/individual.xls"y6 download="Individual" mat-stroked-button color="secondary">Employee Excel Format &nbsp;&nbsp;<i class="las la-download"></i></a>
          <a href="{{ url }}/assets/sample_excel/Team/team.xlsx" download="Team" mat-stroked-button color="secondary">Team Excel Format &nbsp;&nbsp;<i class="las la-download"></i></a>
          <a routerLink="/Schedule_List" mat-stroked-button color="primary">Schedule List</a>
       </div>
    </div>
    <div class="iq-card-body">
      <strong class="red">* Fields are Required</strong>
       <div class="form-horizontal" [formGroup]="scheduleForm">
           
         <div class="form-group row" [ngClass]="{'has-error': (errevaluationId.touched || errevaluationId.dirty) && errevaluationId.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Evaluation <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusEvaluation()">
                  <mat-label>Evaluation</mat-label>
                  <mat-select formControlName="evaluationId" (ngModelChange)="getevoluid($event)">
                     <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Evaluation" #evaluationSearch (input)="onInputChangeEvaluation()" />
                     <mat-option value="">-- Select Evaluation --</mat-option>
                    <mat-option *ngFor="let ev of evaluation" [value]="ev.evaluationId">
                     {{ev.evaluationName}}
                    </mat-option>
                  </mat-select>
                  <mat-hint *ngIf="(errevaluationId.touched || errevaluationId.dirty) && errevaluationId.invalid"><span *ngIf="errevaluationId.errors?.required" class="error-msg">Evaluation is Required</span></mat-hint>
                </mat-form-field>
           </div>
         </div>

         <div *ngIf="aud_type" class="form-group row" [ngClass]="{'has-error': (errauditType.touched || errauditType.dirty) && errauditType.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="evaluation_type">Audit Type <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Audit Type</mat-label>
                  <mat-select formControlName="auditType" (ngModelChange)="getevoludp($event)">
                     <mat-option value="">-- Select Audit Type --</mat-option>
                     <mat-option value="Internal">Internal</mat-option>
                     <mat-option value="External">External</mat-option>
                  </mat-select>
                  <mat-hint *ngIf="(errauditType.touched || errauditType.dirty) && errauditType.invalid"><span *ngIf="errauditType.errors?.required" class="error-msg">Audit Type is Required</span></mat-hint>
                </mat-form-field>
                
           </div>
         </div>


        <div *ngIf="evolu_dep" class="form-group row" [ngClass]="{'has-error': (errdepartmentId.touched || errdepartmentId.dirty) && errdepartmentId.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Department <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusDepartment()">
                  <mat-label>Department</mat-label>
                  <mat-select formControlName="departmentId" (ngModelChange)="getevedid($event)">
                     <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Department" #departmentSearch (input)="onInputChangeDepartment()" />
                     <mat-option value="">-- Select Department --</mat-option>
                     <mat-optgroup *ngFor="let group of department" label="{{group.plantName}}">
                        <mat-option *ngFor="let item of group.items" [value]="item.departmentId">
                           {{item.departmentName}}
                        </mat-option>
                     </mat-optgroup>
                  </mat-select>
                  <mat-hint *ngIf="(errdepartmentId.touched || errdepartmentId.dirty) && errdepartmentId.invalid"><span *ngIf="errdepartmentId.errors?.required" class="error-msg">Department is Required</span></mat-hint>
                </mat-form-field>
            </div>
         </div>

         <div *ngIf="evolu_pl" class="form-group row" [ngClass]="{'has-error': (errplantId.touched || errplantId.dirty) && errplantId.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Plant <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusPlant()" >
                  <mat-label>Plant</mat-label>
                  <mat-select formControlName="plantId" (ngModelChange)="geteveid($event)">
                     <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Plant" #plantSearch (input)="onInputChangePlant()" />
                     <mat-option value="">-- Select Plant --</mat-option>
                    <mat-option *ngFor="let pl of plant" [value]="pl.plantId">
                     {{pl.plantName}}
                    </mat-option>
                  </mat-select>
                  <mat-hint *ngIf="(errplantId.touched || errplantId.dirty) && errplantId.invalid"><span *ngIf="errplantId.errors?.required" class="error-msg">Plant is Required</span></mat-hint>
                </mat-form-field>
            </div>
         </div>

         <div class="form-group row" [ngClass]="{'has-error': (erreventCode.touched || erreventCode.dirty) && erreventCode.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Event <sup class="red">*</sup></label>
            <div class="col-sm-5">

               <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusEvent()">
                  <mat-label>Event</mat-label>
                  <mat-select formControlName="eventCode">
                     <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Event" #eventSearch (input)="onInputChangeEvent()" />
                     <mat-option value="">-- Select Event --</mat-option>
                    <mat-option *ngFor="let eve of event" [value]="eve.eventId">
                     {{eve.eventCode}}
                    </mat-option>
                  </mat-select>
                  <mat-hint *ngIf="(erreventCode.touched || erreventCode.dirty) && erreventCode.invalid"><span *ngIf="erreventCode.errors?.required" class="error-msg">Event is Required</span></mat-hint>
                </mat-form-field>
           </div>
         </div>

         <div class="form-group row" [ngClass]="{'has-error': (errjuryId.touched || errjuryId.dirty) && errjuryId.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Jury <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusJury()">
                  <mat-label>Jury / Auditor</mat-label>
                  <mat-select multiple formControlName="juryId">
                     <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Jury" #jurySearch (input)="onInputChangeJury()" />
                    <mat-option *ngFor="let ju of jury" [value]="ju.juryId">
                      {{ju.juryName}}
                    </mat-option>
                  </mat-select>
                  <mat-hint *ngIf="(errjuryId.touched || errjuryId.dirty) && errjuryId.invalid"><span *ngIf="errjuryId.errors?.required" class="error-msg">Jury is Required</span></mat-hint>
                </mat-form-field>
           </div>
         </div>

         <div *ngIf="isexcell" class="form-group row" [ngClass]="{'has-error': (errupload_excel.touched || errupload_excel.dirty) && errupload_excel.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="errupload_excel">Upload Excel <sup class="red">*</sup></label>
            <div class="col-sm-5">
              <div class="input-group mb-3">
               <div class="custom-file">
                 <input type="file" class="custom-file-input" id="inputGroupFile01" (change)="onSelectExcel($event)" accept=".xlsx, .xls, .csv">
                 <label class="custom-file-label" for="inputGroupFile01">{{selectedFileName != '' ? selectedFileName : 'Choose file'}}</label>
               </div>
                  <span class="help-block" *ngIf="(errupload_excel.touched || errupload_excel.dirty) && errupload_excel.invalid">
                     <span *ngIf="errupload_excel.errors?.required">Excel is Required</span>
                  </span>
             </div>
            </div>
         </div>

         <!-- <div class="form-group row" [ngClass]="{'has-error': (errevaluationType.touched || errevaluationType.dirty) && errevaluationType.invalid}" *ngIf="displayNo">
            <label class="control-label col-sm-2 align-self-center mb-0" for="evaluation_type">Evaluation Type <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>-- Select Evaluation Type --</mat-label>
                  <mat-select formControlName="evaluationType">
                     <mat-option value="Single Jury">Single Jury</mat-option>
                     <mat-option value="Multi Jury">Multi Jury</mat-option>
                  </mat-select>
                  <mat-hint *ngIf="(errevaluationType.touched || errevaluationType.dirty) && errevaluationType.invalid"><span *ngIf="errevaluationType.errors?.required" class="error-msg">Evaluation Type is Required</span></mat-hint>
                </mat-form-field>
           </div>
         </div> -->

         
         

         

         <div *ngIf="evolu_zone" class="form-group row" [ngClass]="{'has-error': (errzone.touched || errzone.dirty) && errzone.invalid}">
             <label class="control-label col-sm-2 align-self-center mb-0" for="department_name">Zone <sup class="red">*</sup></label>
             <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Zone</mat-label>
                  <input matInput placeholder="Zone" formControlName="zone" >
                  <mat-hint *ngIf="(errzone.touched || errzone.dirty) && errzone.invalid"><span *ngIf="errzone.errors?.required" class="error-msg">Zone is Required</span></mat-hint>
                </mat-form-field>
             </div>
          </div>
          
          <div class="form-group row">
              <div class="col-md-7 text-right">
                <input type="hidden" *ngIf="hiddenId" value="" formControlName="scheduleId" />
                <button type="button" [disabled]="!scheduleForm.valid"  (click)="submitSchedule(pageAct)"  mat-raised-button color="primary">Save</button>
            </div>
          </div>
       </div>
    </div>
 </div>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private _url: string = environment.api_url;
  constructor(private _http: HttpClient) { }

  getDashboard(dashboardData) {
    return this._http.post<any>(this._url+'/DashboardJury.php', dashboardData);
  }

  
  getadminDashboard(): Observable<any>{
    return this._http.get<any>(this._url+'/DashboardAdminData.php');
  }
 

  chgPendStatus(dashboardData) {
    return this._http.post<any>(this._url+'/ProgressChange.php', dashboardData);
  }

  getProfile(profileData) {
    return this._http.post<any>(this._url+'/Profileadmin.php', profileData);
  }

  getJuryProfile(profileData) {
    return this._http.post<any>(this._url+'/ProfileJury.php', profileData);
  }

}

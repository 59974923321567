import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { IEvaluation } from 'src/app/evaluation/evaluation';

@Injectable({
  providedIn: 'root'
})
export class EvaluationlistService {

  private _url: string = environment.api_url;
  constructor(private _http: HttpClient) { }

  evaluationData(): Observable<IEvaluation[]>{
    return this._http.get<IEvaluation[]>(this._url+'/evaluationList.php');
  }

  singleevaluation(sel_id: any): Observable<any>{
    return this._http.get<any>(this._url+'/singleEvaluation.php?id='+sel_id);
  }

  dateYear(sel_id: any): Observable<any>{
    return this._http.get<any>(this._url+'/dateYear.php?id='+sel_id);
  }

  deleteEvaluation(id){
    return this._http.delete<IEvaluation>(this._url+'/deleteEvaluation.php?id='+id)
  }
}

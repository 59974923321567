<div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between">
       <div class="iq-header-title">
          <h4 class="card-title">{{ pageAct }} Jury / Auditor</h4>
       </div>
       <div class="pull-right add">
         <a routerLink="/Jury_List" mat-stroked-button color="primary">Jury / Auditor List</a>
      </div>
    </div>
    <div class="iq-card-body">
      <strong class="red">* Fields are Required</strong>
       <div class="form-horizontal" [formGroup]="juryForm">

         <div class="form-group row" [ngClass]="{'has-error': (errjuryName.touched || errjuryName.dirty) && errjuryName.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="department_name">Jury / Auditor Name <sup class="red">*</sup></label>
            <div class="col-sm-5">
              <mat-form-field class="example-full-width" appearance="outline">
                 <mat-label>Jury / Auditor Name</mat-label>
                 <input matInput placeholder="Jury / Auditor Name" formControlName="juryName" >
                 <mat-hint *ngIf="(errjuryName.touched || errjuryName.dirty) && errjuryName.invalid"><span *ngIf="errjuryName.errors?.required" class="error-msg">Jury / Auditor Name is Required</span><span *ngIf="errjuryName.errors?.pattern" class="error-msg">Jury / Auditor Name should be in Alphabets</span></mat-hint>
               </mat-form-field>
            </div>
         </div>

         <div class="form-group row" [ngClass]="{'has-error': (errjuryMobile.touched || errjuryMobile.dirty) && errjuryMobile.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="department_name">Mobile Number <sup class="red">*</sup></label>
            <div class="col-sm-5">
              <mat-form-field class="example-full-width" appearance="outline">
                 <mat-label>Mobile Number</mat-label>
                 <input matInput placeholder="Mobile Number" formControlName="juryMobile" >
                 <mat-hint *ngIf="(errjuryMobile.touched || errjuryMobile.dirty) && errjuryMobile.invalid"><span *ngIf="errjuryMobile.errors?.required" class="error-msg">Mobile Number is Required</span><span *ngIf="errjuryMobile.errors?.pattern" class="error-msg">Mobile Number should be in Number</span></mat-hint>
               </mat-form-field>
            </div>
         </div>

         <div class="form-group row" [ngClass]="{'has-error': (errjuryType.touched || errjuryType.dirty) && errjuryType.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="evaluation_type">Jury / Auditor Type <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>-- Select Jury / Auditor Type --</mat-label>
                  <mat-select formControlName="juryType" (ngModelChange)="getJAType($event)">
                     <mat-option value="Internal">Internal</mat-option>
                     <mat-option value="External">External</mat-option>
                  </mat-select>
                  <mat-hint *ngIf="(errjuryType.touched || errjuryType.dirty) && errjuryType.invalid"><span *ngIf="errjuryType.errors?.required" class="error-msg">Jury / Auditor Type is Required</span></mat-hint>
                </mat-form-field>
           </div>
         </div>

        
         <div *ngIf="internal">

             <div class="form-group row" [ngClass]="{'has-error': (errplantId.touched || errplantId.dirty) && errplantId.invalid}">
               <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Plant <sup class="red">*</sup></label>
               <div class="col-sm-5">
                  <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusPlant()">
                     <mat-label>-- Select Plant --</mat-label>
                     <mat-select formControlName="plantId">
                        <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Plant" #plantSearch (input)="onInputChangePlant()" />
                       <mat-option *ngFor="let item of plant" [value]="item.plantId">
                        {{item.plantName}}
                       </mat-option>
                     </mat-select>
                     <mat-hint *ngIf="(errplantId.touched || errplantId.dirty) && errplantId.invalid"><span *ngIf="errplantId.errors?.required" class="error-msg">Plant is Required</span></mat-hint>
                   </mat-form-field>
              </div>
            </div>

         <div class="form-group row" [ngClass]="{'has-error': (errjuryEmployeeCode.touched || errjuryEmployeeCode.dirty) && errjuryEmployeeCode.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Employee Code <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Employee Code</mat-label>
                  <input matInput placeholder="Employee Code" formControlName="juryEmployeeCode" >
                  <mat-hint *ngIf="(errjuryEmployeeCode.touched || errjuryEmployeeCode.dirty) && errjuryEmployeeCode.invalid"><span *ngIf="errjuryEmployeeCode.errors?.required" class="error-msg">Employee Code is Required</span></mat-hint>
                </mat-form-field>
           </div>
         </div>


        </div>
          
        <div *ngIf="external">

          <div class="form-group row" [ngClass]="{'has-error': (errjuryOrganization.touched || errjuryOrganization.dirty) && errjuryOrganization.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Organization <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Organization</mat-label>
                  <input matInput placeholder="Organization" formControlName="juryOrganization" >
                  <mat-hint *ngIf="(errjuryOrganization.touched || errjuryOrganization.dirty) && errjuryOrganization.invalid"><span *ngIf="errjuryOrganization.errors?.required" class="error-msg">Organization is Required</span></mat-hint>
                </mat-form-field>
           </div>
         </div>

          <div class="form-group row" [ngClass]="{'has-error': (errjuryPlace.touched || errjuryPlace.dirty) && errjuryPlace.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Place <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Place</mat-label>
                  <input matInput placeholder="Place" formControlName="juryPlace" >
                  <mat-hint *ngIf="(errjuryPlace.touched || errjuryPlace.dirty) && errjuryPlace.invalid"><span *ngIf="errjuryPlace.errors?.required" class="error-msg">Place is Required</span></mat-hint>
                </mat-form-field>
           </div>
         </div>


        </div>

         <div class="form-group row" [ngClass]="{'has-error': (errjuryExpertise.touched || errjuryExpertise.dirty) && errjuryExpertise.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Expertise <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusEvaluation()">
                  <mat-label>-- Select Expertise --</mat-label>
                  <mat-select formControlName="juryExpertise" multiple>
                     <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Evaluation" #evaluationSearch (input)="onInputChangeEvaluation()" />
                    <mat-option *ngFor="let ev of evaluation" [value]="ev.evaluationId">
                     {{ev.evaluationName}}
                    </mat-option>
                  </mat-select>
                  <mat-hint *ngIf="(errjuryExpertise.touched || errjuryExpertise.dirty) && errjuryExpertise.invalid"><span *ngIf="errjuryExpertise.errors?.required" class="error-msg">Expertise is Required</span></mat-hint>
                </mat-form-field>
           </div>
         </div>
          
          <div class="form-group row">
              <div class="col-md-7 text-right">
                <input type="hidden" *ngIf="hiddenId" value="" formControlName="juryId" />
                <button type="button" [disabled]="!juryForm.valid"  (click)="submitJury(pageAct)"  mat-raised-button color="primary">Save</button>
            </div>
          </div>
       </div>
    </div>
 </div>
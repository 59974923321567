<div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between">
       <div class="iq-header-title">
          <h4 class="card-title">Candidate Evaluation Report</h4>
       </div>
    </div>
    <div class="iq-card-body">
       <div class="form-horizontal">
           
        <form #reportForm="ngForm" (ngSubmit)="submitReport(reportForm);">

        <div class="form-group row">
            <label class="control-label col-sm-2 align-self-center mb-0" for="event_date">From Date <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field (click)="fromDate.open()">
                  <input matInput readonly [matDatepicker]="fromDate" is-date name="fromDate" [ngModel]="" #rfromdate="ngModel">
                  <mat-datepicker-toggle [for]="fromDate" matSuffix></mat-datepicker-toggle>
                  <mat-datepicker #fromDate></mat-datepicker>
               </mat-form-field>
            </div>
         </div>

         <div class="form-group row">
            <label class="control-label col-sm-2 align-self-center mb-0" for="event_date">To Date <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field (click)="toDate.open()">
                  <input matInput readonly [matDatepicker]="toDate" name="toDate" [ngModel]="" #rtodate="ngModel">
                  <mat-datepicker-toggle [for]="toDate" matSuffix></mat-datepicker-toggle>
                  <mat-datepicker #toDate></mat-datepicker>
               </mat-form-field>
            </div>
         </div>

         <div class="form-group row">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Business Unit <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusBusinessUnit()" >
                  <mat-label>Business Unit</mat-label>
                  <mat-select name="businessUnit" [ngModel]="" (ngModelChange)="chgBusinessUnit($event)" #resetbusinessunit="ngModel">
                     <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Business Unit" #businessUnitSearch (input)="onInputChangeBusinessUnit()" />
                     <mat-option value="">-- Select Business Unit --</mat-option>
                    <mat-option *ngFor="let bu of businessunit" [value]="bu.businessUnitId">
                     {{bu.businessUnitName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
           </div>
         </div>

         <div class="form-group row">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Plant </label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusPlant()" >
                  <mat-label>Plant</mat-label>
                  <mat-select name="plant" [ngModel]="" (ngModelChange)="chgPlant($event)" #resetplant>
                     <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Plant" #plantSearch (input)="onInputChangePlant()" />
                     <mat-option value="">-- Select Plant --</mat-option>
                    <mat-option *ngFor="let p of plant" [value]="p.plantId">
                     {{p.plantName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
           </div>
         </div>

         <div class="form-group row">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Evaluation</label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusEvaluation()">
                  <mat-label>Evaluation</mat-label>
                  <mat-select name="evaluation" [ngModel]="" (ngModelChange)="chgevl($event)" #resetevaluation="ngModel">
                     <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Evaluation" #evaluationSearch (input)="onInputChangeEvaluation()" />
                     
                        <mat-option *ngFor="let ev of evaluation" [value]="ev.evaluationId">
                     {{ev.evaluationName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
           </div>
         </div>

         <div class="form-group row">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Event Code </label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusEventCode()" >
                  <mat-label>Event Code</mat-label>
                  <mat-select name="eventCode" [ngModel]="" #reseteventcode="ngModel" (ngModelChange)="chgEventCode($event)">
                     <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Event Code" #eventCodeSearch (input)="onInputChangeEventCode()" />
                     <mat-option value="">-- Select Event Code --</mat-option>
                    <mat-option *ngFor="let p of eventcode" [value]="p.eventId">
                     {{p.eventCode}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
           </div>
         </div>

         <div class="form-group row">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Employee / Team </label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusEmployeeTeam()" >
                  <mat-label>Employee / Team</mat-label>
                  <mat-select name="employeeTeam" [ngModel]="" #resetemployeeteam="ngModel" (ngModelChange)="chgEmployeeTeam($event)">
                     <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Employee / Team" #employeeTeamSearch (input)="onInputChangeEmployeeTeam()" />
                     <mat-option value="">-- Select Employee / Team --</mat-option>
                    <mat-option *ngFor="let p of employeeteam" [value]="p.excelUploadId">
                     {{p.employeeName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
           </div>
         </div>
          
          <div class="form-group row">
              <div class="col-md-7 text-right">
                <button type="submit" [disabled]="disableReportBtn || rfromdate.pristine || rtodate.pristine || resetevaluation.pristine" mat-raised-button color="primary">Get Report</button>
            </div>
          </div>

        </form>
       </div>

       <div *ngIf="shreport" class="row report-pdf">

         <div class="col-md-8">
         <a target="_blank" href="{{ url }}/generatePDF/pdf/EmployeeTeamReportPDF.php?evaluation_id={{PDFevaluation_id}}&eventCode={{PDFEventCode}}&businessunit_id={{PDFbusinessunit_id}}&EmployeeTeam={{PDFEmployeeTeam}}&plant_id={{PDFplant_id}}&fromDate={{PDFfromDate}}&toDate={{PDFtoDate}}" mat-raised-button color="primary">Generate PDF</a>

         
         <table class="chartform table">
           <tr><td><strong>Employee / Team Name</strong></td> <td>:</td> <td>{{ filEmployeeTeam }}</td></tr>
           <tr><td><strong>Juries</strong></td> <td>:</td> <td>{{ filJuryName }}</td></tr>
           <tr><td><strong>Schedule Date</strong></td> <td>:</td> <td>{{ filEventDate }}</td></tr>
           <tr><td><strong>Company</strong></td> <td>:</td> <td>{{ filCompanyName }}</td></tr>
           <tr><td><strong>Business Unit</strong></td> <td>:</td> <td>{{ filBusinessUnitName }}</td></tr>
           <tr><td><strong>Plant</strong></td> <td>:</td> <td>{{ filPlantName }}</td></tr>
           <tr><td><strong>Evaluation</strong></td> <td>:</td> <td>{{ filEvaluationName }}</td></tr>
           <tr><td><strong>Event Code</strong></td> <td>:</td> <td>{{ filEventCode }}</td></tr>
           <tr><td><strong>Dates</strong></td> <td>:</td> <td>{{ filFromDate }} - {{ filToDate }}</td></tr>
         </table> 

         <div *ngIf="evType!='Plant'">
            
            <table class="chartform table" *ngFor="let feedback of feedback_data; let fd = index;">
               <tr>
                  <td><strong>Jury Name : </strong></td> <td>:</td> <td>{{ feedback.juryName }}</td>
               </tr>
               <tr>
                  <td><strong>Jury Mobile : </strong></td> <td>:</td> <td>{{ feedback.juryMobile }}</td>
               </tr>
               <tr>
                  <td><strong>Comment : </strong></td> <td>:</td> <td>{{ feedback.general }}</td>
               </tr>
            </table>
         </div>

      </div> 
           <table class="table r-table" *ngIf="disrec">
               <tr>
                  <table>
                     <tr>
                        <th width="4%" align="center">#</th>
                        <th width="12%" *ngIf="evType=='Plant'">Title</th>
                        <th width="12%">Question</th>
                        <th class="inner-td" *ngIf="evType=='Plant'">
                              <tr>
                                 <th width="15%">Sub Question</th>
                                 <th width="10%">Maximum Marks</th>
                                 <th width="10%">Awarded Marks (avg)</th>
                                 <th width="15%">Remarks</th>
                                 <th width="10%">Image</th>
                              </tr>
                        </th>
                        <th width="5%" *ngIf="evType!='Plant'">Maximum Marks</th>
                        <th width="10%" *ngIf="evType!='Plant'">Awarded Marks (avg)</th>
                        <th width="15%" *ngIf="evType!='Plant'">Remarks</th>
                     </tr>
                  </table>
               </tr>
              
               <tr *ngFor="let eps of report_data; let k = index;">
                  <table>
                     <tr>
                        <td colspan="15" align="center"><strong style="color:rgba(219, 12, 12, 0.637);">{{ eps.epsname }} </strong><span>
                           
                           <a *ngIf="evType=='Plant'" target="_blank" href="{{ img_url }}/Feedback/{{ eps.evtcodeid }}" mat-button color="primary">View Feedback</a>
                        
                        </span><span style="float: right;"><strong style="color:rgba(219, 12, 12, 0.637);">Marks : {{ eps.award_tot_mark }}</strong></span></td>
                     </tr>
                  </table>
                  
                  <table>
                     <tr *ngFor="let question of eps.dataRecords; let i = index;">
                        <td align="center" width="4%">{{ i+1 }}.</td>
                        <td width="12%" *ngIf="evType=='Plant'"><strong>{{ question.title }}</strong></td>
                        <td width="12%"><strong>{{ question.question_phase }}</strong></td>
                        <td class="inner-td" *ngIf="evType=='Plant'">
                           <tr *ngFor="let subquestion of question.sub_ques">
                              <td width="18%">{{ subquestion.squestion_phase }}</td>
                              <td width="15%">{{ subquestion.squestion_mark }}</td>
                              <td width="15%">{{ subquestion.sq_mark }}</td>
                              <td width="25%"><i>{{ subquestion.sq_remark }}</i></td>
                              <td width="10%">
                                 <a *ngIf="subquestion.sq_image != '--'" href="{{ img_url }}/assets/images/jury_evaluations/{{ subquestion.sq_image }}" target="_blank"><img src="{{ img_url }}/assets/images/jury_evaluations/{{ subquestion.sq_image }}" width="40px" height="40px"></a>
                                 <a *ngIf="subquestion.sq_image == '--'">{{ subquestion.sq_image }}</a></td>
                           </tr>
                        </td>
                        <td width="5%" *ngIf="evType!='Plant'"><span *ngIf="question.sub_ques.length==0">{{ question.question_mark }}</span></td>
                        <td width="10%" *ngIf="evType!='Plant'"><span *ngIf="question.sub_ques.length==0">{{ question.mq_mark }}</span></td>
                        <td width="15%" *ngIf="evType!='Plant'"><span *ngIf="question.sub_ques.length==0">{{ question.mq_remark }}</span></td>
                     </tr>
                  </table>
                  
               </tr>
               








           </table>
           <table class="table" *ngIf="!disrec">
            <tr>
               <th>#</th>
               <th>Title</th>
               <th>Question</th>
               <th>Sub Question</th>
               <th>Maximum Mark</th>
               <th>Awarded Marks (avg)</th>
            </tr>
            <tr>
                <td colspan="6">No Records Found</td>
            </tr>
        </table>
       </div>


    </div>
 </div>
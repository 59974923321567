import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JuryloginService {
  redirectUrl: string;

  private _url: string = environment.api_url;

  constructor(private _http: HttpClient) { }

  public jurylogin(logDetails): Observable<any>{
    return this._http.post(this._url+'/jurylogin.php', { logDetails });
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IEvaluation } from '../evaluation/evaluation';
import { IDepartment } from '../department/department';
import { Router, ActivatedRoute } from '@angular/router';
import { ScheduleService } from '../services/schedule/schedule.service';
import { PlantlistService } from '../services/plant/plantlist.service';
import { EvaluationlistService } from '../services/evaluation/evaluationlist.service';
import { DepartmentlistService } from '../services/department/departmentlist.service';
import { ToastrService } from 'ngx-toastr';
import { IEvent } from '../event/event';
import { EventlistService } from '../services/event/eventlist.service';
import { JurylistService } from '../services/jury/jurylist.service';
import { IJury } from '../jury/jury';
import { IOptDepartment } from './opt_department';
import { IPlant } from '../plant/plant';
import {saveAs as importedSaveAs} from "file-saver";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit {

  message: any;
  pageAct: string = "Create";
  hiddenId: boolean = false;
  evolu_dep: boolean = false;
  evolu_pl: boolean = false;
  aud_type: boolean = false;
  evolu_zone: boolean = false;
  isexcell: boolean = false;
  scheduleForm: FormGroup;
  evaluation: IEvaluation[];
  _evaluation: IEvaluation[];
  plant: IPlant[];
  _plant: IPlant[];
  department: IOptDepartment[];
  _department: IOptDepartment[];
  event: IEvent[];
  _event: IEvent[];
  jury: IJury[];
  _jury: IJury[];
  selectedFileName: any = "";
  getdata: any;
  displayNo: boolean = false;

  nodata: boolean = false;

  url: string = environment.url;

  constructor(private fb: FormBuilder, 
              private router: Router, 
              private scheduleapi: ScheduleService, 
              private route: ActivatedRoute, 
              private plantservice: PlantlistService,
              private evaluationservice: EvaluationlistService, 
              private departmentservice: DepartmentlistService, 
              private eventservice: EventlistService, 
              private juryservice: JurylistService, 
              private toastr: ToastrService) { }

  token: any;

  @ViewChild('evaluationSearch') evaluationSearchInput: ElementRef;
  @ViewChild('plantSearch') plantSearchInput: ElementRef;
  @ViewChild('departmentSearch') departmentSearchInput: ElementRef;
  @ViewChild('eventSearch') eventSearchInput: ElementRef;
  @ViewChild('jurySearch') jurySearchInput: ElementRef;

  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.token = window.localStorage.getItem('token');
    if(!this.token){
      this.router.navigate(['']);
    }

    this.evaluationservice.evaluationData().subscribe((data: IEvaluation[]) => {
      this.evaluation = data;
      this._evaluation = data;
    });

    this.plantservice.plantData().subscribe((data: IPlant[]) => {
      this.plant = data;
      this._plant = data;
    });

    this.departmentservice.optDepartmentData().subscribe((data: IOptDepartment[]) => {
      this.department = data;
      this._department = data;
    });

    this.juryservice.juryData().subscribe((data: IJury[]) => {
      this.jury = data;
      this._jury = data;
    });

    if(this.route.snapshot.paramMap.get('id'))
    {
      let id = parseInt(this.route.snapshot.paramMap.get('id'));
      this.pageAct = "Update";
      this.hiddenId = true;
      this.scheduleapi.getSchedule(id).subscribe((data: any) => {
        this.getdata = data;
        this.selectedFileName = data.upload_excel;
        if(data.auditType == "Internal")
        {
          this.evolu_zone = true;
        }
        this.scheduleForm.patchValue(data);
      })

      this.scheduleForm = this.fb.group({
        scheduleId: [''],
        evaluationId: ['', [Validators.required]],
        departmentId: ['', [Validators.required]],
        plantId: ['', [Validators.required]],
        eventCode: ['', Validators.required],
        juryId: ['', [Validators.required]],
        upload_excel: ['', Validators.required],
        //evaluationType: ['', [Validators.required]],
        auditType: ['', Validators.required],
        zone: ['', Validators.required]
      });
      
    } else {
      this.pageAct = "Create";
      this.hiddenId = false;

      this.scheduleForm = this.fb.group({
        evaluationId: ['', [Validators.required]],
        departmentId: ['', [Validators.required]],
        plantId: ['', [Validators.required]],
        eventCode: ['', Validators.required],
        juryId: ['', [Validators.required]],
        upload_excel: ['', Validators.required],
        //evaluationType: ['', [Validators.required]],
        auditType: ['', Validators.required],
        zone: ['', Validators.required]
      });

    }
  }

  downloadempfile()
  {
    importedSaveAs('../assets/sample_excel/individual.xls');
  }

  geteveid(event)
  {
    const evel_id = this.scheduleForm.get('evaluationId').value;

    const formData = new FormData();
    formData.append('evel_id', evel_id);
    formData.append('plnt_id', event);

    this.event = [];
    this.eventservice.geteventPData(formData).subscribe((data: IEvent[]) => {
      this.event = data;
      this._event = data;
    });
  }

  getevedid(event)
  {
    const evel_id = this.scheduleForm.get('evaluationId').value;

    const formData = new FormData();
    formData.append('evel_id', evel_id);
    formData.append('dept_id', event);
    
    this.event = [];
    this._event = [];
    const erreventCode = this.scheduleForm.get('eventCode');
          erreventCode.setValidators(Validators.required);
          erreventCode.updateValueAndValidity();
    this.scheduleForm.get('eventCode').setValue("");

    this.eventservice.geteventDData(formData).subscribe((data: IEvent[]) => {
      this.event = data;
      this._event = data;
    });
  }

  getevoluid(event)
  {
    //console.log("in");
    // if(!this.route.snapshot.paramMap.get('id'))
    // {
      this.jury = [];
      this.juryservice.evoluJuryData(event).subscribe((data: IJury[]) => {
        this.jury = data;
        this._jury = data;
      });

      this.event = [];
      this.eventservice.geteventData(event).subscribe((data: IEvent[]) => {
        this.event = data;
        this._event = data;
      });

      this.evaluationservice.singleevaluation(event).subscribe((data: any) => {
        if(data["evol_type"] == "Team" || data["evol_type"] == "Individual")
        {
          const errupload_excel = this.scheduleForm.get('upload_excel');
          errupload_excel.setValidators(Validators.required);
          errupload_excel.updateValueAndValidity();
          this.isexcell = true;
          
          const errplantId = this.scheduleForm.get('plantId');
          errplantId.clearValidators();
          errplantId.updateValueAndValidity();
          this.scheduleForm.get('plantId').setValue("");

          const errdepartmentId = this.scheduleForm.get('departmentId');
          errdepartmentId.setValidators(Validators.required);
          errdepartmentId.updateValueAndValidity();

          const errauditType = this.scheduleForm.get('auditType');
          errauditType.clearValidators();
          errauditType.updateValueAndValidity();
          this.scheduleForm.get('auditType').setValue("");

          const errzone = this.scheduleForm.get('zone');
          errzone.clearValidators();
          errzone.updateValueAndValidity();
          this.scheduleForm.get('zone').setValue("");

          this.aud_type = false;
          this.evolu_pl = false;
          this.evolu_dep = true;
          this.evolu_zone = false;

        } else {
          const errupload_excel = this.scheduleForm.get('upload_excel');
          errupload_excel.clearValidators();
          errupload_excel.updateValueAndValidity();
          this.isexcell = false;
          
          this.aud_type = true;
          this.evolu_dep = false;
          this.evolu_zone = false;
          this.evolu_pl = true;

          const errplantId = this.scheduleForm.get('plantId');
          errplantId.setValidators(Validators.required);
          errplantId.updateValueAndValidity();

          const errauditType = this.scheduleForm.get('auditType');
          errauditType.setValidators(Validators.required);
          errauditType.updateValueAndValidity();

          const errdepartmentId = this.scheduleForm.get('departmentId');
          errdepartmentId.clearValidators();
          errdepartmentId.updateValueAndValidity();

          this.scheduleForm.get('departmentId').setValue("");

          const errupload_excell = this.scheduleForm.get('upload_excel');
          errupload_excell.clearValidators();
          errupload_excell.updateValueAndValidity();

          this.scheduleForm.get('upload_excel').setValue("");

          //console.log(this.getdata);
          if(this.getdata != "")
          {
            if(this.getdata.auditType == "Internal")
            {
              this.evolu_zone = true;
              this.evolu_pl = true;

              const errzone = this.scheduleForm.get('zone');
              errzone.setValidators(Validators.required);
              errzone.updateValueAndValidity();

              const errplantId = this.scheduleForm.get('plantId');
              errplantId.setValidators(Validators.required);
              errplantId.updateValueAndValidity();

            } else if(this.getdata.auditType == "External") {
              this.evolu_zone = false;
              this.evolu_pl = true;

              const errzone = this.scheduleForm.get('zone');
              errzone.clearValidators();
              errzone.updateValueAndValidity();
              this.scheduleForm.get('zone').setValue("");

              const errplantId = this.scheduleForm.get('plantId');
              errplantId.setValidators(Validators.required);
              errplantId.updateValueAndValidity();
            }

          }

        }
      });
        
    // }
  }
  getevoludp(event)
  {
    //console.log("here");
    this.scheduleForm.get('departmentId').setValue("");
    if(event == "Internal")
    {
      const errzone = this.scheduleForm.get('zone');
      errzone.setValidators(Validators.required);
      errzone.updateValueAndValidity();

      const errdepartmentId = this.scheduleForm.get('departmentId');
      errdepartmentId.clearValidators();
      errdepartmentId.updateValueAndValidity();
      this.scheduleForm.get('departmentId').setValue("");

      const errplantId = this.scheduleForm.get('plantId');
      errplantId.setValidators(Validators.required);
      errplantId.updateValueAndValidity();

      const errupload_excel = this.scheduleForm.get('upload_excel');
      errupload_excel.clearValidators();
      errupload_excel.updateValueAndValidity();
      this.scheduleForm.get('upload_excel').setValue("");

      this.evolu_dep = false;
      this.evolu_zone = true;
      this.evolu_pl = true;
      this.isexcell = false;

    } else if(event == "External") {

      const errzone = this.scheduleForm.get('zone');
      errzone.clearValidators();
      errzone.updateValueAndValidity();
      this.scheduleForm.get('zone').setValue("");

      const errdepartmentId = this.scheduleForm.get('departmentId');
      errdepartmentId.clearValidators();
      errdepartmentId.updateValueAndValidity();
      this.scheduleForm.get('departmentId').setValue("");

      const errupload_excel = this.scheduleForm.get('upload_excel');
      errupload_excel.clearValidators();
      errupload_excel.updateValueAndValidity();
      this.scheduleForm.get('upload_excel').setValue("");

      const errplantId = this.scheduleForm.get('plantId');
      errplantId.setValidators(Validators.required);
      errplantId.updateValueAndValidity();

      this.evolu_dep = false;
      this.evolu_zone = false;
      this.evolu_pl = true;
      this.isexcell = false;
    }
  }

  onSelectExcel(event) {
    const selectedfile = event.target.files[0];
    this.selectedFileName = selectedfile.name;
    //console.log(selectedfile.name);
    if(selectedfile.type == "application/vnd.ms-excel" || selectedfile.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      this.scheduleForm.get('upload_excel').setValue(selectedfile);
    } else {
      this.scheduleForm.get('upload_excel').setValue("");
    }
  }

  get errevaluationId() { return this.scheduleForm.get('evaluationId'); }
  get errdepartmentId() { return this.scheduleForm.get('departmentId'); }
  get errplantId() { return this.scheduleForm.get('plantId'); }
  get erreventCode() { return this.scheduleForm.get('eventCode'); }
  get errjuryId() { return this.scheduleForm.get('juryId'); }
  get errupload_excel() { return this.scheduleForm.get('upload_excel'); }
  //get errevaluationType() { return this.scheduleForm.get('evaluationType'); }
  get errauditType() { return this.scheduleForm.get('auditType'); }
  get errzone() { return this.scheduleForm.get('zone'); }

  onfocusEvaluation() {
    this.evaluationSearchInput.nativeElement.focus();
  }
  onInputChangeEvaluation() {
    const searchInput = this.evaluationSearchInput.nativeElement.value ? this.evaluationSearchInput.nativeElement.value.toLowerCase() : '';
    this.evaluation = this._evaluation.filter(c_data => {
      const c_name: any = c_data.evaluationName.toLowerCase();
      return c_name.indexOf(searchInput) > -1;
    })
  }

  onfocusPlant() {
    this.plantSearchInput.nativeElement.focus();
  }
  onInputChangePlant() {
    const searchInput = this.plantSearchInput.nativeElement.value ? this.plantSearchInput.nativeElement.value.toLowerCase() : '';
    this.plant = this._plant.filter(c_data => {
      const c_name: any = c_data.plantName.toLowerCase();
      return c_name.indexOf(searchInput) > -1;
    })
  }

  onfocusDepartment() {
    this.departmentSearchInput.nativeElement.focus();
  }
  onInputChangeDepartment() {
    const searchInput = this.departmentSearchInput.nativeElement.value ? this.departmentSearchInput.nativeElement.value.toLowerCase() : '';
    this.department = this._department.filter(bu_data => {
      return (bu_data.items.some(i => i.departmentName.toLowerCase().indexOf(searchInput.toLowerCase()) > -1));
    })
  }

  onfocusEvent() {
    this.eventSearchInput.nativeElement.focus();
  }
  onInputChangeEvent() {
    const searchInput = this.eventSearchInput.nativeElement.value ? this.eventSearchInput.nativeElement.value.toLowerCase() : '';
    this.event = this._event.filter(c_data => {
      const c_name: any = c_data.eventCode.toLowerCase();
      return c_name.indexOf(searchInput) > -1;
    })
  }

  onfocusJury() {
    this.jurySearchInput.nativeElement.focus();
  }
  onInputChangeJury() {
    const searchInput = this.jurySearchInput.nativeElement.value ? this.jurySearchInput.nativeElement.value.toLowerCase() : '';
    this.jury = this._jury.filter(c_data => {
      const c_name: any = c_data.juryName.toLowerCase();
      return c_name.indexOf(searchInput) > -1;
    })
  }

  submitSchedule(act){
    if(this.scheduleForm.invalid)
    {
      return;
    }
    if(act == "Create") {
      const formData = new FormData();
      formData.append('evaluationId', this.scheduleForm.get('evaluationId').value);
      formData.append('departmentId', this.scheduleForm.get('departmentId').value);
      formData.append('plantId', this.scheduleForm.get('plantId').value);
      formData.append('eventCode', this.scheduleForm.get('eventCode').value);
      formData.append('juryId', this.scheduleForm.get('juryId').value);
      formData.append('upload_excel', this.scheduleForm.get('upload_excel').value);
      //formData.append('evaluationType', this.scheduleForm.get('evaluationType').value);
      formData.append('auditType', this.scheduleForm.get('auditType').value);
      formData.append('zone', this.scheduleForm.get('zone').value);

      this.scheduleapi.addSchedule(formData)
      .subscribe(data => {
        this.router.navigate(['/Schedule_List']);
        this.toastr.success('Schedule Added Successfully');
      })
    }
    if(act == "Update") {
      const formData = new FormData();
      formData.append('scheduleId', this.scheduleForm.get('scheduleId').value);
      formData.append('evaluationId', this.scheduleForm.get('evaluationId').value);
      formData.append('departmentId', this.scheduleForm.get('departmentId').value);
      formData.append('plantId', this.scheduleForm.get('plantId').value);
      formData.append('eventCode', this.scheduleForm.get('eventCode').value);
      formData.append('juryId', this.scheduleForm.get('juryId').value);
      formData.append('upload_excel', this.scheduleForm.get('upload_excel').value);
      //formData.append('evaluationType', this.scheduleForm.get('evaluationType').value);
      formData.append('auditType', this.scheduleForm.get('auditType').value);
      formData.append('zone', this.scheduleForm.get('zone').value);

      this.scheduleapi.updateSchedule(formData).subscribe(data => {
        this.router.navigate(['/Schedule_List']);
        this.toastr.success('Schedule Updated Successfully');
      })
    }
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private menuDisable = new BehaviorSubject<boolean>(false);
  mszMenu$ = this.menuDisable.asObservable();

  constructor() { }

  sendMessage(message: boolean) {
    this.menuDisable.next(message);
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { IScheduleView } from './scheduleview';
import { Router, ActivatedRoute } from '@angular/router';
import { ScheduleviewlistService } from '../services/scheduleview/scheduleviewlist.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ScheduleService } from '../services/schedule/schedule.service';

@Component({
  selector: 'app-scheduleview',
  templateUrl: './scheduleview.component.html',
  styleUrls: ['./scheduleview.component.css']
})
export class ScheduleviewComponent implements OnInit {

  popoverTitle = "Delete Confirmation";
  popoverMessage = "Dear Admin would you like to Delete this Employee";
  confirmClicked = false;
  cancelClicked = false;

  displayedTeamColumns: string[] = ['sno', 'companyId', 'plantId', 'qcName', 'projectTheme', 'projectNo', 'baseLine', 'target', 'savings', 'actions'];
  displayedEmployeeColumns: string[] = ['sno','employeeId', 'employeeName', 'companyId', 'businessUnitId', 'plantId', 'projectTheme', 'projectNo', 'baseLine', 'target', 'savings', 'actions'];
  dataSource = new MatTableDataSource;

  schedulesview: IScheduleView[];
  constructor(private router:Router, private route: ActivatedRoute, private sheduleviewlistService: ScheduleviewlistService, private scheduleapi: ScheduleService,  private toastr: ToastrService) { }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  shEmployee: boolean = false;
  shTeam: boolean = false;
  shdId : number;

  token: any;
  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.token = window.localStorage.getItem('token');
    if(!this.token){
      this.router.navigate(['']);
    }

    this.dataSource.paginator = this.paginator;

    let id = parseInt(this.route.snapshot.paramMap.get('id'));
    this.shdId = id;
    this.scheduleapi.getSchedule(id).subscribe((data: any) => {
      if(data["upload_excel_type"] == "Employee") {
        this.shTeam = false;
        this.shEmployee = true;
      } else if(data["upload_excel_type"] == "Team") {
        this.shEmployee = false;
        this.shTeam = true;
      }
    });
    this.sheduleviewlistService.scheduleViewData(id).subscribe((data: IScheduleView[]) => {
      this.schedulesview = data;
      this.dataSource.data = data;
    });
  }

  delete(employees: IScheduleView):void {
    this.sheduleviewlistService.deleteScheduleView(employees.excelUploadId ).subscribe(data => { 
      this.schedulesview = this.schedulesview.filter(u => u !== employees)
      
      this.dataSource = new MatTableDataSource(this.schedulesview);
      this.dataSource.paginator = this.paginator;

      this.toastr.success('Employee Deleted Successfully');
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

}
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IEvent } from 'src/app/event/event';

@Injectable({
  providedIn: 'root'
})
export class EventlistService {

  private _url: string = environment.api_url;
  constructor(private _http: HttpClient) { }

  eventData(): Observable<IEvent[]>{
    return this._http.get<IEvent[]>(this._url+'/eventList.php');
  }

  geteventData(sel_id: any): Observable<any>{
    return this._http.get<any>(this._url+'/geteventList.php?id='+sel_id);
  }

  geteventPData(sel_id: any): Observable<any>{
    return this._http.post<any>(this._url+'/geteventList.php', sel_id);
  }
  
  geteventDData(sel_id: any): Observable<any>{
    return this._http.post<any>(this._url+'/geteventList.php', sel_id);
  }

  deleteEvent(id){
    return this._http.delete<IEvent>(this._url+'/deleteEvent.php?id='+id)
  }
}

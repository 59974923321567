
<!-- <app-spinner *ngIf="showSpinner"></app-spinner> -->
<h4 class="hdng">Feedback</h4>
<!-- <mat-card>Simple card</mat-card> -->

<!-- <mat-grid-list cols="3" rowHeight="2:1">
    <mat-grid-tile>1</mat-grid-tile>
    <mat-grid-tile>2</mat-grid-tile>
    <mat-grid-tile>3</mat-grid-tile>
  </mat-grid-list> -->

<mat-dialog-actions>
    <div class="session-popup" [formGroup]="questionForm">
    
    
    
    <table class="chartform table">
        <tr><td colspan="3"><strong>{{ data.juryName }}</strong></td></tr>
        <tr>
            <td><strong>{{ data.juryMobile }}</strong></td>
            <td>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Lane Line</mat-label>
                    <textarea matInput placeholder="Lane Line" formControlName="laneLine" id="sub_question_phase_criteria" style="resize:none;"></textarea>
                </mat-form-field>
            </td>
            <td>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Email *</mat-label>
                    <textarea matInput placeholder="Email *" formControlName="email" id="sub_question_phase_criteria" style="resize:none;"></textarea>
                </mat-form-field>
            </td>
        </tr>

        <tr>
            <td>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Contact Details *</mat-label>
                    <textarea matInput placeholder="Contact Details *" formControlName="contact" id="sub_question_phase_criteria" style="resize:none;"></textarea>
                </mat-form-field>
            </td>

            <td>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Co-ordinator Details *</mat-label>
                    <textarea matInput placeholder="Co-ordinator Details *" formControlName="co_ordinator" id="sub_question_phase_criteria" style="resize:none;"></textarea>
                </mat-form-field> 
            </td>

            <td>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Assessor *</mat-label>
                    <textarea matInput placeholder="Assessor *" formControlName="assessor" id="sub_question_phase_criteria" style="resize:none;"></textarea>
                </mat-form-field>  
            </td>
        </tr>

        <tr><td><strong>{{ data.audit }}</strong></td><td><strong>{{ data.session_end_date }}</strong></td><td><strong>Audit Score Summary</strong></td></tr>

        
        </table>
        
        <table class="table" style="text-align: center;">
            <tr>
                <td *ngFor="let titl of data.title_name; let i = index;"><strong>{{ titl }}</strong></td>
            </tr>
            <tr>
                <td *ngFor="let titl_max of data.title_max; let i = index;"><strong *ngIf="i == 0">{{ titl_max }}</strong><span *ngIf="i > 0">{{ titl_max }}</span></td>
            </tr>
            <tr>
                <td *ngFor="let titl_award of data.title_award; let i = index;"><strong *ngIf="i == 0">{{ titl_award }}</strong><span *ngIf="i > 0">{{ titl_award }}</span></td>
            </tr>
        </table>
        


    <table>
        <tr>
            <td style="vertical-align: top;">
                <table class="table" formArrayName="positives">
                    <tr>
                        <td colspan="3">
                            <strong>Positive Points</strong>
                        </td>
                    </tr>
                    <tr *ngFor="let itemrow of positives.controls; let i=index" [formGroupName]="i">
                        <td style="width:2px; border-right: none; border-bottom: none; border-top: none;">{{ i+1 }}.</td>
                        <td style="border:none;">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Positive Points</mat-label>
                                <textarea matInput placeholder="Positive Points" formControlName="positivePoint" id="positivePoint" style="resize:none;"></textarea>
                            </mat-form-field>
                        </td>
                        
                        <td *ngIf="i>0" style="width:10px; border:none;">
                            <button mat-button color="warn" (click)="deletePositive(i)" >
                            Delete
                            </button>
                        </td>
                        <td *ngIf="i==0" style="width:10px; border:none;">
                            <button mat-button (click)="addPositive()" color="primary">Add</button>
                        </td>
                        
                    </tr>
        
                    
                </table>
            </td>
            <td style="vertical-align: top;">
                <table class="table" formArrayName="improvements">
                    <tr>
                        <td colspan="3">
                            <strong>Opportunity for Improvements</strong>
                        </td>
                    </tr>
                    <tr *ngFor="let itemrow of improvements.controls; let i=index" [formGroupName]="i">
                        <td style="width:2px; border-right: none; border-bottom: none; border-top: none;">{{ i+1 }}.</td>
                        <td style="border:none;">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Opportunity for Improvements</mat-label>
                                <textarea matInput placeholder="Opportunity for Improvements" formControlName="improvementPoint" id="improvement" style="resize:none;"></textarea>
                            </mat-form-field>
                        </td>
                        <td *ngIf="i>0" style="width:10px; border:none;">
                            <button mat-button color="warn" (click)="deleteImprovement(i)" >
                               Delete
                            </button>
                         </td>
                         <td *ngIf="i==0" style="width:10px; border:none;">
                            <button mat-button (click)="addImprovement()" color="primary">Add</button>
                         </td>
                        
                    </tr>
            
                    
                </table>
            </td>
        </tr>
    </table>

    <table>
        <tr>
            <td>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>General *</mat-label>
                    <textarea matInput placeholder="General *" formControlName="general" id="sub_question_phase_criteria" style="resize:none;"></textarea>
                </mat-form-field>
            </td>
        </tr>
    </table>
    

   

    <div class="form-group row">
        <div class="col-md-7 text-right">
            <input type="hidden" value="" formControlName="scheduleJuryId" />
          <button type="button" [disabled]="!questionForm.valid" (click)="submitFeedback()"  mat-raised-button color="primary">Submit</button>
      </div>
   </div>

    

</div>
   
</mat-dialog-actions>
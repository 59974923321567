import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JuryEvaluationService {

  private _url: string = environment.api_url;
  constructor(private _http: HttpClient) { }

  addScheduleEvaluation(question) {
    // let httpheaders = new HttpHeaders().set('Content-Type','application/Json');
    // let options = {
    //   headers: httpheaders
    // };
    // return this._http.post<IJuryEvaluation>(this._url+'/addJuryEvaluation.php',question, options);

    return this._http.post<any>(this._url+'/addJuryEvaluation.php',question);
  }

  editScheduleEvaluation(question) {
    return this._http.post<any>(this._url+'/editJuryEvaluation.php',question);
  }

  addGrades(grades) {
    return this._http.post<any>(this._url+'/addGrades.php',grades);
  }

  addRequest(request) {
    return this._http.post<any>(this._url+'/addRequest.php',request);
  }

  uploadFile(imageData) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this._http.post<any>(this._url+'/uploadEvaluationFile.php', imageData);
  }

  getSummary(summaryData) {
    return this._http.post<any>(this._url+'/evaluationSummary.php', summaryData);
  }

  getfeedback(feedbackData) {
    return this._http.post<any>(this._url+'/evaluationFeedback.php', feedbackData);
  }

  addFeedback(feedbackData) {
    return this._http.post<any>(this._url+'/addFeedback.php', feedbackData);
  }

  getSmsemail(smsemailData) {
    return this._http.post<any>(this._url+'/smsemail.php', smsemailData);
  }

  getResult(resultData) {
    return this._http.post<any>(this._url+'/evaluationResults.php', resultData);
  }

  endSchedule(scheduleEnd) {
    return this._http.post<any>(this._url+'/endSchedule.php', scheduleEnd);
  }

  reqpermission(reqData) {
    return this._http.post<any>(this._url+'/requestPermission.php', reqData);
  }

  getDashboard(dashboardData) {
    return this._http.post<any>(this._url+'/evaluationDashboard.php', dashboardData);
  }

  submitSession(EvaluationData) {
    return this._http.post<any>(this._url+'/SessionEnd.php', EvaluationData);
  }

  submitDay(DayEndData) {
    return this._http.post<any>(this._url+'/DayEnd.php', DayEndData);
  }

}

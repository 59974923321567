import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ITitle } from 'src/app/title/title';

@Injectable({
  providedIn: 'root'
})
export class TitlelistService {

  private _url: string = environment.api_url;
  constructor(private _http: HttpClient) { }

  titleData(): Observable<ITitle[]>{
    return this._http.get<ITitle[]>(this._url+'/titleList.php');
  }

  // optBusinessUnitData(): Observable<IOptBusinessUnit[]>{
  //   return this._http.get<IOptBusinessUnit[]>(this._url+'/optBusinessUnitList.php');
  // }

  optTitleData(sel_id: any): Observable<ITitle[]>{
    return this._http.get<ITitle[]>(this._url+'/optTitleList.php?id='+sel_id);
  }

  deletetitle(id){
    return this._http.delete<ITitle>(this._url+'/deleteTitle.php?id='+id)
  }

}

<div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between">
       <div class="iq-header-title">
          <h4 class="card-title">Grade Report</h4>
       </div>
    </div>
    <div class="iq-card-body">
       <div class="form-horizontal">
           
        <form #reportForm="ngForm" (ngSubmit)="submitReport(reportForm);">

        <div class="form-group row">
            <label class="control-label col-sm-2 align-self-center mb-0" for="event_date">From Date <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field (click)="fromDate.open()">
                  <input matInput readonly [matDatepicker]="fromDate" is-date name="fromDate" [ngModel]="" #rfromdate="ngModel">
                  <mat-datepicker-toggle [for]="fromDate" matSuffix></mat-datepicker-toggle>
                  <mat-datepicker #fromDate></mat-datepicker>
               </mat-form-field>
            </div>
         </div>

         <div class="form-group row">
            <label class="control-label col-sm-2 align-self-center mb-0" for="event_date">To Date <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field (click)="toDate.open()">
                  <input matInput readonly [matDatepicker]="toDate" name="toDate" [ngModel]="" #rtodate="ngModel">
                  <mat-datepicker-toggle [for]="toDate" matSuffix></mat-datepicker-toggle>
                  <mat-datepicker #toDate></mat-datepicker>
               </mat-form-field>
            </div>
         </div>

         <div class="form-group row">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Evaluation <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusEvaluation()">
                  <mat-label>Evaluation</mat-label>
                  <mat-select name="evaluation" (ngModelChange)="chgEvaluation($event)" [ngModel]="" #revaluation="ngModel">
                     <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Evaluation" #evaluationSearch (input)="onInputChangeEvaluation()" />
                     <mat-option value="">-- Select Evaluation --</mat-option>
                    <mat-option *ngFor="let ev of evaluation" [value]="ev.evaluationId">
                     {{ev.evaluationName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
           </div>
         </div>

         <div class="form-group row">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Event Code <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusEventCode()" >
                  <mat-label>Event Code</mat-label>
                  <mat-select name="eventCode" [ngModel]="" #reventCode="ngModel" (ngModelChange)="chgEventCode($event)">
                     <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Event Code" #eventCodeSearch (input)="onInputChangeEventCode()" />
                     <mat-option value="">-- Select Event Code --</mat-option>
                    <mat-option *ngFor="let ev of eventCode" [value]="ev.eventId">
                     {{ev.eventCode}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
           </div>
         </div>
          
          <div class="form-group row">
              <div class="col-md-7 text-right">
                <button type="submit" [disabled]="disableReportBtn || rfromdate.pristine || rtodate.pristine || reventCode.pristine || revaluation.pristine" mat-raised-button color="primary">Get Report</button>
            </div>
          </div>

        </form>
       </div>

       <div *ngIf="shreport">
            <a target="_blank" href="{{ url }}/generatePDF/pdf/AnalyticGradeReportPDF.php?evaluation_id={{PDFevaluation_id}}&eventCode={{PDFeventCode}}&fromDate={{PDFfromDate}}&toDate={{PDFtoDate}}" mat-raised-button color="primary">Generate PDF</a> &nbsp;

            <a target="_blank" href="{{ url }}/generateExcel/AnalyticGradeReport.php?evaluation_id={{PDFevaluation_id}}&eventCode={{PDFeventCode}}&fromDate={{PDFfromDate}}&toDate={{PDFtoDate}}" mat-raised-button color="primary">Generate Excel</a><br>

            <table class="table-table">
               <tr>
                  <th>Event Code</th> <th>:</th> <td>{{ filEventCode }}</td>
               </tr>
               <tr>
                  <th>Evaluation</th> <th>:</th> <td>{{ filEvaluationName }}</td>
               </tr>
               <tr>
                  <th>Dates</th> <th>:</th><td>{{ filFromDate }} - {{ filToDate }}</td>
               </tr>
         </table>
         <br>
           <table class="chartform table" *ngIf="disrec">
               <tr>
                    <th>#</th>
                    <th>Evaluation</th>
                    <th>Grade</th>
                    <th>Employee / Team Count</th>
               </tr>
               <tr *ngFor="let r of report_data; let i = index;">
                   <td>{{ i+1 }}.</td>
                   <td>{{ r.competitionName }}</td>
                   <td>{{ r.grade }}</td>
                   <td>{{ r.empCount }}</td>
               </tr>
           </table>
           <table class="table" *ngIf="!disrec">
            <tr>
                <th>#</th>
                <th>Competition</th>
                <th>Grade</th>
                <th>Employee / Team Count</th>
            </tr>
            <tr>
                <td colspan="4">No Records Found</td>
            </tr>
        </table>
       </div>


    </div>
 </div>
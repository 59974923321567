import { Component, OnInit, ViewChild } from '@angular/core';
import { IEvent } from '../event/event';
import { Router } from '@angular/router';
import { EventlistService } from '../services/event/eventlist.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-eventlist',
  templateUrl: './eventlist.component.html',
  styleUrls: ['./eventlist.component.css']
})
export class EventlistComponent implements OnInit {

  displayedColumns: string[] = ['sno','evaluationId', 'eventCompetition', 'eventDate', 'eventCode', 'eventevaluationtype', 'eventId'];
  dataSource = new MatTableDataSource;

  events: IEvent[];
  constructor(private router: Router, private eventlistService: EventlistService, private toastr: ToastrService) { }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  token: any;
  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.token = window.localStorage.getItem('token');
    if(!this.token){
      this.router.navigate(['']);
    }

    this.dataSource.paginator = this.paginator;

    this.eventlistService.eventData().subscribe((data: IEvent[]) => {
      this.events = data;
      this.dataSource.data = data;
    });
  }

  delete(events: IEvent):void {
    this.eventlistService.deleteEvent(events.eventId ).subscribe(data => { 
      this.events = this.events.filter(u => u !== events)

      this.dataSource = new MatTableDataSource(this.events);
      this.dataSource.paginator = this.paginator;

      this.toastr.success('Event Deleted Successfully');
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { IJury } from '../jury/jury';
import { Router } from '@angular/router';
import { JurylistService } from '../services/jury/jurylist.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-jurylist',
  templateUrl: './jurylist.component.html',
  styleUrls: ['./jurylist.component.css']
})
export class JurylistComponent implements OnInit {

  displayedColumns: string[] = ['sno','juryName', 'juryMobile', 'juryExpertise', 'companyId', 'businessUnitId', 'plantId', 'juryEmployeeCode', 'juryOrganization', 'juryPlace', 'juryId'];
  dataSource = new MatTableDataSource;

  juris: IJury[];
  constructor(private router: Router, private jurylistService: JurylistService, private toastr: ToastrService) { }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  token: any;
  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.token = window.localStorage.getItem('token');
    if(!this.token){
      this.router.navigate(['']);
    }

    this.dataSource.paginator = this.paginator;

    this.jurylistService.juryData().subscribe((data: IJury[]) => {
      this.juris = data;
      this.dataSource.data = data;
    });
  }

  delete(juris: IJury):void {
    this.jurylistService.deleteJury(juris.juryId ).subscribe(data => { 
      this.juris = this.juris.filter(u => u !== juris)

      this.dataSource = new MatTableDataSource(this.juris);
      this.dataSource.paginator = this.paginator;

      this.toastr.success('Jury / Auditor Deleted Successfully');
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

}

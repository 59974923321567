<div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between">
      <div class="iq-header-title">
        <h4 class="card-title pull-left">Evaluation Results</h4>
      </div>
      
    </div>
    <div class="iq-card-body company-list">

        <div class="session-popup">
            <div class="row threepop">
                <div class="col-md-4 assigned"><h4>Assigned - {{ totalAssigned }}</h4></div>
                <div class="col-md-4 completed"><h4>Completed - {{ completedAssigned }}</h4></div>
                <div class="col-md-4 pending"><h4>Pending - {{ remainAssigned }}</h4></div>
            </div>
        <form #gradeForm="ngForm" (ngSubmit)="submitMarks(gradeForm);">
            <div *ngIf="sessionEnd">
                <mat-form-field class="example-full-width" appearance="outline" *ngIf="resultRecords" style="width: 300px; float: right;">
                    <mat-label>Jury</mat-label>
                    <mat-select [ngModel]="gjury_id" name="jury_id" (ngModelChange)="chgJury($event)">
                    <mat-option value="0" *ngIf="jCo > 1">-- Select Jury --</mat-option>
                    <mat-option *ngFor="let jury of juries" [value]="jury.juryId">
                    {{jury.juryName}}
                    </mat-option>
                    </mat-select>
                    <!-- <mat-hint *ngIf="(errcompanyId.touched || errcompanyId.dirty) && errcompanyId.invalid"><span *ngIf="errcompanyId.errors?.required" class="error-msg">Company is Required</span></mat-hint> -->
                </mat-form-field>
            </div>
           <div class="clearfix"></div>
           
            <div class="ev-results">
                
                    <input type="hidden" name="jury_schedule_id" [(ngModel)]="juryScheduleID" />
                    <table class="table event-results" *ngIf="resultRecords">
                        <tr>
                            <th>#</th>
                            <th>Evaluated Date</th>
                            <th>Plant</th>
                            <th>Team / Employee Name</th>
                            <th>Max Marks</th>
                            <th>Awarded</th>
                            <th>Avg Score</th>
                            <th>Grade</th>
                            <th>Actions</th>
                        </tr>
                        <tr *ngFor="let summaryRow of summ_data; let i = index;" ngModelGroup="emp{{ summaryRow.excelUploadId }}">
                            <input type="hidden" name="employee_id" [ngModel]="summaryRow.excelUploadId" />
                            <td>{{ i+1 }}.</td>
                            <td>{{ summaryRow.date | date:'dd-MM-yyyy' }}</td>
                            <td>{{ summaryRow.plantName }}</td>
                            <td>{{ summaryRow.employeeName }}</td>
                            <td>{{ summaryRow.total_marks }}</td>
                            <td>{{ summaryRow.awarded_marks }}</td>
                            <td>{{ summaryRow.average_marks }}</td>
                            <td>
                                <div class="form-group row">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Grade</mat-label>
                                    <input matInput placeholder="Grade" [ngModel]="summaryRow.grade" class="qMark" name="grade">
                                    </mat-form-field>
                                </div>
                            </td>
                            <td><div class="edit-btn">

                                <i class="lar la-edit" routerLink="/EditEvaluation/{{ summaryRow.excelUploadId }}" *ngIf="summaryRow.accept_decline==1 && summaryRow.request_status==0"></i> 

                                <i class="lar la-clipboard" *ngIf="(summaryRow.request_status=='' || summaryRow.request_status==0 || summaryRow.request_status==1) && (summaryRow.accept_decline=='' || summaryRow.accept_decline==2)" id="{{ summaryRow.excelUploadId }}" (click)="sendRequest($event);"></i>

                                <!-- <span><font color="green">A</font></span> -->
                                <span *ngIf="summaryRow.accept_decline==2"><font color="red">D</font></span>

                            </div></td>
                        </tr>
                    </table>
            
            <table class="table" *ngIf="!resultRecords">
                <tr>
                    <th>#</th>
                    <th>Evaluated Date</th>
                    <th>Plant</th>
                    <th>Team / Employee Name</th>
                    <th>Max Marks</th>
                    <th>Awarded</th>
                    <th>Avg Score</th>
                    <th>Grade</th>
                    <th>Actions</th>
                </tr>
                <tr>
                    <td colspan="9">No Result Records Found</td>
                </tr>
            </table>
        </div>
        <div class="btn-grp" *ngIf="resultRecords">
            <div class=" add" *ngIf="sessionEnd">
                
                <button type="button" style="float:left;" mat-button mat-raised-button color="warn" [disabled]="disableSchEndBtn" (click)="endSchedule()">End Schedule</button>

                <button type="submit" style="float: right;" mat-button mat-raised-button color="warn" [disabled]="disableGradesBtn">Submit Grades</button>

                <span class="info"><i matTooltip='End your schedule permanently. Login to this schedule no longer available by clicking upon "End Schedule"' [matTooltipPosition]="positionAfter" class="las la-info-circle"></i></span>
            </div>
            <button type="button" mat-button mat-raised-button color="warn" [disabled]="sessionDisable" (click)="SessionEnd('some_text')" *ngIf="!sh_sessionEnd">Session End</button>
            <!-- <div class="pull-right add">
                <span class="info"><i class="las la-info-circle"></i></span> <button mat-raised-button color="primary">Logout</button>
            </div> -->
        </div>  
    </form>
        
        </div>
    </div>
  </div>

 
  <router-outlet></router-outlet>
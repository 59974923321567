import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IPlant } from './plant';
import { Router, ActivatedRoute } from '@angular/router';
import { PlantService } from '../services/plant/plant.service';
import { BusinessunitlistService } from '../services/businessunit/businessunitlist.service';
import { IOptBusinessUnit } from '../business-unit/opt_businessunit';
import { ToastrService } from 'ngx-toastr';
import { UserValidators } from '../services/custom_validation';

@Component({
  selector: 'app-plant',
  templateUrl: './plant.component.html',
  styleUrls: ['./plant.component.css']
})
export class PlantComponent implements OnInit {

  message: any;
  pageAct: string = "Create";
  hiddenId: boolean = false;
  plantForm: FormGroup;
  businessunit: IOptBusinessUnit[];
  _businessunit: IOptBusinessUnit[];
  constructor(private fb: FormBuilder, 
              private router: Router,
              private plantapi: PlantService,
              private route: ActivatedRoute,
              private businessUnitService: BusinessunitlistService, 
              private toastr: ToastrService,
              private service: UserValidators) { }

  token: any;

    @ViewChild('businessUnitSearch') businessUnitSearchInput: ElementRef;

  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.token = window.localStorage.getItem('token');
    if(!this.token){
      this.router.navigate(['']);
    }

    this.businessUnitService.optBusinessUnitData().subscribe((data: IOptBusinessUnit[]) => {
      this.businessunit = data;
      this._businessunit = data;
    });

    if(this.route.snapshot.paramMap.get('id'))
    {
      let id = parseInt(this.route.snapshot.paramMap.get('id'));
      this.pageAct = "Update";
      this.hiddenId = true;
      this.plantapi.getPlant(id).subscribe((data: any) => {
        this.plantForm.patchValue(data);
      })

      this.plantForm = this.fb.group({
        plantId: [''],
        businessUnitId: ['', [Validators.required]],
        plantName: ['', [Validators.required]],
        plantCode: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9]*$')], this.service.plantEditValidator(id)],
        plantLocation: ['', Validators.required]
      });
      
    } else {
      this.pageAct = "Create";
      this.hiddenId = false;

      this.plantForm = this.fb.group({
        businessUnitId: ['', [Validators.required]],
        plantName: ['', [Validators.required]],
        plantCode: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9]*$')], this.service.PlantValidator()],
        plantLocation: ['', Validators.required]
      });

    }

  }

  get errbusinessUnitId() { return this.plantForm.get('businessUnitId'); }
  get errplantName() { return this.plantForm.get('plantName'); }
  get errplantCode() { return this.plantForm.get('plantCode'); }
  get errplantLocation() { return this.plantForm.get('plantLocation'); }

  onfocus() {
    this.businessUnitSearchInput.nativeElement.focus();
  }

  chgBusinessUnit() {
    this.plantForm.get('plantCode').setValue('');
  }

  onInputChange() {
    const searchInput = this.businessUnitSearchInput.nativeElement.value ? this.businessUnitSearchInput.nativeElement.value.toLowerCase() : '';
    this.businessunit = this._businessunit.filter(bu_data => {
      return (bu_data.items.some(i => i.businessUnitName.toLowerCase().indexOf(searchInput.toLowerCase()) > -1));
    })
  }


  submitPlant(act){
    if(this.plantForm.invalid)
    {
      return;
    }
    if(act == "Create") {
      this.plantapi.addPlant(this.plantForm.value)
      .subscribe(data => {
        this.router.navigate(['/Plant_List']);
        this.toastr.success('Plant Added Successfully');
      })
    }
    if(act == "Update") {
      this.plantapi.updatePlant(this.plantForm.value).subscribe(() => {
        this.router.navigate(['/Plant_List']);
        this.toastr.success('Plant Updated Successfully');
      })
    }
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { IQuestion } from '../question/question';
import { Router } from '@angular/router';
import { QuestionlistService } from '../services/question/questionlist.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-questionlist',
  templateUrl: './questionlist.component.html',
  styleUrls: ['./questionlist.component.css']
})
export class QuestionlistComponent implements OnInit {

  displayedColumns: string[] = ['sno','evaluationId', 'questionPhase', 'questionType', 'questionId'];
  dataSource = new MatTableDataSource;

  question: IQuestion[];
  constructor(private router: Router, private questionlistService: QuestionlistService, private toastr: ToastrService) { }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  token: any;
  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.token = window.localStorage.getItem('token');
    if(!this.token){
      this.router.navigate(['']);
    }

    this.dataSource.paginator = this.paginator;

    this.questionlistService.questionData().subscribe((data: IQuestion[]) => {
      this.question = data;
      this.dataSource.data = data;
    });
  }

  delete(question: IQuestion):void {
    this.questionlistService.deleteQuestion(question.questionId ).subscribe(data => { 
      this.question = this.question.filter(u => u !== question)
      
      this.dataSource = new MatTableDataSource(this.question);
      this.dataSource.paginator = this.paginator;

      this.toastr.success('Question Deleted Successfully');
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

}

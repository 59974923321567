import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { JuryEvaluationService } from '../services/JuryEvaluation/jury-evaluation.service';
import { MessageService } from '../services/JuryEvaluation/message.service';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-smsmail',
  templateUrl: './smsmail.component.html',
  styleUrls: ['./smsmail.component.css']
})

export class SmsmailComponent implements OnInit {

  copyText: any;
  whatsappText: any;
  
  escapedFind: any;
  chgText: any;

  constructor(private router: Router, public dialogRef: MatDialogRef<SmsmailComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private snackBar: MatSnackBar, private messageData: MessageService, private evaluationapi: JuryEvaluationService, private matDialog: MatDialog, private clipboardApi: ClipboardService) { }

  ngOnInit(): void {
    this.copyText = 'Dear Jury / Auditor,\nWelcome to the QCC Conventions\n\nEvent Code: '+this.data.jevtCode+'\nEvent Date : '+this.data.jevtDate+'\nTime : 9:00 Am to 5:30 Pm\nWeb link : http://qcfievaluation.ymtsindia.com/JuryLogin\n\nUsername : '+this.data.jusername+'\nPassword : '+this.data.jpassword+'\n\nRegards,\nQCFI-Tirupati Chapter';

    this.whatsappText = 'Dear Jury / Auditor,%0a‎Welcome to the QCC Conventions%0a%0aEvent Code: '+this.data.jevtCode+'%0aEvent Date : '+this.data.jevtDate+'%0aTime : 9:00 Am to 5:30 Pm%0aWeb link : http://qcfievaluation.ymtsindia.com/JuryLogin%0a%0aUsername : '+this.data.jusername+'%0aPassword : '+this.data.jpassword+'%0a%0aRegards,%0aQCFI-Tirupati Chapter';
  }

  copyingText() {
    
    const arr = this.copyText.split('\n');

    arr.forEach(item => {
      const div = document.createElement('div');
      div.innerText = item;
      document.body.appendChild(div);
    });

    this.clipboardApi.copyFromContent(this.copyText);

    this.whatsappText = this.copyText;
    
    let snackBarRef = this.snackBar.open('Message copied to Clipboard.', '', { horizontalPosition: 'center', verticalPosition: 'top', duration: 3000 });
    
  }

}

<div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between">
       <div class="iq-header-title">
          <h4 class="card-title">Title List</h4>
       </div>
       <div class="pull-right add">
        <a routerLink="/Title" mat-stroked-button color="primary">Add New Title</a>
     </div>
    </div>
    <div class="iq-card-body company-list">
    <div class="form-group row">
        <div class="col-sm-12 col-lg-12">

            <div class="mat-elevation-z8">
                <mat-form-field appearance="outline" class="filter-dt">
                    <mat-label>Filter Data</mat-label>
                    <input (keyup)="applyFilter($event.target.value)" matInput placeholder="Filter Data">
                    <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
                    <!-- <mat-hint>Hint</mat-hint> -->
                  </mat-form-field>

                <table mat-table matSort [dataSource]="dataSource">
              
                  <!-- Position Column -->
                  <ng-container matColumnDef="sno">
                    <th mat-header-cell *matHeaderCellDef> # </th>
                    <td mat-cell *matCellDef="let element; let i = index;"> {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (i + 1) }}. </td>
                  </ng-container>
              
                  <!-- Name Column -->
                  <ng-container matColumnDef="evaluationId">
                    <th mat-header-cell *matHeaderCellDef> Evaluation </th>
                    <td mat-cell *matCellDef="let element"> {{element.evaluationId}} </td>
                  </ng-container>
              
                  <!-- Weight Column -->
                  <ng-container matColumnDef="titleName">
                    <th mat-header-cell *matHeaderCellDef> Title Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.titleName}} </td>
                  </ng-container>

                  <ng-container matColumnDef="titleId">
                    <th mat-header-cell *matHeaderCellDef>Actions </th>
                    <td mat-cell *matCellDef="let element">
                        <span class="material-icons icon-dt" title="Edit" routerLink="/Title/{{ element.titleId }}">
                          edit
                        </span>&nbsp;&nbsp;
                        <span class="material-icons text-danger icon-dt" title="Delete" (click)=delete(element)>
                          delete_outline
                        </span>
                    </td>
                  </ng-container>
                  
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <div *ngIf="dataSource.data.length === 0">
                    No Records Found!
                </div>
              
                <mat-paginator #paginatorRef [pageSize]="50" [pageSizeOptions]="[50, 100, 150]" showFirstLastButtons></mat-paginator>
              </div>


            <!-- <table class="table">
                <tr>
                    <th>#</th>
                    <th class="text-left">Evaluation</th>
                    <th class="text-left">Title Name</th>
                    <th class="text-center">Status</th>
                </tr>
                <tr *ngFor="let each_title of title; let t = index">
                    <td>{{ t+1 }}.</td>
                    <td class="text-left">{{ each_title.evaluationId}}</td>
                    <td class="text-left">{{ each_title.titleName}}</td>
                    <td class="text-center">
                        <div class="custom-control custom-switch custom-switch-color custom-control-inline">
          <input type="checkbox" class="custom-control-input bg-info" id="customSwitch06" checked="">
          <label class="custom-control-label" for="customSwitch06"></label>
          <a routerLink="/Title/{{ each_title.titleId }}" class="edit" title="{{ 'Edit '+each_title.titleName }}"><i class="lar la-edit"></i></a>
          <a (click)=delete(each_title) class="delete" title="{{ 'Delete '+each_title.titleName }}"><i class="lar la-trash-alt"></i></a>
       </div>
                    </td>
                </tr>
            </table> -->
        </div>
    </div>
                                  
    </div>
 </div>
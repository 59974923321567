<div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between">
       <div class="iq-header-title">
          <h4 class="card-title">{{ pageAct }} Question</h4>
       </div>
       <div class="pull-right add">
         <a routerLink="/Question_List" mat-stroked-button color="primary">Question List</a>
      </div>
    </div>
    <div class="iq-card-body">
      <strong class="red">* Fields are Required</strong>
       <div class="form-horizontal" [formGroup]="questionForm">

         <div class="form-group row" [ngClass]="{'has-error': (errevaluationId.touched || errevaluationId.dirty) && errevaluationId.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Evaluation <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusEvaluation()">
                  <mat-label>Evaluation</mat-label>
                  <mat-select formControlName="evaluationId" (ngModelChange)="gettitle($event)">
                     <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Evaluation" #evaluationSearch (input)="onInputChangeEvaluation()" />
                     <mat-option value="">-- Select Evaluation --</mat-option>
                    <mat-option *ngFor="let evaluation of evaluations" [value]="evaluation.evaluationId">
                     {{ evaluation.evaluationName }}
                    </mat-option>
                  </mat-select>
                  <mat-hint *ngIf="(errevaluationId.touched || errevaluationId.dirty) && errevaluationId.invalid"><span *ngIf="errevaluationId.errors?.required" class="error-msg">Evaluation is Required</span></mat-hint>
                </mat-form-field>
           </div>
         </div>

         <div class="form-group row" *ngIf="internal" [ngClass]="{'has-error': (errtitleId.touched || errtitleId.dirty) && errtitleId.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Title <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Title</mat-label>
                  <mat-select formControlName="titleId">
                    <mat-option *ngFor="let each_title of title" [value]="each_title.titleId">
                     {{ each_title.titleName }}
                    </mat-option>
                  </mat-select>
                  <mat-hint *ngIf="(errtitleId.touched || errtitleId.dirty) && errtitleId.invalid"><span *ngIf="errtitleId.errors?.required" class="error-msg">Title is Required</span></mat-hint>
                </mat-form-field>
           </div>
         </div>

         <div class="form-group row" [ngClass]="{'has-error': (errquestionType.touched || errquestionType.dirty) && errquestionType.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="evaluation_type">Question Type <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Question Type</mat-label>
                  <mat-select formControlName="questionType" (ngModelChange)="getQType($event)">
                     <mat-option value="Single">Single Question</mat-option>
                     <mat-option value="Multiple">Single with multiple Sub Questions</mat-option>
                  </mat-select>
                  <mat-hint *ngIf="(errquestionType.touched || errquestionType.dirty) && errquestionType.invalid"><span *ngIf="errquestionType.errors?.required" class="error-msg">Question Type is Required</span></mat-hint>
                </mat-form-field>
                
           </div>
         </div>

          <div class="form-group row" [ngClass]="{'has-error': (errquestionPhase.touched || errquestionPhase.dirty) && errquestionPhase.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="department_name">Phase / Criteria <sup class="red">*</sup></label>
            <div class="col-sm-5">
              <mat-form-field class="example-full-width" appearance="outline">
                 <mat-label>Phase / Criteria</mat-label>
                 <textarea matInput placeholder="Phase / Criteria" formControlName="questionPhase"></textarea>
                 <mat-hint *ngIf="(errquestionPhase.touched || errquestionPhase.dirty) && errquestionPhase.invalid"><span *ngIf="errquestionPhase.errors?.required" class="error-msg">Phase / Criteria is Required</span></mat-hint>
               </mat-form-field>
            </div>
         </div>

          <div *ngIf="single_que">
            <div class="form-group row" [ngClass]="{'has-error': (errquestionMarks.touched || errquestionMarks.dirty) && errquestionMarks.invalid}">
               <label class="control-label col-sm-2 align-self-center mb-0" for="department_name">Marks <sup class="red">*</sup></label>
               <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Marks</mat-label>
                  <input matInput placeholder="Marks" formControlName="questionMarks">
                  <mat-hint *ngIf="(errquestionMarks.touched || errquestionMarks.dirty) && errquestionMarks.invalid"><span *ngIf="errquestionMarks.errors?.required" class="error-msg">Marks is Required</span></mat-hint>
                  </mat-form-field>
               </div>
            </div>
          </div>


          <div *ngIf="multiple_que" formArrayName="subques">
             <div *ngFor="let itemrow of subques.controls; let i=index" [formGroupName]="i">

               <h4><strong>Sub Question {{i+1}}.</strong></h4>
               <div class="form-group row">
                  <label class="control-label col-sm-2 align-self-center mb-0" for="department_name">Sub Phase / Criteria <sup class="red">*</sup></label>
                  <div class="col-sm-5">
                    <mat-form-field class="example-full-width" appearance="outline">
                       <mat-label>Sub Phase / Criteria</mat-label>
                       <textarea matInput placeholder="Sub Phase / Criteria" formControlName="subQuestion" id="sub_question_phase_criteria{{i}}"></textarea>
                     </mat-form-field>
                  </div>
               </div>

               <div class="form-group row">
                  <label class="control-label col-sm-2 align-self-center mb-0" for="question_marks{{i}}">Marks <sup class="red">*</sup></label>
                  <div class="col-sm-{{i == 0 ? '5' : '4'}}">
                  <mat-form-field class="example-full-width" appearance="outline">
                     <mat-label>Marks</mat-label>
                     <input matInput placeholder="Marks" formControlName="subQuestionMarks" id="question_marks{{i}}">
                     </mat-form-field>
                  </div>
                  
                  <div class="col-sm-1" *ngIf="i>0">
                     <button mat-mini-fab color="warn" (click)="deleteSubQuestion(i)" aria-label="Delete Sub Question">
                        <mat-icon>delete</mat-icon>
                     </button>
                  </div>
               </div>

            </div>
            <button type="button" [disabled]="questionForm.get('subques').invalid" (click)="addSubQuestion()" mat-raised-button color="primary">Add Question</button>
          </div>


          <div class="form-group row">
              <div class="col-md-7 text-right">
               <input type="hidden" *ngIf="hiddenId" value="" formControlName="questionId" />
                <button type="button" [disabled]="!questionForm.valid"  (click)="submitQuestion(pageAct)"  mat-raised-button color="primary">Save</button>
            </div>
         </div>
       </div>
    </div>
 </div>
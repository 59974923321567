import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EvaluationrequestComponent } from '../evaluationrequest/evaluationrequest.component';
import { NoteComponent } from '../note/note.component';
import { HeadermessageService } from '../services/dashboard/headermessage.service';
import { JuryEvaluationService } from '../services/JuryEvaluation/jury-evaluation.service';
import { MessageService } from '../services/JuryEvaluation/message.service';
import { JurylogoutService } from '../services/jurylogout/jurylogout.service';
import { TooltipPosition } from '@angular/material/tooltip';
import { FeedbackcommentComponent } from '../feedbackcomment/feedbackcomment.component';

@Component({
  selector: 'app-evaluationsresults',
  templateUrl: './evaluationsresults.component.html',
  styleUrls: ['./evaluationsresults.component.css']
})
export class EvaluationsresultsComponent implements OnInit {

  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  positionAfter = this.positionOptions[0];
  positionBefore = this.positionOptions[1];
  juryScheduleID: any;
  totalAssigned: any;
  completedAssigned: any;
  gjury_id: any;
  remainAssigned: any;
  resultRecords: boolean;
  summ_data: any;
  juries: any;
  pageHead: string;
  showSpinner: boolean = true;
  disableGradesBtn: boolean = false;
  disableSchEndBtn: boolean = true;
  sessionEnd: boolean = true;
  sh_sessionEnd: boolean = true;
  jCo: any;
  jId: any;

  public sessionDisable: boolean = false;

  constructor(private router: Router, private evaluationapi: JuryEvaluationService, private headerData: HeadermessageService, private toastr: ToastrService, private snackBar: MatSnackBar, private matDialog: MatDialog, private JurylogoutService: JurylogoutService, public dialog: MatDialog, private messageData: MessageService, private route: ActivatedRoute) { }

  @ViewChild('gradeForm') gradeForm: NgForm;

  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.juryScheduleID = window.localStorage.getItem('jsch_j_id');

    this.headerData.sendMessage('Evaluation Results');
    this.headerData.mszHead$.subscribe(
      message => {
        this.pageHead = message;
      }
    );

    const formData = new FormData();
    formData.append('jury_schedule_id', this.juryScheduleID);
    
    this.evaluationapi.getResult(formData).subscribe((data) => {
      this.totalAssigned = data.total;
      this.completedAssigned = data.completed;
      this.remainAssigned=data.pending;
      this.gjury_id=data.gjury_id;
      //this.gjury_id=data.juDipId;
      this.juries = data.jury_data;
      this.jCo = data.juCou;
      this.jId = data.juDipId;

      if(data.gjury_id != '') {
        this.disableGradesBtn = false;
      }

      if(this.jCo <= 1)
      {
        this.disableGradesBtn = false;
        this.gjury_id = data.juDipId;
      }


      if(data.schgrd) {
        this.disableSchEndBtn = true;
      } else {
        this.disableSchEndBtn = false;
      }

      if(data.sumData)
      {
        this.resultRecords = true;
        this.summ_data = data.datalist;
        this.sessionEnd = data.jsessionend;
        this.sh_sessionEnd = data.sh_sessionend;
      } else {
        this.resultRecords = false;
      }
    });
  }

  SessionEnd(sessiontxt) {
    this.messageData.sendMessage(true);

    this.messageData.mszSession$.subscribe(
      message => {
        this.sessionDisable = message;
      }
    );
   //this.router.navigate(['/JuryEvaluation']);
   let snackBarRef = this.snackBar.openFromComponent(NoteComponent, { horizontalPosition: 'center', verticalPosition: 'top', panelClass: ['noteSnackBar'] });
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/EvaluationResults'], { relativeTo: this.route });
  }

  endSchedule() {
    const formData = new FormData();
    formData.append('jury_schedule_id', this.juryScheduleID);

    this.evaluationapi.getfeedback(formData).subscribe((data) => {
      
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.width = "80%";
      dialogConfig.height = "70%";
      dialogConfig.data = { juryName : data.juryName, juryMobile : data.juryMobile, audit : data.audit, session_end_date : data.session_end_date };
      this.dialog.open(FeedbackcommentComponent,dialogConfig);
      
    });

    this.evaluationapi.endSchedule(formData).subscribe((data) => {

      let snackBarRef = this.snackBar.open('Schedule ended successfully and please submit Feedback.', '', { horizontalPosition: 'center', verticalPosition: 'top', duration : 3000 });

      // setTimeout(() => {
      //   const logDetails = {
      //     schedule_jury_id: window.localStorage.getItem('jsch_j_id')
      //   };
      //   this.JurylogoutService.jurylogout(logDetails)
      //   .subscribe((data: any) => {
      //       if(data.message == "Successful Logout") {
      //         this.router.navigate(['/JuryLogin']);
      //       }
      //   });
      // }, 3000);
      
    });
  }

  chgJury(event)
  {
    // if(event != 0)
    // {
    //   this.disableGradesBtn = false;
    // } else {
    //   this.disableGradesBtn = true;
    // }
  }

  sendRequest(event) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = "50%";
    dialogConfig.height = "50%";
    dialogConfig.data = { empId : event.target.id, jury_schedule_id : this.juryScheduleID };
    this.dialog.open(EvaluationrequestComponent,dialogConfig);

   // let snackBarRef = this.snackBar.open('Evaluation submission has not yet done...','', { duration : 3000, horizontalPosition: 'center', verticalPosition: 'top' });
    
  }

  submitMarks(gradeForm: NgForm){

    if(gradeForm.form.controls['jury_id'].value == '' || gradeForm.form.controls['jury_id'].value == '0')
    {
      let snackBarRef = this.snackBar.open('Please choose Jury.', '', { horizontalPosition: 'center', verticalPosition: 'top', duration : 3000 });
      return false;
    }
    
    this.disableGradesBtn = true;
    this.showSpinner = true;

    this.evaluationapi.addGrades(gradeForm.form.value).subscribe((data) => {
      let snackBarRef = this.snackBar.open('Grades saved successfully.', '', { horizontalPosition: 'center', verticalPosition: 'top', duration : 3000 });
  
        setTimeout(() => {
          this.matDialog.closeAll();
          window.localStorage.removeItem('token');
          window.localStorage.removeItem('jtoken');
          if(window.localStorage.getItem('tokenact') == "admin")
          {
            this.router.navigate(['']);
          } else if(window.localStorage.getItem('tokenact') == "jury")
          {
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate(['/EvaluationResults'], { relativeTo: this.route });
            // const logDetails = {
            //   schedule_jury_id: window.localStorage.getItem('jsch_j_id')
            // };
        
            // this.JurylogoutService.jurylogout(logDetails)
            // .subscribe((data: any) => {
            //     if(data.message == "Successful Logout") {
            //       this.router.navigate(['/JuryLogin']);
            //     }
            // });
          }
        }, 3000);
    });
  
  }

}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { IEvaluation } from 'src/app/evaluation/evaluation';
import { EvaluationlistService } from 'src/app/services/evaluation/evaluationlist.service';
import { ReportService } from 'src/app/services/reports/report.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-analyticgrade',
  templateUrl: './analyticgrade.component.html',
  styleUrls: ['./analyticgrade.component.css']
})
export class AnalyticgradeComponent implements OnInit {

  evaluation: IEvaluation[];
  _evaluation: IEvaluation[];
  eventCode: any;
  _eventCode: any;
  filFromDate: any;
  filToDate: any;
  disableReportBtn: boolean = false;
  shreport: boolean = false;
  filEventCode: any;
  filEvaluationName: any;
  report_data: any;
  disrec: boolean = false;

  PDFevaluation_id: any;
  PDFeventCode: any;
  PDFfromDate: any;
  PDFtoDate: any;

  url: string = environment.api_url;

  constructor(private evaluationservice: EvaluationlistService, private reportapi: ReportService) { }

  @ViewChild('evaluationSearch') evaluationSearchInput: ElementRef;
  @ViewChild('eventCodeSearch') eventCodeSearchInput: ElementRef;
  @ViewChild('reportForm') reportForm: NgForm;

  ngOnInit(): void {
    this.evaluationservice.evaluationData().subscribe((data: IEvaluation[]) => {
      this.evaluation = data;
      this._evaluation = data;
    });

    this.reportapi.eventCodeData().subscribe((data: any) => {
      this.eventCode = data;
      this._eventCode = data;
    });

  }

  onfocusEvaluation() {
    this.evaluationSearchInput.nativeElement.focus();
  }
  onInputChangeEvaluation() {
    const searchInput = this.evaluationSearchInput.nativeElement.value ? this.evaluationSearchInput.nativeElement.value.toLowerCase() : '';
    this.evaluation = this._evaluation.filter(c_data => {
      const c_name: any = c_data.evaluationName.toLowerCase();
      return c_name.indexOf(searchInput) > -1;
    })
  }

  onfocusEventCode() {
    this.eventCodeSearchInput.nativeElement.focus();
  }
  onInputChangeEventCode() {
    const searchInput = this.eventCodeSearchInput.nativeElement.value ? this.eventCodeSearchInput.nativeElement.value.toLowerCase() : '';
    this.eventCode = this._eventCode.filter(c_data => {
      const c_name: any = c_data.eventCode.toLowerCase();
      return c_name.indexOf(searchInput) > -1;
    })
  }

  chgEvaluation(evaluation_id) {
    if(evaluation_id != "") {
      const formData = new FormData();
      formData.append('gc_evaluation_id', evaluation_id);
      this.reportapi.pev_gradeList(formData).subscribe((data: any) => {
        if(data.other['msz']) {
          this.eventCode = data.dataList;
          this._eventCode = data.dataList;
        } else {
          this.eventCode = [];
          this._eventCode = [];
        }
      });
    } else {
      this.eventCode = [];
      this._eventCode = [];
      this.reportForm.control.get('eventcode').reset();
    }
  }

  chgEventCode (eventCodeId) {
    if(eventCodeId == "") {
      this.disableReportBtn = true;
    } else {
      this.disableReportBtn = false;
    }
  }

  submitReport(reportForm: NgForm){
    this.disableReportBtn = true;

    this.reportapi.analyticsGradeReport(reportForm.form.value).subscribe((data) => {
      this.filEventCode = data.eventCode;
      this.filEvaluationName = data.evaluationName;
      this.filFromDate = data.rfromDate;
      this.filToDate = data.rtoDate;

      this.PDFevaluation_id = data.field_data['evaluationId'];
      this.PDFeventCode = data.field_data['eventCode'];
      this.PDFfromDate = data.field_data['fromDate'];
      this.PDFtoDate = data.field_data['toDate'];

      this.report_data = data.dataRecords;

      if(data.records) {
        this.disrec = true;
      } else {
        this.disrec = false;
      }

      this.disableReportBtn = false;
      this.shreport = true;
    });
  }

}

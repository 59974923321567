import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ISchedule } from 'src/app/schedule/schedule';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {
  private _url: string = environment.api_url;
  constructor(private _http: HttpClient) { }

  addSchedule(schedule){
    // let httpheaders = new HttpHeaders().set('Content-Type','application/Json');
    // let options = {
    //   headers: httpheaders
    // };
    //return this._http.post<any>(this._url+'/addEvaluation.php',evaluation);
    return this._http.post<any>(this._url+'/addSchedule.php',schedule);
  }

  getSchedule(id:number){
    return this._http.get<ISchedule[]>(this._url+'/eachSchedule.php?id='+id);
  }

  updateSchedule(schedule){
    return this._http.post<any>(this._url+'/updateSchedule.php', schedule);
  }

}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IEvaluation } from '../evaluation/evaluation';
import { Router, ActivatedRoute } from '@angular/router';
import { EvaluationlistService } from '../services/evaluation/evaluationlist.service';
import { ToastrService } from 'ngx-toastr';
import { TitleService } from '../services/title/title.service';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.css']
})
export class TitleComponent implements OnInit {

  message: any;
  pageAct: string = "Create";
  hiddenId: boolean = false;
  titleForm: FormGroup;
  evaluations: IEvaluation[];
  _evaluations: IEvaluation[];
  constructor(private fb: FormBuilder, 
              private router: Router, 
              private titleapi: TitleService,
              private route: ActivatedRoute,
              private evaluationlistService: EvaluationlistService,
              private toastr: ToastrService) { }

  token: any;

  @ViewChild('evaluationSearch') evaluationSearchInput: ElementRef;

  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.token = window.localStorage.getItem('token');
    if(!this.token){
      this.router.navigate(['']);
    }

    this.evaluationlistService.evaluationData().subscribe((data: IEvaluation[]) => {
      this.evaluations = data;
      this._evaluations = data;
    });

    if(this.route.snapshot.paramMap.get('id'))
    {
      let id = parseInt(this.route.snapshot.paramMap.get('id'));
      this.pageAct = "Update";
      this.hiddenId = true;
      this.titleapi.getTitle(id).subscribe((data: any) => {
        this.titleForm.patchValue(data);
      })

      this.titleForm = this.fb.group({
        titleId: [''],
        evaluationId: ['', [Validators.required]],
        titleName: ['', [Validators.required]]
      });
      
    } else {
      this.pageAct = "Create";
      this.hiddenId = false;

      this.titleForm = this.fb.group({
        evaluationId: ['', [Validators.required]],
        titleName: ['', [Validators.required]]
      });

    }

  }

  get errEvaluationId() { return this.titleForm.get('evaluationId'); }
  get errtitleName() { return this.titleForm.get('titleName'); }

  onfocusEvaluation() {
    this.evaluationSearchInput.nativeElement.focus();
  }
  onInputChangeEvaluation() {
    const searchInput = this.evaluationSearchInput.nativeElement.value ? this.evaluationSearchInput.nativeElement.value.toLowerCase() : '';
    this.evaluations = this._evaluations.filter(c_data => {
      const c_name: any = c_data.evaluationName.toLowerCase();
      return c_name.indexOf(searchInput) > -1;
    })
  }

  submitTitle(act){
    if(this.titleForm.invalid)
    {
      return;
    }
    if(act == "Create") {
      this.titleapi.addTitle(this.titleForm.value)
      .subscribe(data => {
        this.router.navigate(['/Title_List']);
        this.toastr.success('Title Added Successfully');
      })
    }
    if(act == "Update") {
      this.titleapi.updateTitle(this.titleForm.value).subscribe(() => {
        this.router.navigate(['/Title_List']);
        this.toastr.success('Title Updated Successfully');
      })
    }
  }

}

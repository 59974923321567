import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { JuryEvaluationService } from '../services/JuryEvaluation/jury-evaluation.service';

@Component({
  selector: 'app-evaluationrequest',
  templateUrl: './evaluationrequest.component.html',
  styleUrls: ['./evaluationrequest.component.css']
})
export class EvaluationrequestComponent implements OnInit {

  disableReqBtn: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EvaluationrequestComponent>, private matDialog: MatDialog, private evaluationapi: JuryEvaluationService, private snackBar: MatSnackBar, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
  }

  submitRequest(requestForm: NgForm){
    this.disableReqBtn = true;

    this.evaluationapi.addRequest(requestForm.form.value).subscribe((data) => {
      let snackBarRef = this.snackBar.open('Request Sent.', '', { horizontalPosition: 'center', verticalPosition: 'top', duration : 3000 });
        setTimeout(() => {
          this.matDialog.closeAll();
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['/EvaluationResults'], { relativeTo: this.route });
        }, 2000);
    });
  
  }

}

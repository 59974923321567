import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IPlant } from 'src/app/plant/plant';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlantService {

  private _url: string = environment.api_url;
  constructor(private _http: HttpClient) { }

  addPlant(plant: IPlant):Observable<IPlant>{
    let httpheaders = new HttpHeaders().set('Content-Type','application/Json');
    let options = {
      headers: httpheaders
    };
    return this._http.post<IPlant>(this._url+'/addPlant.php',plant, options);
  }

  getPlant(id:number){
    return this._http.get<IPlant[]>(this._url+'/eachPlant.php?id='+id);
  }

  updatePlant(plant: IPlant){
    return this._http.put(this._url+'/updatePlant.php?id='+plant.plantId, plant);
  }

}

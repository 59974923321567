import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EvaluationlistService } from '../services/evaluation/evaluationlist.service';
import { IEvaluation } from '../evaluation/evaluation';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-evaluationlist',
  templateUrl: './evaluationlist.component.html',
  styleUrls: ['./evaluationlist.component.css']
})
export class EvaluationlistComponent implements OnInit {

  displayedColumns: string[] = ['sno','evaluationName', 'evaluationCode', 'evaluationType', 'evaluationId'];
  dataSource = new MatTableDataSource;

  evaluations: IEvaluation[];
  constructor(private router: Router, private evaluationListService: EvaluationlistService, private toastr: ToastrService) { }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  token: any;
  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.token = window.localStorage.getItem('token');
    if(!this.token){
      this.router.navigate(['']);
    }

    this.dataSource.paginator = this.paginator;

    this.evaluationListService.evaluationData().subscribe((data: IEvaluation[]) => {
      this.evaluations = data;
      this.dataSource.data = data;
    });
  }

  delete(evaluations: IEvaluation):void {
    this.evaluationListService.deleteEvaluation(evaluations.evaluationId ).subscribe(data => { 
      this.evaluations = this.evaluations.filter(u => u !== evaluations)

      this.dataSource = new MatTableDataSource(this.evaluations);
      this.dataSource.paginator = this.paginator;

      this.toastr.success('Evaluation Deleted Successfully');
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IDepartment } from 'src/app/department/department';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  private _url: string = environment.api_url;
  constructor(private _http: HttpClient) { }

  addDepartment(department: IDepartment): Observable<IDepartment>{
    let httpheaders = new HttpHeaders().set('Content-Type','application/Json');
    let options = {
      headers: httpheaders
    };
    return this._http.post<IDepartment>(this._url+'/addDepartment.php',department, options);
  }

  getDepartment(id:number){
    return this._http.get<IDepartment[]>(this._url+'/eachDepartment.php?id='+id);
  }

  updateDepartment(department: IDepartment){
    return this._http.put(this._url+'/updateDepartment.php?id='+department.departmentId, department);
  }
}

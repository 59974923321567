import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IJury } from 'src/app/jury/jury';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JuryService {

  private _url: string = environment.api_url;
  constructor(private _http: HttpClient) { }

  addJury(jury: IJury): Observable<IJury>{
    let httpheaders = new HttpHeaders().set('Content-Type','application/Json');
    let options = {
      headers: httpheaders
    };
    return this._http.post<IJury>(this._url+'/addJury.php',jury, options);
  }

  getJury(id:number){
    return this._http.get<IJury[]>(this._url+'/eachJury.php?id='+id);
  }

  updateJury(jury: IJury){
    return this._http.put(this._url+'/updateJury.php?id='+jury.juryId, jury);
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { IEvaluation } from '../evaluation/evaluation';
import { Router, ActivatedRoute } from '@angular/router';
import { QuestionService } from '../services/question/question.service';
import { EvaluationlistService } from '../services/evaluation/evaluationlist.service';
import { ToastrService } from 'ngx-toastr';
import { TitlelistService } from '../services/title/titlelist.service';
import { ITitle } from '../title/title';
import { ISubQuestion } from './subquestion';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent implements OnInit {

  message: any;
  pageAct: string = "Create";
  hiddenId: boolean = false;
  questionForm: FormGroup;
  internal: boolean = false;
  multiple_que: boolean = false;
  single_que: boolean = true;
  evaluations: IEvaluation[];
  _evaluations: IEvaluation[];
  title: ITitle[];
  subquestion: ISubQuestion[];


  constructor(private fb: FormBuilder, private router: Router, private questionapi: QuestionService, private route: ActivatedRoute, private evaluationListService: EvaluationlistService, private titlelistService: TitlelistService, private toastr: ToastrService) { }

  token: any;

  @ViewChild('evaluationSearch') evaluationSearchInput: ElementRef;

  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.token = window.localStorage.getItem('token');
    if(!this.token){
      this.router.navigate(['']);
    }

    this.evaluationListService.evaluationData().subscribe((data: IEvaluation[]) => {
      this.evaluations = data;
      this._evaluations = data;
      //console.log(data);
    });

    if(this.route.snapshot.paramMap.get('id'))
    {
      let id = parseInt(this.route.snapshot.paramMap.get('id'));
      this.pageAct = "Update";
      this.hiddenId = true;
      this.questionapi.getQuestion(id).subscribe((data: any) => {
        //this.questionForm.patchValue(data);
        //console.log(data);
        this.questionForm.patchValue({
          questionId: data.questionId,
          evaluationId: data.evaluationId,
          titleId: data.titleId,
          questionType: data.questionType,
          questionPhase: data.questionPhase,
          questionMarks: data.questionMarks
        });

        this.subques.clear();
        data.subques.forEach(b => {
          const fg = this.initItemRows();
          fg.reset(b);
          this.subques.push(fg);
        });
      })

      this.questionForm = this.fb.group({
        questionId: [''],
        evaluationId: ['', [Validators.required]],
        titleId: ['', [Validators.required]],
        questionType: ['', [Validators.required]],
        questionPhase: ['', [Validators.required]],
        questionMarks: ['', [Validators.required]],
        subques: this.fb.array([
          this.initItemRows()
        ])
      });
      
    } else {
      this.pageAct = "Create";
      this.hiddenId = false;

      this.questionForm = this.fb.group({
        evaluationId: ['', [Validators.required]],
        titleId: ['', [Validators.required]],
        questionType: ['Single'],
        questionPhase: ['', [Validators.required]],
        questionMarks: ['', [Validators.required]],
        subques: this.fb.array([
          this.initItemRows()
        ])
      });
    }
  }

  get subques() {
    return this.questionForm.get('subques') as FormArray;
  }

  initItemRows(): FormGroup {
    return this.fb.group({
      subQuestion:[''],
      subQuestionMarks:[''],
    });
  }

  addSubQuestion() {
    this.subques.push(this.initItemRows());
  }

  deleteSubQuestion(index: number): void {
   (<FormArray>this.questionForm.get("subques")).removeAt(index);
  }

  getQType(jaVal: string){
    const errquestionMarks = this.questionForm.get('questionMarks');
    

    if(jaVal == "Single")
     {
      for (const key in this.subques.controls) {
        //console.log(key);
        const formGroup = this.subques.controls[key] as FormGroup;
        //console.log(formGroup.controls['subQuestion'].value);
        //console.log((<FormGroup>this.questionForm.get('subques')).controls[key.subQuestion]);
        (<FormGroup>this.questionForm.get('subques')).controls[key].clearValidators();
        (<FormGroup>this.questionForm.get('subques')).controls[key].updateValueAndValidity();
      }
      errquestionMarks.setValidators(Validators.required);
       this.multiple_que = false;
       this.single_que = true;
     } 
     else if(jaVal == "Multiple")
     {
      for (const key in this.subques.controls) {
        
        (<FormArray>this.questionForm.get('subques')).controls[key].setValidators(Validators.required);
        (<FormArray>this.questionForm.get('subques')).controls[key].updateValueAndValidity();
      }
      errquestionMarks.clearValidators();
      this.multiple_que = true;
      this.single_que = false;
     }
     errquestionMarks.updateValueAndValidity();
    
   }
  

  get errevaluationId() { return this.questionForm.get('evaluationId'); }
  get errquestionType() { return this.questionForm.get('questionType'); }
  get errtitleId() { return this.questionForm.get('titleId'); }
  get errquestionPhase() { return this.questionForm.get('questionPhase'); }
  get errquestionMarks() { return this.questionForm.get('questionMarks'); }

  onfocusEvaluation() {
    this.evaluationSearchInput.nativeElement.focus();
  }
  onInputChangeEvaluation() {
    const searchInput = this.evaluationSearchInput.nativeElement.value ? this.evaluationSearchInput.nativeElement.value.toLowerCase() : '';
    this.evaluations = this._evaluations.filter(c_data => {
      const c_name: any = c_data.evaluationName.toLowerCase();
      return c_name.indexOf(searchInput) > -1;
    })
  }

  gettitle(event: any){
    const errtitleId = this.questionForm.get('titleId');
    this.titlelistService.optTitleData(event).subscribe((data: ITitle[]) => {
      this.title = data;
      this.internal = true;
      errtitleId.setValidators(Validators.required);
      errtitleId.updateValueAndValidity();
    }, (error) => {
      this.internal = false;
      errtitleId.clearValidators();
      errtitleId.updateValueAndValidity();
    });
  }

  submitQuestion(act){
    if(this.questionForm.invalid)
    {
      return;
    }
    if(act == "Create") {
      this.questionapi.addQuestion(this.questionForm.value)
      .subscribe(data => {
        this.router.navigate(['/Question_List']);
        this.toastr.success('Question Added Successfully');
      })
    }
    if(act == "Update") {
      this.questionapi.updateQuestion(this.questionForm.value).subscribe(() => {
        this.router.navigate(['/Question_List']);
        this.toastr.success('Question Updated Successfully');
      })
    }
  }

}

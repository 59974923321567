import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICompany } from 'src/app/company/company';

@Injectable({
  providedIn: 'root'
})
export class CompanylistService {

  private _url: string = environment.api_url;

  constructor(private _http: HttpClient) { }
  
  companyData(): Observable<ICompany[]>{
    return this._http.get<ICompany[]>(this._url+'/companyList.php');
  }

  deleteCompany(id){
    return this._http.delete<ICompany>(this._url+'/deleteCompany.php?id='+id)
  }

}

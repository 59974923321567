import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DashboardService } from '../services/dashboard/dashboard.service';
import { JurylogoutService } from '../services/jurylogout/jurylogout.service';

@Component({
  selector: 'app-jurypopup',
  templateUrl: './jurypopup.component.html',
  styleUrls: ['./jurypopup.component.css']
})
export class JurypopupComponent implements OnInit {

  showSpinner: boolean = true;
  juryScheduleID: any;

  constructor(private router: Router, private JurylogoutService: JurylogoutService, public dialogRef: MatDialogRef<JurypopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private dashboardapi: DashboardService) { }

  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.juryScheduleID = window.localStorage.getItem('jsch_j_id');
    setTimeout(() => {
      this.showSpinner = false;
    }, 1500);
  }

  assignPend() {
    const formData = new FormData();
    formData.append('jury_schedule_id', this.juryScheduleID);

    this.dashboardapi.chgPendStatus(formData).subscribe((data) => {
      if(data.chgStatus) {
        
      }
    });
  }

  logOut() {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('jtoken');
    if(window.localStorage.getItem('tokenact') == "admin")
    {
      this.router.navigate(['']);
    } else if(window.localStorage.getItem('tokenact') == "jury")
    {
      const logDetails = {
        schedule_jury_id: window.localStorage.getItem('jsch_j_id')
      };
  
      this.JurylogoutService.jurylogout(logDetails)
      .subscribe((data: any) => {
          if(data.message == "Successful Logout") {
            this.dialogRef.close();
            this.router.navigate(['/JuryLogin']);
          }
      });
      
    }

  }

}

<div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between">
       <div class="iq-header-title">
          <h4 class="card-title">{{ pageAct }} Event</h4>
       </div>
       <div class="pull-right add">
         <a routerLink="/Event_List" mat-stroked-button color="primary">Event List</a>
      </div>
    </div>
    <div class="iq-card-body">
      <strong class="red">* Fields are Required</strong>
       <div class="form-horizontal" [formGroup]="eventForm">

        <div class="form-group row" [ngClass]="{'has-error': (errplantId.touched || errplantId.dirty) && errplantId.invalid}">
         <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Plant <sup class="red">*</sup></label>
         <div class="col-sm-5">
            <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusPlant()">
               <mat-label>Plant</mat-label>
               <mat-select formControlName="plantId" (ngModelChange)="getPlantDet($event)">
                  <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Plant" #plantSearch (input)="onInputChangePlant()" />
                  <mat-option value="">-- Select Plant --</mat-option>
                 <mat-option *ngFor="let item of plant" [value]="item.plantId">
                  {{item.plantName}}
                 </mat-option>
               </mat-select>
               <mat-hint *ngIf="(errplantId.touched || errplantId.dirty) && errplantId.invalid"><span *ngIf="errplantId.errors?.required" class="error-msg">Plant is Required</span></mat-hint>
             </mat-form-field>
        </div>
      </div>

         <div class="form-group row" [ngClass]="{'has-error': (erreventCompetition.touched || erreventCompetition.dirty) && erreventCompetition.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="evaluation_type">Competition Type <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Competition Type</mat-label>
                  <mat-select formControlName="eventCompetition">
                     <mat-option value="">-- Select Competition Type --</mat-option>
                     <mat-option value="First Level">First Level</mat-option>
                     <mat-option value="Daily">Daily</mat-option>
                  </mat-select>
                  <mat-hint *ngIf="(erreventCompetition.touched || erreventCompetition.dirty) && erreventCompetition.invalid"><span *ngIf="erreventCompetition.errors?.required" class="error-msg">Competition Type is Required</span></mat-hint>
                </mat-form-field>
           </div>
         </div>

         <div class="form-group row" [ngClass]="{'has-error': (erreventDate.touched || erreventDate.dirty) && erreventDate.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="event_date">Date <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <!-- <input type="text" class="form-control" (ngModelChange)="getDateDet($event)" formControlName="eventDate" id="event_date" bsDatepicker [bsConfig]="datePickerConfig" placeholder="Event Date"> -->
               <!-- <input type="date" class="form-control" (ngModelChange)="getDateDet($event)" formControlName="eventDate" id="event_date" placeholder="Event Date"> -->


               <!-- [max]=maxDate -->
               <mat-form-field (click)="myDatePicker.open()">
                  <input matInput readonly [matDatepicker]="myDatePicker" formControlName="eventDate" (dateChange)="getDateDet($event)">
                  <mat-datepicker-toggle [for]="myDatePicker" matSuffix></mat-datepicker-toggle>
                  <mat-datepicker #myDatePicker></mat-datepicker>
               </mat-form-field>

               <span class="help-block" *ngIf="(erreventDate.touched || erreventDate.dirty) && erreventDate.invalid">
                  <span *ngIf="erreventDate.errors?.required">Event Date is Required</span>
               </span>
            </div>
         </div>

        <div class="form-group row" [ngClass]="{'has-error': (errevaluationId.touched || errevaluationId.dirty) && errevaluationId.invalid}">
         <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Evaluation <sup class="red">*</sup></label>
         <div class="col-sm-5">
            <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusEvaluation()">
               <mat-label>Evaluation</mat-label>
               <mat-select formControlName="evaluationId" (ngModelChange)="getEvaluationDet($event)">
                  <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Evaluation" #evaluationSearch (input)="onInputChangeEvaluation()" />
                  <mat-option value="">-- Select Evaluation --</mat-option>
                 <mat-option *ngFor="let item of evaluation" [value]="item.evaluationId">
                  {{item.evaluationName}}
                 </mat-option>
               </mat-select>
               <mat-hint *ngIf="(errevaluationId.touched || errevaluationId.dirty) && errevaluationId.invalid"><span *ngIf="errevaluationId.errors?.required" class="error-msg">Evaluation is Required</span></mat-hint>
             </mat-form-field>
        </div>
      </div>

         <div class="form-group row" [ngClass]="{'has-error': (erreventevaluationtype.touched || erreventevaluationtype.dirty) && erreventevaluationtype.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="evaluation_type">Evaluation Type <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Evaluation Type</mat-label>
                  <mat-select formControlName="eventevaluationtype">
                     <mat-option value="">-- Select Evaluation Type --</mat-option>
                     <mat-option value="Single Jury">Single Jury</mat-option>
                     <mat-option value="Multi Jury">Multi Jury</mat-option>
                  </mat-select>
                  <mat-hint *ngIf="(erreventevaluationtype.touched || erreventevaluationtype.dirty) && erreventevaluationtype.invalid"><span *ngIf="erreventevaluationtype.errors?.required" class="error-msg">Evaluation Type is Required</span></mat-hint>
                </mat-form-field>
           </div>
         </div>

          <div class="form-group row">
            <label class="control-label col-sm-3 align-self-center mb-0">Event Code</label>
            <div class="col-sm-5">
               <input type="hidden" class="form-control" formControlName="eventCode" placeholder="Event Code">
               <div><strong>{{ eventForm.get('eventCode').value }}</strong></div>
            </div>
         </div>
          
          <div class="form-group row">
              <div class="col-md-7 text-right">
                <input type="hidden" *ngIf="hiddenId" value="" formControlName="eventId" />
                <button type="button" [disabled]="!eventForm.valid"  (click)="submitEvent(pageAct)"  mat-raised-button color="primary">Save</button>
            </div>
          </div>
       </div>
    </div>
 </div>
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  api_url: 'https://evaluationtest.ymtsindia.com/web_api',
  url: 'https://evaluationtest.ymtsindia.com',

  // api_url: "http://localhost:2221/angular_api",
  // url: "http://localhost:2221"

  //api_url: "https://sameeksha.saiwebtechnos.com/web_api",
  //url: "https://sameeksha.saiwebtechnos.com"

  ///api_url: "http://localhost/angular_api",
  ///url: "http://localhost:4200"

  /// api_url: "http://arsrva014/sameeksha/angular_api",
  /// url: "http://arsrva014/sameeksha"

  //  api_url: "http://10.111.0.185:3000/angular_api",
  //  url: "http://10.111.0.185:3000"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

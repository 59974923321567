import { Component, OnInit, ViewChild } from '@angular/core';
import { ITitle } from '../title/title';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TitlelistService } from '../services/title/titlelist.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-titlelist',
  templateUrl: './titlelist.component.html',
  styleUrls: ['./titlelist.component.css']
})
export class TitlelistComponent implements OnInit {

  displayedColumns: string[] = ['sno','evaluationId', 'titleName', 'titleId'];
  dataSource = new MatTableDataSource;

  title: ITitle[];
  constructor(private router: Router, private titlelistService: TitlelistService, private toastr: ToastrService) { }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  token: any;
  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.token = window.localStorage.getItem('token');
    if(!this.token){
      this.router.navigate(['']);
    }

    this.dataSource.paginator = this.paginator;

    this.titlelistService.titleData().subscribe((data: ITitle[]) => {
      this.title = data;
      this.dataSource.data = data;
    });
  }

  delete(title: ITitle):void {
    this.titlelistService.deletetitle(title.titleId ).subscribe(data => { 
      this.title = this.title.filter(u => u !== title)

      this.dataSource = new MatTableDataSource(this.title);
      this.dataSource.paginator = this.paginator;

      this.toastr.success('Title Deleted Successfully');
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

}

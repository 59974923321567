
<div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between">
      <div class="iq-header-title">
        <h4 class="card-title pull-left">Dashboard</h4>
      </div>
      
    </div>
    <div class="iq-card-body">

        <div class="row bd-front">
          <div class="col-md-12">
            <form #reportForm="ngForm" (ngSubmit)="submitReport(reportForm);">
            <div class="form-group row">
              
              <div class="col-sm-2">
                <mat-form-field (click)="fromDate.open()">
                    <input matInput readonly [matDatepicker]="fromDate" is-date name="fromDate" placeholder="From Date" [ngModel]="" #rfromdate="ngModel">
                    <mat-datepicker-toggle [for]="fromDate" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #fromDate></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="col-sm-2">
                <mat-form-field (click)="toDate.open()">
                  <input matInput readonly [matDatepicker]="toDate" name="toDate" placeholder="To Date" [ngModel]="" #rtodate="ngModel">
                  <mat-datepicker-toggle [for]="toDate" matSuffix></mat-datepicker-toggle>
                  <mat-datepicker #toDate></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="col-sm-2" style="padding-left:0px;">
                <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusCompany()" >
                  <mat-label>Company</mat-label>
                  <mat-select name="company" [ngModel]="" (ngModelChange)="chgCompany($event)" #rcompany="ngModel">
                     <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Company" #companySearch (input)="onInputChangeCompany()" />
                     <mat-option value="">-- Select Company --</mat-option>
                    <mat-option *ngFor="let com of company" [value]="com.companyId">
                     {{com.companyName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-sm-2" style="padding-left:0px;">
                <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusBusinessUnit()" >
                  <mat-label>Business Unit</mat-label>
                  <mat-select name="businessUnit" [ngModel]="" (ngModelChange)="chgBusinessUnit($event)" #resetbusinessunit="ngModel">
                     <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Business Unit" #businessUnitSearch (input)="onInputChangeBusinessUnit()" />
                     <mat-option value="">-- Select Business Unit --</mat-option>
                    <mat-option *ngFor="let bu of businessunit" [value]="bu.businessUnitId">
                     {{bu.businessUnitName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-sm-2" style="padding-left:0px;">
                <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusPlant()" >
                  <mat-label>Plant</mat-label>
                  <mat-select name="plant" [ngModel]="" #resetplant>
                     <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Plant" #plantSearch (input)="onInputChangePlant()" />
                     <mat-option value="">-- Select Plant --</mat-option>
                    <mat-option *ngFor="let p of plant" [value]="p.plantId">
                     {{p.plantName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-sm-2" style="padding-left:0px; padding-top:5px;">
                <button type="submit" [disabled]="disableReportBtn || rfromdate.pristine || rtodate.pristine" mat-raised-button color="primary">Get Data</button>
              </div>
           </div>
          
          </form>
    
          </div>
        
        <div class="col-md-4">
          <article>
            <img src="assets/images/icon-schedule.png">
              <h2 class="asgnd">Scheduled - <strong>{{ totalSchedules }}</strong></h2>
          </article>      
        </div>

        <div class="col-md-4">
          <article>
            <img src="assets/images/icon-inprogress.png">
              <h2 class="inprgs">Progress - <strong>{{ progressedSchedules }}</strong></h2>
          </article>      
        </div>

        <div class="col-md-4">
          <article>
            <img src="assets/images/icon-completed.png">
              <h2 class="cmpltd">Closed - <strong>{{ completedSchedules }}</strong></h2>
          </article>      
        </div>

        <!-- <div class="col-md-3">
            <article>
              <img src="assets/images/icon-defrd.png">
                <h2 class="defrd">Deferred - <strong>10000</strong></h2>
            </article>      
          </div> -->

          
          <div class="col-md-12" style="margin-top:25px;" *ngIf="chartReady">
            <h3 class="tith">Evaluation (Team & Individual)</h3>
            <!-- <canvas baseChart
               [data]="data"
               [labels]="labels"
               [options]="options"
               [colors]="pieChartColors"
               [chartType]="pieChartType">
            </canvas> -->

            <ngx-charts-bar-vertical-2d
              [view]="view"
              [scheme]="colorScheme"
              [results]="evalu"
              [gradient]="gradient"
              [xAxis]="showXAxis"
              [yAxis]="showYAxis"
              [legend]="showLegend"
              [showXAxisLabel]="showXAxisLabel"
              [showYAxisLabel]="showYAxisLabel"
              [xAxisLabel]="xAxisLabel"
              [yAxisLabel]="yAxisLabel"
              [legendTitle]="legendTitle"
              (select)="onSelect($event)"
              (activate)="onActivate($event)"
              (deactivate)="onDeactivate($event)">
            </ngx-charts-bar-vertical-2d>


            
        </div>

        
          <div class="col-md-12" style="margin-top:25px;" *ngIf="chartReady">
            <h3 class="tith">Plant Evaluation</h3>
            <!-- <canvas baseChart
               [data]="data"
               [labels]="labels"
               [options]="options"
               [colors]="pieChartColors"
               [chartType]="pieChartType">
            </canvas> -->

            <ngx-charts-bar-vertical-2d
              [view]="view"
              [scheme]="colorScheme"
              [results]="plant_evalu"
              [gradient]="gradient"
              [xAxis]="showXAxis"
              [yAxis]="showYAxis"
              [legend]="showLegend"
              [showXAxisLabel]="showXAxisLabel"
              [showYAxisLabel]="showYAxisLabel"
              [xAxisLabel]="xAxisLabel"
              [yAxisLabel]="yAxisLabel"
              [legendTitle]="legendTitle"
              (select)="onSelect($event)"
              (activate)="onActivate($event)"
              (deactivate)="onDeactivate($event)">
            </ngx-charts-bar-vertical-2d>


            
        </div>

        
    </div>
</div>
    </div>
  <router-outlet></router-outlet>
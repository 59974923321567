import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BusinessunitService } from '../services/businessunit/businessunit.service';
import { ICompany } from '../company/company';
import { CompanylistService } from '../services/company/companylist.service';
import { ToastrService } from 'ngx-toastr';
import { UserValidators } from '../services/custom_validation';

@Component({
  selector: 'app-business-unit',
  templateUrl: './business-unit.component.html',
  styleUrls: ['./business-unit.component.css']
})
export class BusinessUnitComponent implements OnInit {

  message: any;
  pageAct: string = "Create";
  hiddenId: boolean = false;
  businessUnitForm: FormGroup
  companies: ICompany[];
  _companies: ICompany[];
  constructor(private fb: FormBuilder, 
            private router: Router, 
            private businessunitapi: BusinessunitService, 
            private route: ActivatedRoute,
            private companylistService: CompanylistService,
            private toastr: ToastrService,
            private service: UserValidators) { }

  token: any;

  @ViewChild('companySearch') companySearchInput: ElementRef;

  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.token = window.localStorage.getItem('token');
    if(!this.token){
      this.router.navigate(['']);
    }

    this.companylistService.companyData().subscribe((data: ICompany[]) => {
      this.companies = data;
      this._companies = data;
    });

    if(this.route.snapshot.paramMap.get('id'))
    {
      let id = parseInt(this.route.snapshot.paramMap.get('id'));
      this.pageAct = "Update";
      this.hiddenId = true;
      this.businessunitapi.getBusinessUnit(id).subscribe((data: any) => {
        this.businessUnitForm.patchValue(data);
      })

      this.businessUnitForm = this.fb.group({
        businessUnitId: [''],
        companyId: ['', [Validators.required]],
        businessUnitName: ['', [Validators.required]],
        businessUnitCode: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9]*$')], this.service.businessUnitEditValidator(id)]
      });
      
    } else {
      this.pageAct = "Create";
      this.hiddenId = false;

      this.businessUnitForm = this.fb.group({
        companyId: ['', [Validators.required]],
        businessUnitName: ['', [Validators.required]],
        businessUnitCode: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9]*$')], this.service.BusinessUnitValidator()]
      });

    }
  }

  get errCompanyId() { return this.businessUnitForm.get('companyId'); }
  get errbusinessUnitName() { return this.businessUnitForm.get('businessUnitName'); }
  get errbusinessUnitCode() { return this.businessUnitForm.get('businessUnitCode'); }

  onfocusCompany() {
    this.companySearchInput.nativeElement.focus();
  }
  onInputChangeCompany() {
    const searchInput = this.companySearchInput.nativeElement.value ? this.companySearchInput.nativeElement.value.toLowerCase() : '';
    this.companies = this._companies.filter(c_data => {
      const c_name: any = c_data.companyName.toLowerCase();
      return c_name.indexOf(searchInput) > -1;
    })
  }

  chgCompany() {
    this.businessUnitForm.get('businessUnitCode').setValue('');
  }

  submitBusinessUnit(act){
    if(this.businessUnitForm.invalid)
    {
      return;
    }
    if(act == "Create") {
      this.businessunitapi.addBusinessUnit(this.businessUnitForm.value)
      .subscribe(data => {
        this.router.navigate(['/Business_Unit_List']);
        this.toastr.success('Business Unit Added Successfully');
      })
    }
    if(act == "Update") {
      this.businessunitapi.updateBusinessUnit(this.businessUnitForm.value).subscribe(() => {
        this.router.navigate(['/Business_Unit_List']);
        this.toastr.success('Business Unit Updated Successfully');
      })
    }
  }

}

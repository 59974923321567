import { Component, OnInit } from '@angular/core';
import { IOptPlant } from '../plant/opt_plant';
import { ICompany } from '../company/company';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PlantlistService } from '../services/plant/plantlist.service';
import { CompanylistService } from '../services/company/companylist.service';
import { ToastrService } from 'ngx-toastr';
import { EmployeeService } from '../services/employee/employee.service';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {

  message: any;
  pageAct: string = "Create";
  hiddenId: boolean = false;
  employeeForm: FormGroup;
  plant: IOptPlant[];
  companies: ICompany[];
  nodata: boolean = false;
  plantselected: number = 0;
  schId: any;

  constructor(private fb: FormBuilder,
              private router: Router,
              private employeeapi: EmployeeService,
              private route: ActivatedRoute,
              private plantservice: PlantlistService, 
              private companylistService: CompanylistService,
              private employeelistService: EmployeeService, 
              private toastr: ToastrService) { }

  token: any;
  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.token = window.localStorage.getItem('token');
    if(!this.token){
      this.router.navigate(['']);
    }

    const numericNumberReg= '^-?[0-9]\\d*(\\.\\d{1,2})?$';

    this.companylistService.companyData().subscribe((data: ICompany[]) => {
      this.companies = data;
    });

    if(this.route.snapshot.paramMap.get('empid'))
    {
      let id = parseInt(this.route.snapshot.paramMap.get('id'));
      this.schId = id;
      this.pageAct = "Update";
      this.hiddenId = true;
      let empid = parseInt(this.route.snapshot.paramMap.get('empid'));
      this.employeelistService.getEmployee(empid).subscribe((data: any) => {
        this.employeeForm.patchValue(data);
      })

      this.employeeForm = this.fb.group({
        excelUploadId: [''],
        scheduleId: [this.schId],
        employeeId: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
        employeeName: ['', [Validators.required]],
        companyId: ['', [Validators.required]],
        plantId: ['', Validators.required],
        projectTheme: ['', [Validators.required]],
        projectNo: ['', [Validators.required]],
        baseLine: ['', Validators.required],
        target: ['', Validators.required],
        savings: ['', [Validators.required]]
      });
      
    } else {
      let id = parseInt(this.route.snapshot.paramMap.get('id'));
      this.schId = id;
      this.pageAct = "Assign";
      this.hiddenId = false;

      this.employeeForm = this.fb.group({
        scheduleId: [this.schId],
        employeeId: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
        employeeName: ['', [Validators.required]],
        companyId: ['', [Validators.required]],
        plantId: ['', Validators.required],
        projectTheme: ['', [Validators.required]],
        projectNo: ['', [Validators.required]],
        baseLine: ['', Validators.required],
        target: ['', Validators.required],
        savings: ['', [Validators.required]]
      });

    }

  }

  get errscheduleId() { return this.employeeForm.get('scheduleId'); }
  get erremployeeId() { return this.employeeForm.get('employeeId'); }
  get erremployeeName() { return this.employeeForm.get('employeeName'); }
  get errcompanyId() { return this.employeeForm.get('companyId'); }
  get errplantId() { return this.employeeForm.get('plantId'); }
  get errprojectTheme() { return this.employeeForm.get('projectTheme'); }
  get errprojectNo() { return this.employeeForm.get('projectNo'); }
  get errbaseLine() { return this.employeeForm.get('baseLine'); }
  get errtarget() { return this.employeeForm.get('target'); }
  get errsavings() { return this.employeeForm.get('savings'); }

  getbusiUnit(event: any){
    this.plantservice.optPlantData(event).subscribe((data: IOptPlant[]) => {
      this.plant = data;
    }, (error) => {
      this.employeeForm.get('plantId').patchValue('');
      this.plant = [];
    });
  }

  submitEmployee(act){
    
    if(this.employeeForm.invalid)
    {
      return;
    }
    if(act == "Assign") {
      this.employeeapi.addEmployee(this.employeeForm.value)
      .subscribe(data => {
        this.router.navigate(['/ScheduleView/'+data["scheduleId"]]);
        this.toastr.success('Employee Added Successfully');
      })
    }
    if(act == "Update") {
      this.employeeapi.updateEmployee(this.employeeForm.value).subscribe(data => {
        this.router.navigate(['/ScheduleView/'+data["scheduleId"]]);
        this.toastr.success('Employee Updated Successfully');
      })
    }
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  private _url: string = environment.api_url;

  constructor(private _http: HttpClient) { }

  analytic_report(report) {
    return this._http.post<any>(this._url+'/generatePDF/pdf/AnalyticReportPDF.php', report);
  }
  
  analyticsReport(filters) {
    return this._http.post<any>(this._url+'/analyticReport.php',filters);
  }

  dashboardReport(filters) {
    return this._http.post<any>(this._url+'/DashboardReport.php',filters);
  }

  plantEvaluationReport(filters) {
    return this._http.post<any>(this._url+'/plantEvaluationReport.php',filters);
  }

  plantAverageReport(filters) {
    return this._http.post<any>(this._url+'/plantAverageReport.php',filters);
  }

  plantYearlyAverageReport(filters) {
    return this._http.post<any>(this._url+'/plantYearlyAverageReport.php',filters);
  }

  plantCodeEvaluationReport(filters) {
    return this._http.post<any>(this._url+'/plantCodeEvaluationReport.php',filters);
  }

  schedulestatusReport(filters) {
    return this._http.post<any>(this._url+'/scheduleStatusReport.php',filters);
  }

  statusReport(filters) {
    return this._http.post<any>(this._url+'/StatusReport.php',filters);
  }

  eventCodeReport(filters) {
    return this._http.post<any>(this._url+'/EventCodeReport.php',filters);
  }

  analyticsGradeReport(filters) {
    return this._http.post<any>(this._url+'/analyticGradeReport.php',filters);
  }

  employeeTeamReport(filters) {
    return this._http.post<any>(this._url+'/EmployeeTeamReport.php',filters);
  }

  feedbackReport(filters) {
    return this._http.post<any>(this._url+'/FeedbackReport.php',filters);
  }

  businessUnits(company_id) {
    return this._http.post<any>(this._url+'/ReportList.php',company_id);
  }

  plants(businessunit_id) {
    return this._http.post<any>(this._url+'/ReportList.php',businessunit_id);
  }

  pev_evaluationList(pe_plant_id) {
    return this._http.post<any>(this._url+'/ReportList.php',pe_plant_id);
  }

  pav_evaluationList(pa_plant_id) {
    return this._http.post<any>(this._url+'/ReportList.php',pa_plant_id);
  }

  pev_eventCodeList(pe_evaluation_id) {
    return this._http.post<any>(this._url+'/ReportList.php',pe_evaluation_id);
  }

  pev_gradeList(gc_evaluation_id) {
    return this._http.post<any>(this._url+'/ReportList.php',gc_evaluation_id);
  }

  eventsCodes(plant_id) {
    return this._http.post<any>(this._url+'/ReportList.php',plant_id);
  }

  ev_eventsCodes(e_evaluation_id) {
    return this._http.post<any>(this._url+'/ReportList.php',e_evaluation_id);
  }

  closedeventsCodes(closed_plant_id) {
    return this._http.post<any>(this._url+'/ReportList.php',closed_plant_id);
  }

  employeeTeam(eventCode_id) {
    return this._http.post<any>(this._url+'/ReportList.php',eventCode_id);
  }

  eventCodeData(): Observable<any>{
    return this._http.get<any>(this._url+'/ReportList.php?p_act=eventcodelist');
  }

}

<div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between">
      <div class="iq-header-title">
        <h4 class="card-title">Feedback</h4>
      </div>
    </div>
    <div class="iq-card-body">
        <a target="_blank" href="{{ url }}/generatePDF/pdf/FeedbackReportPDF.php?eventcode={{eventcodeid}}" mat-raised-button color="primary">Generate PDF</a>
        <div class="session-popup" *ngFor="let fbj of feedbackJury; let fj = index;">

            <h4 class="red"><strong>#{{ fj+1 }} Jury</strong></h4>
            
            <table class="chartform table" style="border:1px solid #000;">
                <tr>
                    <td><strong>Jury Name : </strong>{{ fbj.juryName }}</td>
                    <td><strong>Mobile : </strong>{{ fbj.juryMobile }}</td>
                    <td><strong>Audit : </strong>{{ fbj.audit }}</td>
                </tr>
                <tr>
                    <td><strong>Lane Line : </strong>{{ fbj.laneline }}</td>
                    <td><strong>Email : </strong>{{ fbj.email }}</td>
                    <td><strong>Session End : </strong>{{ fbj.session_end_date }}</td>
                </tr>
        
                <tr>
                    <td><strong>Contact Details : </strong>{{ fbj.contactDetails }}</td>
                    <td><strong>Co-ordinate Details : </strong>{{ fbj.coordinatorDetail }}</td>
                    <td><strong>Assessor : </strong>{{ fbj.assessor }}</td>
                </tr>
        
                
            </table>
    
            <h4 style="color:blueviolet;"><strong>Audit Score Summary</strong></h4>
            
            <table class="table" style="text-align: center;" border="1">
                <tr>
                    <td *ngFor="let titl of fbj.titles; let i = index;"><strong>{{ titl }}</strong></td>
                </tr>
                <tr>
                    <td *ngFor="let titl_max of fbj.titles_max; let i = index;"><strong *ngIf="i == 0">{{ titl_max }}</strong><span *ngIf="i > 0">{{ titl_max }}</span></td>
                </tr>
                <tr>
                    <td *ngFor="let titl_award of fbj.titles_award; let i = index;"><strong *ngIf="i == 0">{{ titl_award }}</strong><span *ngIf="i > 0">{{ titl_award }}</span></td>
                </tr>
            </table>
        
            <table border="1">
                <tr>
                    <td style="vertical-align: top; width: 50%;">
                        <table class="table">
                            <tr>
                                <td colspan="2">
                                    <strong>Positive Points</strong>
                                </td>
                            </tr>
                            <tr *ngFor="let pp of fbj.positive_points; let pi = index;">
                                <td style="width:1%">{{ pi+1 }}.</td>
                                <td>{{ pp }}</td>
                            </tr>
                            
                        </table>
                    </td>
                    <td style="vertical-align: top; width: 50%;">
                        <table class="table">
                            <tr>
                                <td colspan="2">
                                    <strong>Opportunity for Improvements</strong>
                                </td>
                            </tr>
                            <tr *ngFor="let impr of fbj.improvement_points; let ii = index;">
                                <td style="width:1%">{{ ii+1 }}.</td>
                                <td>{{ impr }}</td>
                            </tr>
                    
                        </table>
                    </td>
                </tr>
            </table>
        
            <table style="margin-top:15px;">
                <tr>
                    <td><strong>General : </strong><i>{{ fbj.general }}</i></td>
                </tr>
            </table>
        
            
        
        </div>
    </div>
  </div>
  <router-outlet></router-outlet>


    

import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ResultsummaryComponent } from '../resultsummary/resultsummary.component';
import { JuryEvaluationService } from '../services/JuryEvaluation/jury-evaluation.service';
import { JurylogoutService } from '../services/jurylogout/jurylogout.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  questionForm: FormGroup;
  juryScheduleID: any;

  constructor(private fb: FormBuilder, private router: Router, public dialogRef: MatDialogRef<ResultsummaryComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private evaluationapi: JuryEvaluationService, private toastr: ToastrService, private JurylogoutService: JurylogoutService, private matDialog: MatDialog) { }

  ngOnInit(): void {

    this.juryScheduleID = window.localStorage.getItem('jsch_j_id');


    this.questionForm = this.fb.group({
     // questionId: [''],
      laneLine: [''],
      email: ['', [Validators.required]],
      contact: ['', [Validators.required]],
      co_ordinator: ['', [Validators.required]],
      assessor: ['', [Validators.required]],
      general: ['', [Validators.required]],
      scheduleJuryId: [this.juryScheduleID],
      positives: this.fb.array([
        this.initPositiveRows()
      ]),
      improvements: this.fb.array([
        this.initImprovementRows()
      ])
    });


  }

  addPositive() {
    this.positives.push(this.initPositiveRows());
  }
  addImprovement() {
    this.improvements.push(this.initImprovementRows());
  }

  deletePositive(index: number): void {
    (<FormArray>this.questionForm.get("positives")).removeAt(index);
  }

  deleteImprovement(index: number): void {
    (<FormArray>this.questionForm.get("improvements")).removeAt(index);
  }

  get positives() {
    return this.questionForm.get('positives') as FormArray;
  }

  get improvements() {
    return this.questionForm.get('improvements') as FormArray;
  }

  initPositiveRows(): FormGroup {
    return this.fb.group({
      positivePoint:[''],
    });
  }

  initImprovementRows(): FormGroup {
    return this.fb.group({
      improvementPoint:[''],
    });
  }

  submitFeedback(){
    if(this.questionForm.invalid)
    {
      return;
    }
    
    this.evaluationapi.addFeedback(this.questionForm.value)
    .subscribe(data => {

      const logDetails = {
        schedule_jury_id: window.localStorage.getItem('jsch_j_id')
      };
      this.JurylogoutService.jurylogout(logDetails)
      .subscribe((data: any) => {
          if(data.message == "Successful Logout") {
            this.toastr.success('Feedback Submitted Successfully and Logged out');
            setTimeout(() => {
              this.matDialog.closeAll();
              window.localStorage.removeItem('token');
              window.localStorage.removeItem('jtoken');
            }, 3000);
            this.router.navigate(['/JuryLogin']);
          }
      });

      
    })

  }
}

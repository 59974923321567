<div class="clred" mat-dialog-close><strong *ngIf='!sessionDisable'><i class="las la-times"></i></strong></div>
<!-- <app-spinner *ngIf="showSpinner"></app-spinner> -->
<h4 class="hdng">Results Summary</h4>
<!-- <mat-card>Simple card</mat-card> -->

<!-- <mat-grid-list cols="3" rowHeight="2:1">
    <mat-grid-tile>1</mat-grid-tile>
    <mat-grid-tile>2</mat-grid-tile>
    <mat-grid-tile>3</mat-grid-tile>
  </mat-grid-list> -->

<mat-dialog-actions>
    <div class="session-popup">
    <div class="row threepop" *ngIf="!data.evoid">
        <div class="col-md-4 assigned"><h4>Assigned - {{ data.totalAssigned }}</h4></div>
        <div class="col-md-4 completed"><h4>Completed - {{ data.completedAssigned }}</h4></div>
        <div class="col-md-4 pending"><h4>Pending - {{ data.remainAssigned }}</h4></div>
    </div>
    <div class="btn-grp">
        <div class="pull-left add">
            <button mat-raised-button color="warn" [disabled]='sessionDisable' (click)="SessionEnd('some_text')">Session End</button>
            <span class="info"><i matTooltip="Submit your total schedule evaluation and proceed to grades" [matTooltipPosition]="positionAfter" class="las la-info-circle"></i></span>
        </div>
        <div class="pull-right add">
            <span class="info"><i matTooltip="Submit your day evaluation" [matTooltipPosition]="positionBefore" class="las la-info-circle"></i></span> <button mat-raised-button [disabled]='sessionDisable' color="primary" (click)="DayEnd()">Day End</button>
        </div>
    </div>
    <table class="table">
        <tr>
            <th>#</th>
            <th>Evaluated Date</th>
            <th>Plant</th>
            <th *ngIf="!data.evoid">Team / Employee Name</th>
            <th *ngIf="data.evoid">Title</th>
            <th>Max Marks</th>
            <th>Awarded</th>
            <!-- <th *ngIf="!data.evoid">Avg Score</th> -->
            <th *ngIf="!data.evoid">Edit</th>
        </tr>
        <tr *ngFor="let summaryRow of data.summ_data; let i = index;">
            <td>{{ i+1 }}.</td>
            <td>{{ summaryRow.date | date:'dd-MM-yyyy' }}</td>
            <td>{{ summaryRow.plantName }}</td>
            <td *ngIf="!data.evoid">{{ summaryRow.employeeName }}</td>
            <td *ngIf="data.evoid">{{ summaryRow.titleName }}</td>
            <td>{{ summaryRow.total_marks }}</td>
            <td>{{ summaryRow.awarded_marks }}</td>
            <!-- <td *ngIf="!data.evoid">{{ summaryRow.average_marks | number }}</td> -->
            <td *ngIf="!data.evoid">
                <span class="material-icons icon-dt" routerLink="/EditEvaluation/{{ summaryRow.excelUploadId }}" title="Edit">edit</span>
            </td>
        
        </tr>
    </table>
</div>
    <!-- <ul >
        <li><label>Evaluation</label></li>
        <li><label>Company</label></li>
        <li><label>Business</label></li>
        <li><label>Plant/Department</label></li>
        <li><label>Date</label></li>
        <li><label>Event Code</label></li>
        <li><label>Evaluation Type</label></li>
    </ul> -->
      
    <!-- <button mat-raised-button mat-dialog-close>Continue</button>
    <button mat-raised-button mat-dialog-close (click)="logOut()">LogOut</button> -->
</mat-dialog-actions>
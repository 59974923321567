<div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between">
       <div class="iq-header-title">
          <h4 class="card-title">{{ pageAct }} Title</h4>
       </div>
       <div class="pull-right add">
         <a routerLink="/Title_List" mat-stroked-button color="primary">Title List</a>
      </div>
    </div>
    <div class="iq-card-body">
      <strong class="red">* Fields are Required</strong>
       <div class="form-horizontal" [formGroup]="titleForm">

          <div class="form-group row" [ngClass]="{'has-error': (errEvaluationId.touched || errEvaluationId.dirty) && errEvaluationId.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Evaluation <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusEvaluation()">
                  <mat-label>Evaluation</mat-label>
                  <mat-select formControlName="evaluationId">
                     <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Evaluation" #evaluationSearch (input)="onInputChangeEvaluation()" />
                     <mat-option value="">-- Select Evaluation --</mat-option>
                    <mat-option *ngFor="let evaluation of evaluations" [value]="evaluation.evaluationId">
                     {{evaluation.evaluationName}}
                    </mat-option>
                  </mat-select>
                  <mat-hint *ngIf="(errEvaluationId.touched || errEvaluationId.dirty) && errEvaluationId.invalid"><span *ngIf="errEvaluationId.errors?.required" class="error-msg">Evaluation is Required</span></mat-hint>
                </mat-form-field>
           </div>
         </div>

          <div class="form-group row" [ngClass]="{'has-error': (errtitleName.touched || errtitleName.dirty) && errtitleName.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="department_name">Title <sup class="red">*</sup></label>
            <div class="col-sm-5">
              <mat-form-field class="example-full-width" appearance="outline">
                 <mat-label>Title</mat-label>
                 <input matInput placeholder="Title" formControlName="titleName" >
                 <mat-hint *ngIf="(errtitleName.touched || errtitleName.dirty) && errtitleName.invalid"><span *ngIf="errtitleName.errors?.required" class="error-msg">Title is Required</span></mat-hint>
               </mat-form-field>
            </div>
         </div>
          
          <div class="form-group row">
              <div class="col-md-7 text-right">
                <input type="hidden" *ngIf="hiddenId" value="" formControlName="titleId" />
                <button type="button" [disabled]="!titleForm.valid"  (click)="submitTitle(pageAct)"  mat-raised-button color="primary">Save</button>
            </div>
          </div>
       </div>
    </div>
 </div>
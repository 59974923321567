import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DayendnoteComponent } from '../dayendnote/dayendnote.component';
import { NoteComponent } from '../note/note.component';
import { JuryEvaluationService } from '../services/JuryEvaluation/jury-evaluation.service';
import { MessageService } from '../services/JuryEvaluation/message.service';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'app-resultsummary',
  templateUrl: './resultsummary.component.html',
  styleUrls: ['./resultsummary.component.css']
})
export class ResultsummaryComponent implements OnInit {

  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  positionAfter = this.positionOptions[0];
  positionBefore = this.positionOptions[1];
  showSpinner: boolean = true;
  juryScheduleID: any;
 
  constructor(private router: Router, public dialogRef: MatDialogRef<ResultsummaryComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private snackBar: MatSnackBar, private messageData: MessageService, private evaluationapi: JuryEvaluationService, private matDialog: MatDialog) { }

  public sessionDisable: boolean = false;

  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.juryScheduleID = window.localStorage.getItem('jsch_j_id');

    setTimeout(() => {
      this.showSpinner = false;
    }, 1500);
  }

  SessionEnd(sessiontxt) {
    this.messageData.sendMessage(true);

    this.messageData.mszSession$.subscribe(
      message => {
        this.sessionDisable = message;
      }
    );
   this.router.navigate(['/JuryEvaluation']);
   let snackBarRef = this.snackBar.openFromComponent(NoteComponent, { horizontalPosition: 'center', verticalPosition: 'top', panelClass: ['noteSnackBar'] });
  }

  DayEnd() {
    this.messageData.sendMessage(true);

    this.messageData.mszSession$.subscribe(
      message => {
        this.sessionDisable = message;
      }
    );
   this.router.navigate(['/JuryEvaluation']);
   let snackBarRef = this.snackBar.openFromComponent(DayendnoteComponent, { horizontalPosition: 'center', verticalPosition: 'top', panelClass: ['noteSnackBar'] });

    // const formData = new FormData();
    // formData.append('jury_schedule_id', this.juryScheduleID);

    // this.evaluationapi.submitDay(formData).subscribe((data) => {
    //   this.matDialog.closeAll();
    //   let snackBarRef = this.snackBar.open('Your Day evaluation has been submitted', '', { horizontalPosition: 'center', verticalPosition: 'top', duration: 3000 });
    // });
  }

}

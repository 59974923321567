import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { EvaluationService } from '../services/evaluation/evaluation.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.css']
})
export class EvaluationComponent implements OnInit {

  imgSrcLogo: string = "../../assets/images/download.png";
  imgSrcGuidline: string = "../../assets/images/download.png";
  selectedImage: any = null;
  message: any;
  pageAct: string = "Create";
  hiddenId: boolean = false;
  evaluationForm: FormGroup;
  selectedFileNameLogo: any = "";
  selectedFileNameGuidline: any = "";

  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private evaluationapi: EvaluationService, private toastr: ToastrService) { }

  token: any;
  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.token = window.localStorage.getItem('token');
    if(!this.token){
      this.router.navigate(['']);
    }
    
    if(this.route.snapshot.paramMap.get('id'))
    {
      
      let id = parseInt(this.route.snapshot.paramMap.get('id'));
      this.pageAct = "Update";
      this.hiddenId = true;
      this.evaluationapi.getEvaluation(id).subscribe((data: any) => {
        this.evaluationForm.patchValue(data);
        this.selectedFileNameLogo = data.evaluationLogo;
        this.selectedFileNameGuidline = data.evaluationGuidline;
        this.imgSrcLogo = "../../assets/images/evolution_logo/"+data.evaluationLogo;
        //this.imgSrcGuidline = "../../assets/images/"+data.evaluationGuidline;
      })

      this.evaluationForm = this.fb.group({
        evaluationId: [''],
        evaluationName: ['', [Validators.required]],
        evaluationCode: ['', [Validators.required]],
        evaluationLogo: ['', [Validators.required]],
        evaluationType: ['', [Validators.required]],
        evaluationGuidline: ['', [Validators.required]]
      });
      
    } else {
      this.pageAct = "Create";
      this.hiddenId = false;

      this.evaluationForm = this.fb.group({
        evaluationName: ['', [Validators.required]],
        evaluationCode: ['', [Validators.required]],
        evaluationLogo: ['', [Validators.required]],
        evaluationType: ['', [Validators.required]],
        evaluationGuidline: ['', [Validators.required]]
      });
    }
  }
  
  get evaluationId() { return this.evaluationForm.get('evaluationId'); }
  get evaluationName() { return this.evaluationForm.get('evaluationName'); }
  get evaluationCode() { return this.evaluationForm.get('evaluationCode'); }
  get evaluationLogo() { return this.evaluationForm.get('evaluationLogo'); }
  get evaluationType() { return this.evaluationForm.get('evaluationType'); }
  get evaluationGuidline() { return this.evaluationForm.get('evaluationGuidline'); }


  onSelectLogo(event) {
    const selectedLogo = event.target.files[0];
    this.selectedFileNameLogo = selectedLogo.name;
    this.evaluationForm.get('evaluationLogo').setValue(selectedLogo);
    if(event.target.files && event.target.files[0]){
      const reader = new FileReader();
      reader.onload = (e:any) => this.imgSrcLogo = e.target.result;
      reader.readAsDataURL(event.target.files[0]);
      this.selectedImage = event.target.files[0];
    } else {
      this.imgSrcLogo = "../../assets/images/download.png";
      this.selectedImage = null;
    }
  }
  onSelectGuidline(event) {
    const selectedGuidline = event.target.files[0];
    this.selectedFileNameGuidline = selectedGuidline.name;
    this.evaluationForm.get('evaluationGuidline').setValue(selectedGuidline);
    if(event.target.files && event.target.files[0]){
      // const reader = new FileReader();
      // reader.onload = (e:any) => this.imgSrcGuidline = e.target.result;
      // reader.readAsDataURL(event.target.files[0]);
      // this.selectedImage = event.target.files[0];
    } else {
      // this.imgSrcGuidline = "../../assets/images/download.png";
      // this.selectedImage = null;
    }
  }

  submitEvaluation(act){
    if(this.evaluationForm.invalid)
    {
      return;
    }
    if(act == "Create") {
      const formData = new FormData();
      formData.append('evaluationName', this.evaluationForm.get('evaluationName').value);
      formData.append('evaluationCode', this.evaluationForm.get('evaluationCode').value);
      formData.append('evaluationLogo', this.evaluationForm.get('evaluationLogo').value);
      formData.append('evaluationType', this.evaluationForm.get('evaluationType').value);
      formData.append('evaluationGuidline', this.evaluationForm.get('evaluationGuidline').value);

      this.evaluationapi.addEvaluation(formData)
      .subscribe(data => {
        //this.message = data.message;
        this.router.navigate(['/Evaluation_List']);
      })
    }
    if(act == "Update") {
      const formData = new FormData();
      formData.append('evaluationId', this.evaluationForm.get('evaluationId').value);
      formData.append('evaluationName', this.evaluationForm.get('evaluationName').value);
      formData.append('evaluationCode', this.evaluationForm.get('evaluationCode').value);
      formData.append('evaluationLogo', this.evaluationForm.get('evaluationLogo').value);
      formData.append('evaluationType', this.evaluationForm.get('evaluationType').value);
      formData.append('evaluationGuidline', this.evaluationForm.get('evaluationGuidline').value);

      this.evaluationapi.updateEvaluation(formData)
      .subscribe(data => {
        this.router.navigate(['/Evaluation_List']);
        this.toastr.success('Evaluation Added Successfully');
      })
      this.evaluationapi.updateEvaluation(this.evaluationForm.value).subscribe(() => {
        this.router.navigate(['/Evaluation_List']);
        this.toastr.success('Evaluation Updated Successfully');
      })
    }
  }

}

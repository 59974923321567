import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { IPlant } from '../plant/plant';
import { PlantlistService } from '../services/plant/plantlist.service';
import { JuryService } from '../services/jury/jury.service';
import { ToastrService } from 'ngx-toastr';
import { EvaluationlistService } from '../services/evaluation/evaluationlist.service';
import { IEvaluation } from '../evaluation/evaluation';
import { IJury } from './jury';

@Component({
  selector: 'app-jury',
  templateUrl: './jury.component.html',
  styleUrls: ['./jury.component.css']
})
export class JuryComponent implements OnInit {

  jurySelected: any;
  external: boolean = false;
  internal: boolean = false;
  message: any;
  pageAct: string = "Create";
  hiddenId: boolean = false;
  juryForm: FormGroup;
  plant: IPlant[];
  _plant: IPlant[];
  evaluation: IEvaluation[];
  _evaluation: IEvaluation[];
  nodata: boolean = false;
  plantselected: number = 0;
  constructor(private fb: FormBuilder, 
              private router: Router, 
              private route: ActivatedRoute, 
              private juryapi: JuryService, 
              private evaluationservice: EvaluationlistService, 
              private plantservice: PlantlistService, 
              private toastr: ToastrService) { }

  token: any;

  @ViewChild('evaluationSearch') evaluationSearchInput: ElementRef;
  @ViewChild('plantSearch') plantSearchInput: ElementRef;

  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.token = window.localStorage.getItem('token');
    if(!this.token){
      this.router.navigate(['']);
    }

    this.evaluationservice.evaluationData().subscribe((data: IEvaluation[]) => {
      this.evaluation = data;
      this._evaluation = data;
    });

    this.plantservice.plantData().subscribe((data: IPlant[]) => {
      this.plant = data;
      this._plant = data;
    }, (error) => {
      this.plant = [];
      this._plant = [];
    });

    if(this.route.snapshot.paramMap.get('id'))
    {
      
      let id = parseInt(this.route.snapshot.paramMap.get('id'));
      this.pageAct = "Update";
      this.hiddenId = true;

      //this.jurySelected = ["2","3"];
      this.juryapi.getJury(id).subscribe((data: any) => {
        console.log(data.juryExpertise);
        this.juryForm.patchValue(data);
      })

      this.juryForm = this.fb.group({
        juryId: [''],
        juryName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
        juryMobile: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
        juryType: ['', Validators.required],
        companyId: ['', Validators.required],
        plantId: ['', Validators.required],
        juryEmployeeCode: ['', Validators.required],
        juryOrganization: ['', Validators.required],
        juryPlace: ['', Validators.required],
        juryExpertise: ['', Validators.required]
      });

      this.juryForm.get('juryType').valueChanges.subscribe(changeValue => {
        const plantId = this.juryForm.get('plantId');
        const juryEmployeeCode = this.juryForm.get('juryEmployeeCode');
        const juryOrganization = this.juryForm.get('juryOrganization');
        const juryPlace = this.juryForm.get('juryPlace');
        if(changeValue == "Internal")
        {
          plantId.setValidators(Validators.required);
          juryEmployeeCode.setValidators(Validators.required);

          juryOrganization.clearValidators();
          juryPlace.clearValidators();
        } else if(changeValue == "External") {
          plantId.clearValidators();
          juryEmployeeCode.clearValidators();

          juryOrganization.setValidators(Validators.required);
          juryPlace.setValidators(Validators.required);
        }
        plantId.updateValueAndValidity();
        juryEmployeeCode.updateValueAndValidity();
        juryOrganization.updateValueAndValidity();
        juryPlace.updateValueAndValidity();
      })
      
    } else {
      this.pageAct = "Create";
      this.hiddenId = false;

      this.juryForm = this.fb.group({
        juryName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
        juryMobile: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
        juryType: ['', Validators.required],
        plantId: ['', Validators.required],
        juryEmployeeCode: ['', Validators.required],
        juryOrganization: ['', Validators.required],
        juryPlace: ['', Validators.required],
        juryExpertise: ['', Validators.required]
      });

      this.juryForm.get('juryType').valueChanges.subscribe(changeValue => {
        const companyId = this.juryForm.get('companyId');
        const plantId = this.juryForm.get('plantId');
        const juryEmployeeCode = this.juryForm.get('juryEmployeeCode');
        const juryOrganization = this.juryForm.get('juryOrganization');
        const juryPlace = this.juryForm.get('juryPlace');
        if(changeValue == "Internal")
        {
          plantId.setValidators(Validators.required);
          juryEmployeeCode.setValidators(Validators.required);

          juryOrganization.clearValidators();
          juryPlace.clearValidators();
        } else if(changeValue == "External") {
          plantId.clearValidators();
          juryEmployeeCode.clearValidators();

          juryOrganization.setValidators(Validators.required);
          juryPlace.setValidators(Validators.required);
        }
        plantId.updateValueAndValidity();
        juryEmployeeCode.updateValueAndValidity();
        juryOrganization.updateValueAndValidity();
        juryPlace.updateValueAndValidity();
      })

    }

  }

  get errjuryName() { return this.juryForm.get('juryName'); }
  get errjuryMobile() { return this.juryForm.get('juryMobile'); }
  get errjuryType() { return this.juryForm.get('juryType'); }
  get errplantId() { return this.juryForm.get('plantId'); }
  get errjuryEmployeeCode() { return this.juryForm.get('juryEmployeeCode'); }
  get errjuryOrganization() { return this.juryForm.get('juryOrganization'); }
  get errjuryPlace() { return this.juryForm.get('juryPlace'); }
  get errjuryExpertise() { return this.juryForm.get('juryExpertise'); }

  onfocusEvaluation() {
    this.evaluationSearchInput.nativeElement.focus();
  }
  onInputChangeEvaluation() {
    const searchInput = this.evaluationSearchInput.nativeElement.value ? this.evaluationSearchInput.nativeElement.value.toLowerCase() : '';
    this.evaluation = this._evaluation.filter(c_data => {
      const c_name: any = c_data.evaluationName.toLowerCase();
      return c_name.indexOf(searchInput) > -1;
    })
  }

  onfocusPlant() {
    this.plantSearchInput.nativeElement.focus();
  }
  onInputChangePlant() {
    const searchInput = this.plantSearchInput.nativeElement.value ? this.plantSearchInput.nativeElement.value.toLowerCase() : '';
    this.plant = this._plant.filter(c_data => {
      const c_name: any = c_data.plantName.toLowerCase();
      return c_name.indexOf(searchInput) > -1;
    })
  }

  getJAType(jaVal){
   if(jaVal == "External")
    {
      this.external = true;
      this.internal = false;
    } else if(jaVal == "Internal")
    {
      this.internal = true;
      this.external = false;
    }
  }

  submitJury(act){
    if(this.juryForm.invalid)
    {
      return;
    }
    if(act == "Create") {
      this.juryapi.addJury(this.juryForm.value)
      .subscribe(data => {
        this.router.navigate(['/Jury_List']);
        this.toastr.success('Jury / Auditor Added Successfully');
      })
    }
    if(act == "Update") {
      this.juryapi.updateJury(this.juryForm.value).subscribe(() => {
        this.router.navigate(['/Jury_List']);
        this.toastr.success('Jury / Auditor Updated Successfully');
      })
    }
  }

}

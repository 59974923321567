<div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between">
      <div class="iq-header-title">
        <h4 class="card-title">Current Schedules</h4>
      </div>
      
    </div>
    <div class="iq-card-body company-list">
      <div class="form-group row">
        <div class="col-sm-12 col-lg-12">
  
            <mat-accordion>
                <mat-expansion-panel hideToggle>
                  <!-- <mat-expansion-panel-header>
                    <mat-panel-title>
                      This is the expansion title
                    </mat-panel-title>
                    <mat-panel-description>
                      This is a summary of the content
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <p>This is the primary content of the panel.</p> -->
                </mat-expansion-panel>




                <mat-expansion-panel (opened)="panelOpenState = true"
                                     (closed)="panelOpenState = false" *ngFor="let jSch of juryschedules; let ci = index">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="event-details headtit">
                      <div class="col-md-3">
                        <label> Company :</label><br> <span>{{jSch.company}}</span> 
                      </div>
                      <div class="col-md-2">
                          <label> Plant/Department :</label><br> <span>{{jSch.plant}} / {{jSch.department}}</span> 
                      </div>
                      <div class="col-md-3">
                          <label>Event Code :</label><br> <span>{{jSch.eventCode}}</span> 
                      </div>
                      <div class="col-md-2">
                        <label> Event Date :</label><br> <span>{{jSch.eventDate}}</span> 
                      </div>
                    
                    <div class="col-md-2">
                        <label> Event Status :</label><br> <span>{{jSch.status}}</span> 
                    </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="session-popup">
                    <table class="table">
                        <tr>
                            <td width="20%"><label>Evaluation</label></td>
                            <td width="5%">:</td>
                            <td width="20%">{{jSch.evaluation}}</td>
                        </tr>
                        <tr>
                            <td><label>Company</label></td>
                            <td>:</td>
                            <td>{{jSch.company}}</td>
                        </tr>
                        <tr>
                            <td><label>Business</label></td>
                            <td>:</td>
                            <td>{{jSch.businessUnit}}</td>
                        </tr>
                        <tr>
                            <td><label>Plant/Department</label></td>
                            <td>:</td>
                            <td>{{jSch.plant}} / {{jSch.department}}</td>
                        </tr>
                        <tr>
                            <td><label>Date</label></td>
                            <td>:</td>
                            <td>{{jSch.eventDate}}</td>
                        </tr>
                        <tr>
                            <td><label>Event Code</label></td>
                            <td>:</td>
                            <td>{{jSch.eventCode}}</td>
                        </tr>
                        <tr>
                            <td><label>Evaluation Type</label></td>
                            <td>:</td>
                            <td>{{jSch.evaluationType}}</td>
                        </tr>
                    </table>
                    <div class="row threepop" *ngIf="jSch.sh_cou">
                        <div class="col-md-4 assigned"><h4>Assigned - {{jSch.totalEvolution}}</h4></div>
                        <div class="col-md-4 completed"><h4>Completed - {{jSch.completedEvolution}}</h4></div>
                        <div class="col-md-4 pending"><h4>Pending - {{jSch.pendingEvolution}}</h4></div>
                    </div>
                      
                </div>
                </mat-expansion-panel>








              </mat-accordion>
  
  
        </div>
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CompanyListComponent } from './company-list/company-list.component';
import { FooterComponent } from './footer/footer.component';
import { MenuComponent } from './menu/menu.component';
import { HeaderComponent } from './header/header.component';
import { BreadcrumsComponent } from './breadcrums/breadcrums.component';
import { LoginComponent } from './login/login.component';
import { CompanyComponent } from './company/company.component';
import { BusinessUnitComponent } from './business-unit/business-unit.component';
import { BusinessUnitListComponent } from './business-unit-list/business-unit-list.component';
import { PlantComponent } from './plant/plant.component';
import { PlantlistComponent } from './plantlist/plantlist.component';
import { DepartmentComponent } from './department/department.component';
import { DepartmentlistComponent } from './departmentlist/departmentlist.component';
import { EvaluationComponent } from './evaluation/evaluation.component';
import { EvaluationlistComponent } from './evaluationlist/evaluationlist.component';
import { JuryComponent } from './jury/jury.component';
import { JurylistComponent } from './jurylist/jurylist.component';
import { QuestionComponent } from './question/question.component';
import { QuestionlistComponent } from './questionlist/questionlist.component';
import { EventComponent } from './event/event.component';
import { EventlistComponent } from './eventlist/eventlist.component';
import { TitleComponent } from './title/title.component';
import { TitlelistComponent } from './titlelist/titlelist.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { SchedulelistComponent } from './schedulelist/schedulelist.component';
import { ScheduleviewComponent } from './scheduleview/scheduleview.component';
import { EmployeeComponent } from './employee/employee.component';
import { TeamComponent } from './team/team.component';

import { JuryloginComponent } from './jurylogin/jurylogin.component';
import { JurydashboardComponent } from './jurydashboard/jurydashboard.component';
import { JuryscheduleComponent } from './juryschedule/juryschedule.component';
import { ResultsummaryComponent } from './resultsummary/resultsummary.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { EvaluationsresultsComponent } from './evaluationsresults/evaluationsresults.component';
import { JuryhelpComponent } from './juryhelp/juryhelp.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { JuryevaluationComponent } from './juryevaluation/juryevaluation.component';
import { HelpComponent } from './help/help.component';
import { RequestComponent } from './request/request.component';
import { JurypasswordListComponent } from './jurypassword-list/jurypassword-list.component';
import { NoteComponent } from './note/note.component';
import { SessiondetailComponent } from './sessiondetail/sessiondetail.component';
import { EditjuryevaluationComponent } from './editjuryevaluation/editjuryevaluation.component';
import { DayendnoteComponent } from './dayendnote/dayendnote.component';
import { SmsmailComponent } from './smsmail/smsmail.component';
import { AnalyticsComponent } from './Reports/analytics/analytics.component';
import { AnalyticgradeComponent } from './Reports/analyticgrade/analyticgrade.component';
import { SchedulestatusComponent } from './Reports/schedulestatus/schedulestatus.component';
import { StatusComponent } from './Reports/status/status.component';
import { EventcodeComponent } from './Reports/eventcode/eventcode.component';
import { EmployeeteamComponent } from './Reports/employeeteam/employeeteam.component';
import { PlantevaluationComponent } from './Reports/plantevaluation/plantevaluation.component';
import { PlantcodeevaluationComponent } from './Reports/plantcodeevaluation/plantcodeevaluation.component';
import { PlantaverageComponent } from './Reports/plantaverage/plantaverage.component';
import { PlantyearlyaverageComponent } from './Reports/plantyearlyaverage/plantyearlyaverage.component';
import { ProfileComponent } from './profile/profile.component';
import { FeedbackcommentComponent } from './feedbackcomment/feedbackcomment.component';
import { FeedbackreportComponent } from './Reports/feedbackreport/feedbackreport.component';

//import { AuthguardService } from './services/authguard.service';


const routes: Routes = [
  { path : 'Company', component: CompanyComponent }, 
  { path : 'Company/:id', component: CompanyComponent }, 
  { path : 'Company_List', component: CompanyListComponent },
  { path : 'Business_Unit', component: BusinessUnitComponent }, 
  { path : 'Business_Unit/:id', component: BusinessUnitComponent }, 
  { path : 'Business_Unit_List', component: BusinessUnitListComponent }, 
  { path : 'Plant', component: PlantComponent }, 
  { path : 'Plant/:id', component: PlantComponent }, 
  { path : 'Plant_List', component: PlantlistComponent }, 
  { path : 'Department', component: DepartmentComponent }, 
  { path : 'Department/:id', component: DepartmentComponent }, 
  { path : 'Department_List', component: DepartmentlistComponent },
  { path : 'Evaluation', component: EvaluationComponent }, 
  { path : 'Evaluation/:id', component: EvaluationComponent }, 
  { path : 'Evaluation_List', component: EvaluationlistComponent }, 
  { path : 'Title', component: TitleComponent }, 
  { path : 'Title/:id', component: TitleComponent }, 
  { path : 'Title_List', component: TitlelistComponent }, 
  { path : 'Jury', component: JuryComponent }, 
  { path : 'Jury/:id', component: JuryComponent }, 
  { path : 'Jury_List', component: JurylistComponent }, 
  { path : 'Question', component: QuestionComponent }, 
  { path : 'Question/:id', component: QuestionComponent }, 
  { path : 'Question_List', component: QuestionlistComponent }, 
  { path : 'Event', component: EventComponent }, 
  { path : 'Event/:id', component: EventComponent }, 
  { path : 'Event_List', component: EventlistComponent },
  { path : 'Schedule', component: ScheduleComponent }, 
  { path : 'ScheduleView/:id', component: ScheduleviewComponent }, 
  { path : 'Schedule/:id', component: ScheduleComponent }, 
  { path : 'Schedule_List', component: SchedulelistComponent }, 
  { path : 'Employee/:id', component: EmployeeComponent },

  { path : 'Feedback/:id', component: FeedbackreportComponent },

  { path : 'Employee/:empid/:id', component: EmployeeComponent },
  { path : 'Team/:id', component: TeamComponent },
  { path : 'Team/:actid/:id', component: TeamComponent },
  { path : 'JuryPassword/:id', component: JurypasswordListComponent },
  { path : 'Login', component: LoginComponent },
  { path : 'Dashboard', component: DashboardComponent },
  { path : 'Help', component: HelpComponent },
  { path : 'Request', component: RequestComponent },
  { path : 'JuryLogin', component: JuryloginComponent },
  { path : 'JuryDashboard', component: JurydashboardComponent },
  { path : 'JurySchedule', component: JuryscheduleComponent },
  { path : 'JuryEvaluation', component: JuryevaluationComponent },
  { path : 'EvaluationResults', component: EvaluationsresultsComponent },
  { path : 'JuryHelp', component: JuryhelpComponent },
  { path : 'EditEvaluation/:id', component: EditjuryevaluationComponent },
  { path : 'Reports/AnalyticsReport', component: AnalyticsComponent },
  { path : 'Reports/AnalyticsGradeReport', component: AnalyticgradeComponent },
  { path : 'Reports/ScheduleStatusReport', component: SchedulestatusComponent },
  { path : 'Reports/StatusReport', component: StatusComponent },
  { path : 'Reports/EventCodeReport', component: EventcodeComponent },
  { path : 'Reports/EmployeeTeamReport', component: EmployeeteamComponent },
  { path : 'Reports/PlantEvaluationReport', component: PlantevaluationComponent },
  { path : 'Reports/PlantCodeEvaluationReport', component: PlantcodeevaluationComponent },
  { path : 'Reports/PlantAverageReport', component: PlantaverageComponent },
  { path : 'Reports/PlantYearlyAverageReport', component: PlantyearlyaverageComponent },
  { path : 'Profile/:id', component: ProfileComponent },
  { path : '', component: LoginComponent }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [CompanyListComponent, CompanyComponent, BusinessUnitComponent, BusinessUnitListComponent, PlantComponent, PlantlistComponent, DepartmentComponent, DepartmentlistComponent, EvaluationComponent, EvaluationlistComponent, TitleComponent, TitlelistComponent, JuryComponent, JurylistComponent, QuestionComponent, QuestionlistComponent, EventComponent, EventlistComponent, ScheduleComponent, SchedulelistComponent, ScheduleviewComponent, EmployeeComponent, TeamComponent, FooterComponent, MenuComponent, HeaderComponent, BreadcrumsComponent, LoginComponent, JuryloginComponent, JurydashboardComponent, JuryscheduleComponent, JuryevaluationComponent, ResultsummaryComponent, FeedbackComponent, FeedbackcommentComponent, FeedbackreportComponent, SmsmailComponent, EvaluationsresultsComponent, JuryhelpComponent, DashboardComponent, HelpComponent, RequestComponent, JurypasswordListComponent, NoteComponent, SessiondetailComponent, EditjuryevaluationComponent, DayendnoteComponent, AnalyticsComponent, AnalyticgradeComponent, SchedulestatusComponent, StatusComponent, EventcodeComponent, EmployeeteamComponent, PlantevaluationComponent, PlantcodeevaluationComponent, PlantaverageComponent, PlantyearlyaverageComponent, ProfileComponent]

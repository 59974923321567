<div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between">
       <div class="iq-header-title">
          <h4 class="card-title">Event List</h4>
       </div>
       <!-- <div class="pull-right add">
          <a routerLink="/Jury" class="btn btn-primary custom-btn">Event List</a>
       </div> -->
    </div>
    <div class="iq-card-body company-list">
    <div class="form-group row">
        <div class="col-sm-12 col-lg-12">

            <div class="col-sm-12 col-lg-12">
                <div class="mat-elevation-z8">
                    <mat-form-field appearance="outline" class="filter-dt">
                        <mat-label>Filter Data</mat-label>
                        <input (keyup)="applyFilter($event.target.value)" matInput placeholder="Filter Data">
                        <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
                        <!-- <mat-hint>Hint</mat-hint> -->
                      </mat-form-field>
    
                    <table mat-table matSort [dataSource]="dataSource">
                  
                      <!-- Position Column -->
                      <ng-container matColumnDef="sno">
                        <th mat-header-cell *matHeaderCellDef> # </th>
                        <td mat-cell *matCellDef="let element; let i = index;"> {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (i + 1) }}. </td>
                      </ng-container>
                  
                      <!-- Name Column -->
                      <ng-container matColumnDef="evaluationId">
                        <th mat-header-cell *matHeaderCellDef> Evaluation </th>
                        <td mat-cell *matCellDef="let element"> {{element.evaluationId}} </td>
                      </ng-container>
                  
                      <!-- Weight Column -->
                      <ng-container matColumnDef="eventCompetition">
                        <th mat-header-cell *matHeaderCellDef> Competition Type </th>
                        <td mat-cell *matCellDef="let element"> {{element.eventCompetition}} </td>
                      </ng-container>
                  
                      <!-- Symbol Column -->
                      <ng-container matColumnDef="eventDate">
                        <th mat-header-cell *matHeaderCellDef> Date </th>
                        <td mat-cell *matCellDef="let element"> {{element.eventDate | date :'dd-MM-yyyy'}} </td>
                      </ng-container>
    
                      <!-- Symbol Column -->
                      <ng-container matColumnDef="eventCode">
                        <th mat-header-cell *matHeaderCellDef> Event Code </th>
                        <td mat-cell *matCellDef="let element"> {{element.eventCode}} </td>
                      </ng-container>
    
                      <!-- Symbol Column -->
                      <ng-container matColumnDef="eventevaluationtype">
                        <th mat-header-cell *matHeaderCellDef> Evaluation Type </th>
                        <td mat-cell *matCellDef="let element"> {{element.eventevaluationtype}} </td>
                      </ng-container>
    
                      <ng-container matColumnDef="eventId">
                        <th mat-header-cell *matHeaderCellDef>Actions </th>
                        <td mat-cell *matCellDef="let element">
                            <span class="material-icons icon-dt" title="Edit" routerLink="/Event/{{ element.eventId }}">
                              edit
                            </span>&nbsp;&nbsp;
                            <span class="material-icons text-danger icon-dt" title="Delete" (click)=delete(element)>
                              delete_outline
                            </span>
                        </td>
                      </ng-container>
                      
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
    
                    <div *ngIf="dataSource.data.length === 0">
                        No Records Found!
                    </div>
                  
                    <mat-paginator #paginatorRef [pageSize]="50" [pageSizeOptions]="[50, 100, 150]" showFirstLastButtons></mat-paginator>
                  </div>




        <!-- <table class="table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th class="text-left">Evaluation</th>
                        <th class="text-center">Competition Type</th>
                        <th class="text-center">Date</th>
                        <th class="text-center">Event Code</th>
                        <th class="text-center">Evaluation Type</th>
                        <th class="text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                <tr *ngFor="let event of events; let ev = index">
                    <td>{{ ev+1 }}.</td>
                    <td class="text-left">{{ event.evaluationId }}</td>
                    <td class="text-center">{{ event.eventCompetition }}</td>
                    <td class="text-center">{{ event.eventDate | date :'dd-MM-yyyy' }}</td>
                    <td class="text-center">{{ event.eventCode }}</td>
                    <td class="text-center">{{ event.eventevaluationtype }}</td>
                    <td class="text-center">
                        <div class="custom-control custom-switch custom-switch-color custom-control-inline">
          <input type="checkbox" class="custom-control-input bg-info" id="customSwitch06" checked="">
          <label class="custom-control-label" for="customSwitch06"></label>
          <a routerLink="/Event/{{ event.eventId }}" title="{{ 'Edit '+event.evaluationId }}" class="edit"><i class="lar la-edit"></i></a>
          <a (click)=delete(event) class="delete" title="{{ 'Delete '+event.evaluationId }}"><i class="lar la-trash-alt"></i></a>
       </div>
                    </td>
                </tr>
                </tbody>
            </table> -->
        </div>
    </div>
                                  
    </div>
 </div>
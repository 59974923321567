<div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between">
       <div class="iq-header-title">
          <h4 class="card-title">Schedule List</h4>
       </div>
       <!-- <div class="pull-right add">
          <a routerLink="/Schedule" class="btn btn-primary custom-btn">Add New Schedule</a>
       </div> -->
    </div>
    <div class="iq-card-body company-list">
    <div class="form-group row">
        <div class="col-sm-12 col-lg-12">
            <div class="mat-elevation-z8">
                <mat-form-field appearance="outline" class="filter-dt">
                    <mat-label>Filter Data</mat-label>
                    <input (keyup)="applyFilter($event.target.value)" matInput placeholder="Filter Data">
                    <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
                    <!-- <mat-hint>Hint</mat-hint> -->
                  </mat-form-field>

                <table mat-table matSort [dataSource]="dataSource">
              
                  <!-- Position Column -->
                  <ng-container matColumnDef="sno">
                    <th mat-header-cell *matHeaderCellDef> # </th>
                    <td mat-cell *matCellDef="let element; let i = index;"> {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (i + 1) }}. </td>
                  </ng-container>
              
                  <!-- Symbol Column -->
                  <ng-container matColumnDef="juryName">
                    <th mat-header-cell *matHeaderCellDef> Jury </th>
                    <td mat-cell *matCellDef="let element"> {{element.juryName}} </td>
                  </ng-container>

                  <!-- Symbol Column -->
                  <ng-container matColumnDef="juryMobile">
                    <th mat-header-cell *matHeaderCellDef> Mobile </th>
                    <td mat-cell *matCellDef="let element"> {{element.juryMobile}} </td>
                  </ng-container>

                  <!-- Name Column -->
                  <ng-container matColumnDef="username">
                    <th mat-header-cell *matHeaderCellDef> Username </th>
                    <td mat-cell *matCellDef="let element"> {{element.username}} </td>
                  </ng-container>
              
                  <!-- Weight Column -->
                  <ng-container matColumnDef="password">
                    <th mat-header-cell *matHeaderCellDef> Password </th>
                    <td mat-cell *matCellDef="let element"> {{element.password}} </td>
                  </ng-container>

                  <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>Actions </th>
                    <td mat-cell *matCellDef="let element">
                      <span *ngIf="!element.session_end">
                        <button mat-stroked-button color="warn" mwlConfirmationPopover [popoverTitle]="popoverTitle" [popoverMessage]="popoverMessage" placement="left" (confirm)="sessionDetail(element.scheduleJuryId)" (cancel)="cancelClicked=true">Session End</button>
                      </span>&nbsp;&nbsp;
                      <span *ngIf="!element.session_end">
                        <button mat-stroked-button color="warn" (click)="smsmail(element.scheduleJuryId);">SMS/Mail</button>
                      </span>
                      <span *ngIf="element.session_end">
                        Session Ended
                      </span>
                    </td>
                  </ng-container>
                  
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <div *ngIf="dataSource.data.length === 0">
                    No Records Found!
                </div>
              
                <mat-paginator #paginatorRef [pageSize]="50" [pageSizeOptions]="[50, 100, 150]" showFirstLastButtons></mat-paginator>
              </div>

            <!-- <table class="table">
                <tr>
                    <th>#</th>
                    <th class="text-left">Evaluation</th>
                    <th class="text-left">Plant</th>
                    <th class="text-left">Department</th>
                    <th class="text-left">Event Code</th>
                    <th class="text-center">Evaluation Type</th>
                    <th class="text-center">Status</th>
                </tr>
                <tr *ngFor="let schedule of schedules; let sch = index">
                    <td>{{ sch+1 }}.</td>
                    <td class="text-left">{{ schedule.evaluationId }}</td>
                    <td class="text-left">{{ schedule.plantId }}</td>
                    <td class="text-left">{{ schedule.departmentId }}</td>
                    <td class="text-left">{{ schedule.eventCode }}</td>
                    <td class="text-center">{{ schedule.evaluationType }}</td>
                    <td class="text-center">
                        <div class="custom-control custom-switch custom-switch-color custom-control-inline">
                            <input type="checkbox" class="custom-control-input bg-info" id="customSwitch06" checked="">
                            <label class="custom-control-label" for="customSwitch06"></label>
                            <a routerLink="/ScheduleView/{{ schedule.scheduleId }}" title="View Schedule" class="edit"><i class="lar la-eye"></i></a>
                            <a routerLink="/Schedule/{{ schedule.scheduleId }}" title="Edit Schedule" class="edit"><i class="lar la-edit"></i></a>
                            <a (click)=delete(schedule) class="delete" title="Delete Schedule"><i class="lar la-trash-alt"></i></a>
                        </div>
                    </td>
                </tr>
            </table> -->
        </div>
    </div>
                                  
    </div>
 </div>
import { Component, OnInit } from '@angular/core';
import { HeadermessageService } from '../services/dashboard/headermessage.service';

@Component({
  selector: 'app-juryhelp',
  templateUrl: './juryhelp.component.html',
  styleUrls: ['./juryhelp.component.css']
})
export class JuryhelpComponent implements OnInit {

  pageHead: string;

  constructor(private headerData: HeadermessageService) { }

  ngOnInit(): void {

    this.headerData.sendMessage('Help');
    this.headerData.mszHead$.subscribe(
      message => {
        this.pageHead = message;
      }
    );
  }

}

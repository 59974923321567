import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { FeedbackComponent } from '../feedback/feedback.component';
import { ResultsummaryComponent } from '../resultsummary/resultsummary.component';
import { JuryEvaluationService } from '../services/JuryEvaluation/jury-evaluation.service';
import { MessageService } from '../services/JuryEvaluation/message.service';
import { JurylogoutService } from '../services/jurylogout/jurylogout.service';
import { SearchService } from '../services/search/search.service';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.css']
})
export class NoteComponent implements OnInit {

  juryScheduleID: any;
  chkev: boolean = false;
  sessionTxt: any;
  evoId: any;

  constructor(private router: Router, private snackBar: MatSnackBar, private messageData: MessageService, private evaluationapi: JuryEvaluationService, private JurylogoutService: JurylogoutService, private matDialog: MatDialog, public dialog: MatDialog, private searchapi: SearchService) { }

  public sessionDisable: boolean;

  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.juryScheduleID = window.localStorage.getItem('jsch_j_id');

    this.searchapi.getevaluation(this.juryScheduleID).subscribe((evdata: any) => {
      this.evoId =  evdata.ev;
      if(this.evoId)
      {
        this.sessionTxt = 'Session End & End Schedule';
      } else {
        this.sessionTxt = 'Session End & View Results';
      }
    });
  }

  cancel() {
    this.messageData.sendMessage(false);

    this.messageData.mszSession$.subscribe(
      message => {
        this.sessionDisable = message;
      }
    );
    
    this.snackBar.dismiss();
  }

  proceed() {
    this.chkev = true;

    this.juryScheduleID = window.localStorage.getItem('jsch_j_id');
    const formData = new FormData();
    formData.append('jury_schedule_id', this.juryScheduleID);

    if(this.evoId) {
      formData.append('end_schedule', '1');
      this.evaluationapi.submitSession(formData).subscribe((data) => {
        let snackBarRef = this.snackBar.open('Session & Schedule ended successfully and please submit feedback.', '', { horizontalPosition: 'center', verticalPosition: 'top', duration : 3000 });
  
        setTimeout(() => {
          this.matDialog.closeAll();
          window.localStorage.removeItem('token');
          window.localStorage.removeItem('jtoken');
          if(window.localStorage.getItem('tokenact') == "admin")
          {
            this.router.navigate(['']);
          } else if(window.localStorage.getItem('tokenact') == "jury")
          {
            
            this.evaluationapi.getfeedback(formData).subscribe((data) => {
      
              const dialogConfig = new MatDialogConfig();
              dialogConfig.disableClose = true;
              dialogConfig.width = "80%";
              dialogConfig.height = "70%";
        
              dialogConfig.data = { juryName : data.juryName, juryMobile : data.juryMobile, audit : data.audit, session_end_date : data.session_end_date, title_name : data.titles, title_max : data.titles_max, title_award : data.titles_award };
        
              this.dialog.open(FeedbackComponent,dialogConfig);
              
            });

          }
        }, 3000);
      });
    } else {
      formData.append('end_schedule', '0');
      this.evaluationapi.submitSession(formData).subscribe((data) => {
        let snackBarRef = this.snackBar.open('Session ended successfully and redirecting to results.', '', { horizontalPosition: 'center', verticalPosition: 'top', duration : 3000 });
  
        setTimeout(() => {
          this.matDialog.closeAll();
          window.localStorage.removeItem('token');
          window.localStorage.removeItem('jtoken');
          if(window.localStorage.getItem('tokenact') == "admin")
          {
            this.router.navigate(['']);
          } else if(window.localStorage.getItem('tokenact') == "jury")
          {
            this.router.navigate(['/EvaluationResults']);
          }
        }, 3000);
      });
    }
    
    

    



  }

}

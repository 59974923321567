<div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between">
      <div class="iq-header-title">
        <h4 class="card-title pull-left">Dashboard</h4>
      </div>
      
    </div>
    <app-spinner *ngIf="showSpinner"></app-spinner>
    <div class="iq-card-body">
        <div class="row bd-front">
        <div class="col-md-4">
          <article>
            <img src="assets/images/icon-assigned.png">
              <h2 class="asgnd">Assigned - <strong>{{ totalSchedules }}</strong></h2>
          </article>      
        </div>

        <div class="col-md-4">
          <article>
            <img src="assets/images/icon-inprogress.png">
              <h2 class="inprgs">Pending - <strong>{{ pendingSchedules }}</strong></h2>
          </article>      
        </div>

        <div class="col-md-4">
          <article>
            <img src="assets/images/icon-completed.png">
              <h2 class="cmpltd">Completed - <strong>{{ completedSchedules }}</strong></h2>
          </article>      
        </div>
    </div>
</div>
    </div>
  <router-outlet></router-outlet>
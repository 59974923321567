<div class="iq-sidebar">
    <div class="iq-sidebar-logo d-flex justify-content-between">
       <a routerLink="/">
       <img src="assets/images/logo-amaron.png" class="img-fluid logos" alt="">
       <img src="assets/images/logo-icon.png" class="img-fluid icon-img" alt="">
       <!-- <span>QCFI<br><small>Gotta be a better way</small></span> -->
       </a>
       <div class="iq-menu-bt align-self-center">
          <div class="wrapper-menu">
             <div class="line-menu half start"></div>
             <div class="line-menu"></div>
             <div class="line-menu half end"></div>
          </div>
       </div>
    </div>
    <div id="sidebar-scrollbar">
       <nav class="iq-sidebar-menu">
          <ul id="iq-sidebar-toggle" class="iq-menu" *ngIf="isAdmin">
               <li><a routerLink="/Dashboard" class="iq-waves-effect"><i class="las la-home"></i><span>Dashboard</span></a></li>
             <li *ngIf="admin_access==0">
                <a href="#mailbox" class="iq-waves-effect" data-toggle="collapse" aria-expanded="false"><i class="las la-users-cog"></i><span>Masters</span> <i class="ri-arrow-right-s-line iq-arrow-right"></i></a>
                <ul id="mailbox" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                   <li class=""><a routerLink="/Company_List"><i class="las la-angle-right"></i> Company</a></li>
                   <li class=""><a routerLink="/Business_Unit_List"><i class="las la-angle-right"></i> Business Unit</a></li>
                   <li class=""><a routerLink="/Plant_List"><i class="las la-angle-right"></i> Plant</a></li>
                   <li class=""><a routerLink="/Department_List"><i class="las la-angle-right"></i> Department</a></li>
                   <li class=""><a routerLink="/Evaluation_List"><i class="las la-angle-right"></i> Evaluation</a></li>
                   <li class=""><a routerLink="/Title_List"><i class="las la-angle-right"></i> Title</a></li>
                   <li class=""><a routerLink="/Jury_List"><i class="las la-angle-right"></i> Jury / Auditor</a></li>
                   <li class=""><a routerLink="/Question_List"><i class="las la-angle-right"></i> Question</a></li>
                </ul>
             </li>
            
             <li *ngIf="admin_access==0">
               <a href="#event" class="iq-waves-effect" data-toggle="collapse" aria-expanded="false"><i class="lar la-calendar"></i><span>Events</span> <i class="ri-arrow-right-s-line iq-arrow-right"></i></a>
               <ul id="event" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                  <li class=""><a routerLink="/Event"><i class="las la-angle-right"></i> Create Event</a></li>
                  <li class=""><a routerLink="/Event_List"><i class="las la-angle-right"></i> Event List</a></li>
               </ul>
            </li>

             <li *ngIf="admin_access==0">
               <a href="#schedule" class="iq-waves-effect" data-toggle="collapse" aria-expanded="false"><i class="lar la-calendar"></i><span>Schedules</span> <i class="ri-arrow-right-s-line iq-arrow-right"></i></a>
               <ul id="schedule" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                  <li class=""><a routerLink="/Schedule"><i class="las la-angle-right"></i> Create Schedule</a></li>
                  <li class=""><a routerLink="/Schedule_List"><i class="las la-angle-right"></i> Schedule List</a></li>
               </ul>
            </li>
            <!-- <li><a href="javascript:void(0);" class="iq-waves-effect"><i class="lar la-address-book"></i><span>Assessments</span></a></li> -->
            <li *ngIf="admin_access==0"><a routerLink="/Request" class="iq-waves-effect"><i class="lar la-address-book"></i><span>Requests</span></a></li>
            
             <!-- <li>
                <a routerLink="#user-info" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><i class="las la-copy"></i><span>Assignments</span><i class="ri-arrow-right-s-line iq-arrow-right"></i></a>
             </li> -->

             <li>
               <a href="#reports" class="iq-waves-effect" data-toggle="collapse" aria-expanded="false"><i class="lar la-calendar"></i><span>Reports & Records</span> <i class="ri-arrow-right-s-line iq-arrow-right"></i></a>
               <ul id="reports" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                  <li class=""><a routerLink="/Reports/AnalyticsReport"><i class="las la-angle-right"></i> Analytics</a></li>
                  <li class=""><a routerLink="/Reports/AnalyticsGradeReport"><i class="las la-angle-right"></i> Grade</a></li>
                  <li class=""><a routerLink="/Reports/ScheduleStatusReport"><i class="las la-angle-right"></i> Schedule Status</a></li>
                  <li class=""><a routerLink="/Reports/StatusReport"><i class="las la-angle-right"></i> Status</a></li>
                  <li class=""><a routerLink="/Reports/EventCodeReport"><i class="las la-angle-right"></i> Event Code</a></li>
                  <li class=""><a routerLink="/Reports/EmployeeTeamReport"><i class="las la-angle-right"></i> Candidate Evaluation</a></li>
                  <li class=""><a routerLink="/Reports/PlantEvaluationReport"><i class="las la-angle-right"></i> Plant Evaluation</a></li>
                  <li class=""><a routerLink="/Reports/PlantCodeEvaluationReport"><i class="las la-angle-right"></i> Plant Code Evaluation</a></li>
                  <li class=""><a routerLink="/Reports/PlantAverageReport"><i class="las la-angle-right"></i> Plant Average</a></li>
                  <li class=""><a routerLink="/Reports/PlantYearlyAverageReport"><i class="las la-angle-right"></i> Plant Yearly Average</a></li>
               </ul>
            </li>


             <!-- <li><a routerLink="/Help" class="iq-waves-effect"><i class="lar la-question-circle"></i><span>Help</span></a></li> -->
            </ul>


            <ul id="iq-sidebar-toggle" class="iq-menu" *ngIf="isJury">
               <li><a routerLink="/JuryDashboard" class="iq-waves-effect"><i class="las la-home"></i><span>Dashboard</span></a></li>
               <li><a routerLink="/JurySchedule" class="iq-waves-effect"><i class="las la-calendar"></i><span>Current Schedules</span></a></li>
               <li><a routerLink="/JuryEvaluation" class="iq-waves-effect"><i class="las la-check-double"></i><span>Evaluations</span></a></li>
               <li *ngIf='menuDisable'><a routerLink="/EvaluationResults" class="iq-waves-effect"><i class="las la-edit"></i><span>Evaluation Results</span></a></li>
               <li><a routerLink="/JuryHelp" class="iq-waves-effect"><i class="lar la-question-circle"></i><span>Help</span></a></li>
            </ul>


       </nav>
       <div class="p-3"></div>
    </div>
 </div>
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from '../services/dashboard/dashboard.service';
import { Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { CompanylistService } from '../services/company/companylist.service';
import { ICompany } from '../company/company';
import { NgForm } from '@angular/forms';
import { ReportService } from '../services/reports/report.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private router:Router, private dashboardapi: DashboardService, private companylistService: CompanylistService, private reportapi: ReportService) { }

  @ViewChild('evaluationSearch') evaluationSearchInput: ElementRef;
  @ViewChild('plantSearch') plantSearchInput: ElementRef;
  @ViewChild('businessUnitSearch') businessUnitSearchInput: ElementRef;
  @ViewChild('companySearch') companySearchInput: ElementRef;

  @ViewChild('reportForm') reportForm: NgForm;
  @ViewChild('resetbusinessunit') resetbusinessunit: ElementRef;
  @ViewChild('reportData', {static: false}) reportData: ElementRef;
  @ViewChild('pdfTable', {static: false}) pdfTable: ElementRef;

  company: ICompany[];
  _company: ICompany[];
  businessunit: any;
  _businessunit: any;
  plant: any;
  _plant: any;
  disableReportBtn: boolean = false;
  shreport: boolean = false;
  filBusinessUnitName: any;
  filEvaluationName: any;
  filFromDate: any;
  filToDate: any;
  filPlantName: any;
  filCompanyName: any;
  report_data: any;
  disrec: boolean = false;
  PDFReport: any;
  PDFevaluation_id: any;
  PDFcompany_id: any;
  PDFbusinessunit_id: any;
  PDFplant_id: any;
  PDFfromDate: any;
  PDFtoDate: any;

  url: string = environment.api_url;

  labels: string[];
  data: number[];
  pieChartType: string = 'pie';
  colors: any = ["#0092C2", "#A71049", "#B3D237"];
  colorLegend: boolean = true;
  pieChartColors: any;
  options: any = {
    legend: {
      position: 'right',
      orient: 'vertical',
    }
  }

  chartReady: boolean = false;
 
  totalSchedules: number = 0;
  completedSchedules: number = 0;
  progressedSchedules: number = 0;


  evalu : any;
  plant_evalu : any;

  view: any[] = [700, 400];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Evaluations';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = 'Events';
  legendTitle: string = 'Status';

  colorScheme = {
    domain: ['#5AA454', '#C7B42C', '#AAAAAA']
  };

  ngOnInit(): void {

    this.companylistService.companyData().subscribe((data: ICompany[]) => {
      this.company = data;
      this._company = data;
    });

    this.dashboardapi.getadminDashboard().subscribe(data => {
      this.totalSchedules = data.count_data["tot_sch"];
      this.progressedSchedules = data.count_data["prog_sch"];
      this.completedSchedules = data.count_data["clos_sch"];

      this.evalu = data.evalua_graph;
      this.plant_evalu = data.p_evalua_graph;
      
      this.pieChartColors = [{ backgroundColor: this.colors }];
      this.data = data.chart;
      this.labels = data.chart_label;
      this.chartReady = true;
    });
  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  onfocusCompany() {
    this.companySearchInput.nativeElement.focus();
  }
  onInputChangeCompany() {
    const searchInput = this.companySearchInput.nativeElement.value ? this.companySearchInput.nativeElement.value.toLowerCase() : '';
    this.company = this._company.filter(c_data => {
      const c_name: any = c_data.companyName.toLowerCase();
      return c_name.indexOf(searchInput) > -1;
    })
  }

  onfocusBusinessUnit() {
    this.businessUnitSearchInput.nativeElement.focus();
  }
  onInputChangeBusinessUnit() {
    const searchInput = this.businessUnitSearchInput.nativeElement.value ? this.businessUnitSearchInput.nativeElement.value.toLowerCase() : '';
    this.businessunit = this._businessunit.filter(c_data => {
      const c_name: any = c_data.businessUnitName.toLowerCase();
      return c_name.indexOf(searchInput) > -1;
    })
  }

  onfocusPlant() {
    this.plantSearchInput.nativeElement.focus();
  }
  onInputChangePlant() {
    const searchInput = this.plantSearchInput.nativeElement.value ? this.plantSearchInput.nativeElement.value.toLowerCase() : '';
    this.plant = this._plant.filter(c_data => {
      const c_name: any = c_data.plantName.toLowerCase();
      return c_name.indexOf(searchInput) > -1;
    })
  }

  chgCompany(company_id) {
    if(company_id != "") {
      const formData = new FormData();
      formData.append('company_id', company_id);
      this.reportapi.businessUnits(formData).subscribe((data: any) => {
        if(data.other['msz']) {
          this.businessunit = data.dataList;
          this._businessunit = data.dataList;
          this.plant = [];
          this._plant = [];
          this.reportForm.control.get('plant').reset();
        } else {
          this.businessunit = [];
          this._businessunit = [];
          this.plant = [];
          this._plant = [];
          this.reportForm.control.get('businessUnit').reset();
          this.reportForm.control.get('plant').reset();
        }
      });
    } else {
      this.businessunit = [];
      this._businessunit = [];
      this.plant = [];
      this._plant = [];
      this.reportForm.control.get('businessUnit').reset();
      this.reportForm.control.get('plant').reset();
    }
  }

  chgBusinessUnit(businessunit_id) {
    if(businessunit_id != "") {
      const formData = new FormData();
      formData.append('businessunit_id', businessunit_id);
      this.reportapi.plants(formData).subscribe((data: any) => {
        if(data.other['msz']) {
          this.plant = data.dataList;
          this._plant = data.dataList;
        } else {
          this.plant = [];
          this._plant = [];
        }
      });
    } else {
      this.plant = [];
      this._plant = [];
      this.reportForm.control.get('plant').reset();
    }
  }

  submitReport(reportForm: NgForm){
    this.disableReportBtn = true;

    this.reportapi.dashboardReport(reportForm.form.value).subscribe((data) => {

      this.totalSchedules = data.count_data["tot_sch"];
      this.progressedSchedules = data.count_data["prog_sch"];
      this.completedSchedules = data.count_data["clos_sch"];
      
      this.evalu = data.evalua_graph;
      this.plant_evalu = data.p_evalua_graph;


      this.pieChartColors = [{ backgroundColor: this.colors }];
      this.data = data.chart;
      this.labels = data.chart_label;
      this.chartReady = true;


      this.filBusinessUnitName = data.businessUnitName;
      this.filPlantName = data.plantName;
      this.filCompanyName = data.companyName;
      this.filFromDate = data.rfromDate;
      this.filToDate = data.rtoDate;
      

      // //this.barChartData = data.chart;
      // //this.barChartLabels = data.chart_label;

      // this.report_data = data.dataRecords;

      // if(data.records) {
      //   this.disrec = true;
      // } else {
      //   this.disrec = false;
      // }

      this.disableReportBtn = false;
      // this.shreport = true;
    });
  }

}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IEvent } from 'src/app/event/event';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private _url: string = environment.api_url;

  constructor(private _http: HttpClient) { }

  addEvent(event: IEvent): Observable<IEvent>{
    let httpheaders = new HttpHeaders().set('Content-Type','application/Json');
    let options = {
      headers: httpheaders
    };
    return this._http.post<IEvent>(this._url+'/addEvent.php',event, options);
  }

  getEvent(id:number){
    return this._http.get<IEvent[]>(this._url+'/eachEvent.php?id='+id);
  }

  updateEvent(event: IEvent){
    return this._http.put(this._url+'/updateEvent.php?id='+event.eventId, event);
  }

}

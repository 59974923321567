<div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between">
       <div class="iq-header-title">
          <h4 class="card-title">{{ pageAct }} Employee to Schedule</h4>
       </div>
       
       <div class="pull-right add">
            <a routerLink="/ScheduleView/{{schId}}" mat-stroked-button color="primary">Back to Schedule Preview</a>
        </div>
    </div>
    <div class="iq-card-body">
      <strong class="red">* Fields are Required</strong>
       <div class="form-horizontal" [formGroup]="employeeForm">

        <div class="form-group row" [ngClass]="{'has-error': (erremployeeId.touched || erremployeeId.dirty) && erremployeeId.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="department_name">Employee No. <sup class="red">*</sup></label>
            <div class="col-sm-5">
              <mat-form-field class="example-full-width" appearance="outline">
                 <mat-label>Employee No.</mat-label>
                 <input matInput placeholder="Employee No." formControlName="employeeId" >
                 <mat-hint *ngIf="(erremployeeId.touched || erremployeeId.dirty) && erremployeeId.invalid"><span *ngIf="erremployeeId.errors?.required" class="error-msg">Employee No. is Required</span></mat-hint>
                 <mat-hint *ngIf="(erremployeeId.touched || erremployeeId.dirty) && erremployeeId.errors?.pattern"><span class="error-msg">Employee No. should be in numeric</span></mat-hint>
               </mat-form-field>
            </div>
         </div>

         <div class="form-group row" [ngClass]="{'has-error': (erremployeeName.touched || erremployeeName.dirty) && erremployeeName.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="department_name">Employee Name <sup class="red">*</sup></label>
            <div class="col-sm-5">
              <mat-form-field class="example-full-width" appearance="outline">
                 <mat-label>Employee Name</mat-label>
                 <input matInput placeholder="Employee Name" formControlName="employeeName" >
                 <mat-hint *ngIf="(erremployeeName.touched || erremployeeName.dirty) && erremployeeName.invalid"><span *ngIf="erremployeeName.errors?.required" class="error-msg">Employee Name is Required</span></mat-hint>
               </mat-form-field>
            </div>
         </div>
           
         <div class="form-group row" [ngClass]="{'has-error': (errcompanyId.touched || errcompanyId.dirty) && errcompanyId.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Company <sup class="red">*</sup></label>
            <div class="col-sm-5">

               <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Company</mat-label>
                  <mat-select formControlName="companyId" (ngModelChange)="getbusiUnit($event)">
                     <mat-option value="">-- Select Company --</mat-option>
                    <mat-option *ngFor="let company of companies" [value]="company.companyId">
                        {{company.companyName}}
                    </mat-option>
                  </mat-select>
                  <mat-hint *ngIf="(errcompanyId.touched || errcompanyId.dirty) && errcompanyId.invalid"><span *ngIf="errcompanyId.errors?.required" class="error-msg">Company is Required</span></mat-hint>
                </mat-form-field>
           </div>
         </div>

         <div class="form-group row" [ngClass]="{'has-error': (errplantId.touched || errplantId.dirty) && errplantId.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Plant <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Plant</mat-label>
                  <mat-select formControlName="plantId">
                     <mat-option value="">-- Select Plant --</mat-option>
                     <mat-optgroup *ngFor="let group of plant" label="{{group.businessUnitName}}">
                        <mat-option *ngFor="let item of group.items" [value]="item.plantId">
                           {{item.plantName}}
                        </mat-option>
                     </mat-optgroup>
                  </mat-select>
                  <mat-hint *ngIf="(errplantId.touched || errplantId.dirty) && errplantId.invalid"><span *ngIf="errplantId.errors?.required" class="error-msg">Plant is Required</span></mat-hint>
                </mat-form-field>
            </div>
         </div>

          <div class="form-group row" [ngClass]="{'has-error': (errprojectTheme.touched || errprojectTheme.dirty) && errprojectTheme.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="department_name">Project Theme <sup class="red">*</sup></label>
            <div class="col-sm-5">
              <mat-form-field class="example-full-width" appearance="outline">
                 <mat-label>Project Theme</mat-label>
                 <textarea matInput placeholder="" formControlName="projectTheme"></textarea>
                 <mat-hint *ngIf="(errprojectTheme.touched || errprojectTheme.dirty) && errprojectTheme.invalid"><span *ngIf="errprojectTheme.errors?.required" class="error-msg">Project Theme is Required</span></mat-hint>
               </mat-form-field>
            </div>
         </div>

         <div class="form-group row" [ngClass]="{'has-error': (errprojectNo.touched || errprojectNo.dirty) && errprojectNo.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="department_name">Project No. <sup class="red">*</sup></label>
            <div class="col-sm-5">
              <mat-form-field class="example-full-width" appearance="outline">
                 <mat-label>Project No.</mat-label>
                 <input matInput placeholder="Project No." formControlName="projectNo" >
                 <mat-hint *ngIf="(errprojectNo.touched || errprojectNo.dirty) && errprojectNo.invalid"><span *ngIf="errprojectNo.errors?.required" class="error-msg">Project No. is Required</span></mat-hint>
               </mat-form-field>
            </div>
         </div>

         <div class="form-group row" [ngClass]="{'has-error': (errbaseLine.touched || errbaseLine.dirty) && errbaseLine.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="department_name">Base Line <sup class="red">*</sup></label>
            <div class="col-sm-5">
              <mat-form-field class="example-full-width" appearance="outline">
                 <mat-label>Base Line</mat-label>
                 <input matInput placeholder="Base Line" formControlName="baseLine" >
                 <mat-hint *ngIf="(errbaseLine.touched || errbaseLine.dirty) && errbaseLine.invalid"><span *ngIf="errbaseLine.errors?.required" class="error-msg">Base Line is Required</span></mat-hint>
               </mat-form-field>
            </div>
         </div>

         <div class="form-group row" [ngClass]="{'has-error': (errtarget.touched || errtarget.dirty) && errtarget.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="department_name">Target <sup class="red">*</sup></label>
            <div class="col-sm-5">
              <mat-form-field class="example-full-width" appearance="outline">
                 <mat-label>Target</mat-label>
                 <input matInput placeholder="Target" formControlName="target" >
                 <mat-hint *ngIf="(errtarget.touched || errtarget.dirty) && errtarget.invalid"><span *ngIf="errtarget.errors?.required" class="error-msg">Target is Required</span></mat-hint>
               </mat-form-field>
            </div>
         </div>

         <div class="form-group row" [ngClass]="{'has-error': (errsavings.touched || errsavings.dirty) && errsavings.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="department_name">Savings <sup class="red">*</sup></label>
            <div class="col-sm-5">
              <mat-form-field class="example-full-width" appearance="outline">
                 <mat-label>Savings</mat-label>
                 <input matInput placeholder="Savings" formControlName="savings" >
                 <mat-hint *ngIf="(errsavings.touched || errsavings.dirty) && errsavings.invalid"><span *ngIf="errsavings.errors?.required" class="error-msg">Savings is Required</span></mat-hint>
               </mat-form-field>
            </div>
         </div>
          
          <div class="form-group row">
              <div class="col-md-7 text-right">
                <input type="hidden" formControlName="scheduleId" />
                <input type="hidden" *ngIf="hiddenId" value="" formControlName="excelUploadId" />
                <button type="button" [disabled]="!employeeForm.valid"  (click)="submitEmployee(pageAct)"  mat-raised-button color="primary">Save</button>
            </div>
          </div>
       </div>
    </div>
 </div>
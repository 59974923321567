import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ICompany } from 'src/app/company/company';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  public _url: string = environment.api_url;
  constructor(private _http: HttpClient) { }

  addCompany(company: ICompany):Observable<ICompany>{
    let httpheaders = new HttpHeaders().set('Content-Type','application/Json');
    let options = {
      headers: httpheaders
    };
    return this._http.post<ICompany>(this._url+'/addCompany.php',company, options);
  }

  getCompany(id:number){
    return this._http.get<ICompany[]>(this._url+'/eachCompany.php?id='+id);
  }

  updateCompany(company: ICompany){
    return this._http.put(this._url+'/updateCompany.php?id='+company.companyId, company);
  }

}

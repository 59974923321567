<div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between">
       <div class="iq-header-title">
          <h4 class="card-title">Business Unit List</h4>
       </div>
       <div class="pull-right add">
            <a routerLink="/Business_Unit" mat-stroked-button color="primary">Add New Business Unit</a>
        </div>
    </div>
    <div class="iq-card-body company-list">
    <div class="form-group row">
        <div class="col-sm-12 col-lg-12">


            <div class="mat-elevation-z8">
                <mat-form-field appearance="outline" class="filter-dt">
                    <mat-label>Filter Data</mat-label>
                    <input (keyup)="applyFilter($event.target.value)" matInput placeholder="Filter Data">
                    <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
                    <!-- <mat-hint>Hint</mat-hint> -->
                  </mat-form-field>

                <table mat-table matSort [dataSource]="dataSource">
              
                  <!-- Position Column -->
                  <ng-container matColumnDef="sno">
                    <th mat-header-cell *matHeaderCellDef> # </th>
                    <td mat-cell *matCellDef="let element; let i = index;"> {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (i + 1) }}. </td>
                  </ng-container>
              
                  <!-- Name Column -->
                  <ng-container matColumnDef="companyId">
                    <th mat-header-cell *matHeaderCellDef> Company </th>
                    <td mat-cell *matCellDef="let element"> {{element.companyId}} </td>
                  </ng-container>
              
                  <!-- Weight Column -->
                  <ng-container matColumnDef="businessUnitName">
                    <th mat-header-cell *matHeaderCellDef> Business Unit Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.businessUnitName}} </td>
                  </ng-container>
              
                  <!-- Symbol Column -->
                  <ng-container matColumnDef="businessUnitCode">
                    <th mat-header-cell *matHeaderCellDef> Business Unit Code </th>
                    <td mat-cell *matCellDef="let element"> {{element.businessUnitCode}} </td>
                  </ng-container>

                  <ng-container matColumnDef="businessUnitId">
                    <th mat-header-cell *matHeaderCellDef>Actions </th>
                    <td mat-cell *matCellDef="let element">
                        <span class="material-icons icon-dt" title="Edit" routerLink="/Business_Unit/{{ element.businessUnitId }}">
                          edit
                        </span>&nbsp;&nbsp;
                        <span class="material-icons text-danger icon-dt" title="Delete" (click)=delete(element)>
                          delete_outline
                        </span>
                    </td>
                  </ng-container>
                  
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <div *ngIf="dataSource.data.length === 0">
                    No Records Found!
                </div>
              
                <mat-paginator #paginatorRef [pageSize]="50" [pageSizeOptions]="[50, 100, 150]" showFirstLastButtons></mat-paginator>
              </div>


            <!-- <table class="table">
                <tr>
                    <th>#</th>
                    <th class="text-left">Company</th>
                    <th class="text-left">Business Unit Name</th>
                    <th class="text-center">Business Unit Code</th>
                    <th class="text-center">Status</th>
                </tr>
                <tr *ngFor="let business_unit of businessunit; let bu = index">
                    <td>{{ bu+1 }}.</td>
                    <td class="text-left">{{ business_unit.companyId}}</td>
                    <td class="text-left">{{ business_unit.businessUnitName}}</td>
                    <td class="text-center">{{ business_unit.businessUnitCode}}</td>
                    <td class="text-center">
                        <div class="custom-control custom-switch custom-switch-color custom-control-inline">
          <input type="checkbox" class="custom-control-input bg-info" id="customSwitch06" checked="">
          <label class="custom-control-label" for="customSwitch06"></label>
          <a routerLink="/Business_Unit/{{ business_unit.businessUnitId }}" class="edit" title="{{ 'Edit '+business_unit.businessUnitName }}"><i class="lar la-edit"></i></a>
          <a (click)=delete(business_unit) class="delete" title="{{ 'Delete '+business_unit.businessUnitName }}"><i class="lar la-trash-alt"></i></a>
       </div>
                    </td>
                </tr>
            </table> -->
        </div>
    </div>
                                  
    </div>
 </div>
<!-- Footer -->
<footer class="bg-white iq-footer">
    <div class="container-fluid">
       <div class="row">
          <div class="col-lg-6">
             <ul class="list-inline mb-0">
                <li class="list-inline-item"><a href="javascript:void(0);">Privacy Policy</a></li>
                <li class="list-inline-item"><a href="javascript:void(0);">Terms of Use</a></li>
             </ul>
          </div>
          <!-- <div class="col-lg-6 text-right">
             Copyright 2020 <a href="javascript:void(0);">SaiWebTechnos</a> All Rights Reserved.
          </div> -->
       </div>
    </div>
 </footer>
 <!-- Footer END -->
<div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between">
       <div class="iq-header-title">
          <h4 class="card-title">Schedule Status Report</h4>
       </div>
    </div>
    <div class="iq-card-body">
       <div class="form-horizontal">
           
        <form #reportForm="ngForm" (ngSubmit)="submitReport(reportForm);">

        <div class="form-group row">
            <label class="control-label col-sm-2 align-self-center mb-0" for="event_date">From Date <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field (click)="fromDate.open()">
                  <input matInput readonly [matDatepicker]="fromDate" is-date name="fromDate" [ngModel]="" #rfromdate="ngModel">
                  <mat-datepicker-toggle [for]="fromDate" matSuffix></mat-datepicker-toggle>
                  <mat-datepicker #fromDate></mat-datepicker>
               </mat-form-field>
            </div>
         </div>

         <div class="form-group row">
            <label class="control-label col-sm-2 align-self-center mb-0" for="event_date">To Date <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field (click)="toDate.open()">
                  <input matInput readonly [matDatepicker]="toDate" name="toDate" [ngModel]="" #rtodate="ngModel">
                  <mat-datepicker-toggle [for]="toDate" matSuffix></mat-datepicker-toggle>
                  <mat-datepicker #toDate></mat-datepicker>
               </mat-form-field>
            </div>
         </div>

         <div class="form-group row">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Business Unit <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusBusinessUnit()" >
                  <mat-label>Business Unit</mat-label>
                  <mat-select name="businessUnit" [ngModel]="" (ngModelChange)="chgBusinessUnit($event)" #resetbusinessunit="ngModel">
                     <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Business Unit" #businessUnitSearch (input)="onInputChangeBusinessUnit()" />
                     <mat-option value="">-- Select Business Unit --</mat-option>
                    <mat-option *ngFor="let bu of businessunit" [value]="bu.businessUnitId">
                     {{bu.businessUnitName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
           </div>
         </div>

         <div class="form-group row">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Plant <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusPlant()" >
                  <mat-label>Plant</mat-label>
                  <mat-select name="plant" [ngModel]="" #resetplant>
                     <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Plant" #plantSearch (input)="onInputChangePlant()" />
                     <mat-option value="">-- Select Plant --</mat-option>
                    <mat-option *ngFor="let p of plant" [value]="p.plantId">
                     {{p.plantName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
           </div>
         </div>

         <div class="form-group row">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Evaluation <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusEvaluation()">
                  <mat-label>Evaluation</mat-label>
                  <mat-select name="evaluation" [ngModel]="">
                     <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Evaluation" #evaluationSearch (input)="onInputChangeEvaluation()" />
                     <mat-option value="">-- Select Evaluation --</mat-option>
                    <mat-option *ngFor="let ev of evaluation" [value]="ev.evaluationId">
                     {{ev.evaluationName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
           </div>
         </div>
          
          <div class="form-group row">
              <div class="col-md-7 text-right">
                <button type="submit" [disabled]="disableReportBtn || rfromdate.pristine || rtodate.pristine || resetbusinessunit.pristine" mat-raised-button color="primary">Get Report</button>
            </div>
          </div>

        </form>
       </div>

       <div *ngIf="shreport">
            <a target="_blank" href="{{ url }}/generatePDF/pdf/ScheduleStatusReportPDF.php?evaluation_id={{PDFevaluation_id}}&businessunit_id={{PDFbusinessunit_id}}&plant_id={{PDFplant_id}}&fromDate={{PDFfromDate}}&toDate={{PDFtoDate}}" mat-raised-button color="primary">Generate PDF</a>&nbsp; &nbsp;

            <a target="_blank" href="{{ url }}/generateExcel/ScheduleStatusReport.php?evaluation_id={{PDFevaluation_id}}&businessunit_id={{PDFbusinessunit_id}}&plant_id={{PDFplant_id}}&fromDate={{PDFfromDate}}&toDate={{PDFtoDate}}" mat-raised-button color="primary">Generate Excel</a><br>
            <table class="table-table">
               <tr>
                  <th>Business Unit</th><th>:</th><td>{{ filBusinessUnitName }}</td>
               </tr>
               <tr>
                  <th>Plant</th><th>:</th><td>{{ filPlantName }}</td>
               </tr>
               <tr>
                  <th>Evaluation</th><th>:</th><td>{{ filEvaluationName }}</td>
               </tr>
               <tr>
                  <th>Dates</th><th>:</th><td>{{ filFromDate }} - {{ filToDate }}</td>
               </tr>
            </table>
           <br>
           <table class="table" *ngIf="disrec">
               <tr>
                    <th>#</th>
                    <th>Competition</th>
                    <th>Plant</th>
                    <th>Scheduled</th>
                    <th>Closed</th>
                    <th>Progress</th>
               </tr>
               <tr *ngFor="let r of report_data; let i = index;">
                   <td>{{ i+1 }}.</td>
                   <td>{{ r.evaluationName }}</td>
                   <td>{{ r.plantName }}</td>
                   <td>{{ r.scheduled }}</td>
                   <td>{{ r.closed }}</td>
                   <td>{{ r.progress }}</td>
               </tr>
           </table>
           <table class="table" *ngIf="!disrec">
            <tr>
                <th>#</th>
                <th>Competition</th>
                <th>Plant</th>
                <th>Scheduled</th>
                <th>Closed</th>
                <th>Progress</th>
            </tr>
            <tr>
                <td colspan="6">No Records Found</td>
            </tr>
        </table>
       </div>


    </div>
 </div>
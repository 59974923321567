import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from '../services/JuryEvaluation/message.service';
import { ProfileService } from '../services/profile/profile.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  message: any;
  pageAct: string = "Create";
  hiddenId: boolean = false;
  profileForm: FormGroup;

  constructor(private fb: FormBuilder, 
    private router:Router, 
    private profileapi: ProfileService, 
    private route: ActivatedRoute,
    private toastr: ToastrService, private messageData: MessageService) { }
  
  token: any;
  public adminMobile: any;

  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.token = window.localStorage.getItem('token');
    if(!this.token){
      this.router.navigate(['']);
    }

    if(this.route.snapshot.paramMap.get('id'))
    {
      let id = parseInt(this.route.snapshot.paramMap.get('id'));
      this.pageAct = "Update";
      this.hiddenId = true;
      this.profileapi.getProfile(id).subscribe((data: any) => {
        this.profileForm.get('profileName').patchValue(data.admin_name);
        this.profileForm.get('profileMobile').patchValue(data.mobile);
        this.profileForm.get('profileUsername').patchValue(data.name);
        this.profileForm.get('profilePassword').patchValue(data.pwd);
        this.profileForm.get('profileId').patchValue(data.Id);
      })

      this.profileForm = this.fb.group({
        profileId: [''],
        profileName: ['', [Validators.required]],
        profileMobile: ['', Validators.required],
        profileUsername: ['', Validators.required],
        profilePassword: ['', Validators.required]
      });
      
    } else {
      this.pageAct = "Create";
      this.hiddenId = false;

      this.profileForm = this.fb.group({
        profileName: ['', [Validators.required]],
        profileMobile: ['', Validators.required],
        profileUsername: ['', Validators.required],
        profilePassword: ['', Validators.required]
      });
      
    }
    
  }

  get errProfileName() { return this.profileForm.get('profileName'); }
  get errProfileMobile() { return this.profileForm.get('profileMobile'); }
  get errUsername() { return this.profileForm.get('profileUsername'); }
  get errPassword() { return this.profileForm.get('profilePassword'); }

  chkMobile(event) {
    if(event.target.value != "")
    {
      
    }
  }

  submitProfile(act){
    if(this.profileForm.invalid)
    {
      return;
    }
    if(act == "Create") {
      this.profileapi.addProfile(this.profileForm.value)
      .subscribe(data => {
        this.router.navigate(['/Dashboard']);
        this.toastr.success('Profile Added Successfully');
      })
    }
    if(act == "Update") {
      this.profileapi.updateProfile(this.profileForm.value).subscribe((data) => {
        this.messageData.sendAdminMessage(data['mobile']);
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/Dashboard'], { relativeTo: this.route });
        
        this.toastr.success('Profile Updated Successfully');
      })
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../services/login/login.service';
import { JuryloginService } from '../services/jurylogin/jurylogin.service';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-jurylogin',
  templateUrl: './jurylogin.component.html',
  styleUrls: ['./jurylogin.component.css']
})
export class JuryloginComponent implements OnInit {

  logDetails: FormGroup;
  message: any = "";
  jtoken: any;
  errorMSG : boolean = false;
  hide: any = false;

  constructor(private fb: FormBuilder,private JuryloginService: JuryloginService,private router:Router, private matDialog: MatDialog) { }

  ngOnInit(): void {
    this.matDialog.closeAll();
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }

    this.jtoken = window.localStorage.getItem('jtoken');
    if(this.jtoken){
      this.router.navigate(['/JuryDashboard']);
    }
    
    this.logDetails = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', Validators.required]
    });
  }

  login()
  {
    if(this.logDetails.invalid)
    {
      return;
    }

    const logDetails = {
      username: this.logDetails.controls.username.value,
      password: this.logDetails.controls.password.value
    };

    this.JuryloginService.jurylogin(logDetails)
    .subscribe((data: any) => {
        this.message = data.message;
        this.errorMSG = true;
        if(data.jtoken) {
          window.localStorage.setItem('jtoken', data.jtoken);
          window.localStorage.setItem('jsch_j_id', data.jlog_id);
          window.localStorage.setItem('tokenact', 'jury');
          window.localStorage.setItem('admin_access', '0');
          this.router.navigate(['/JuryDashboard']);
        }
    }, (error) => {
      this.message = error.message;
    });
  }

}

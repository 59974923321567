import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { JuryEvaluationService } from '../services/JuryEvaluation/jury-evaluation.service';
import { SchedulelistService } from '../services/schedule/schedulelist.service';
import { SessiondetailComponent } from '../sessiondetail/sessiondetail.component';
import { SmsmailComponent } from '../smsmail/smsmail.component';

@Component({
  selector: 'app-jurypassword-list',
  templateUrl: './jurypassword-list.component.html',
  styleUrls: ['./jurypassword-list.component.css']
})
export class JurypasswordListComponent implements OnInit {

  popoverTitle = "Session End Confirmation";
  popoverMessage = "Dear Admin would you like to End Session for this Jury";
  confirmClicked = false;
  cancelClicked = false;

  displayedColumns: string[] = ['sno', 'juryName', 'juryMobile', 'username', 'password', 'actions'];
  dataSource = new MatTableDataSource;

  constructor(private router:Router, private route: ActivatedRoute,  private shedulelistService: SchedulelistService, private toastr: ToastrService, public dialog: MatDialog, private evaluationapi: JuryEvaluationService, private snackBar: MatSnackBar) { }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  schedules: any;
  getId: any;
  juryScheduleID: any;
  showSpinner: boolean = true;


  token: any;
  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.token = window.localStorage.getItem('token');
    if(!this.token){
      this.router.navigate(['']);
    }

    this.juryScheduleID = window.localStorage.getItem('jsch_j_id');

    this.getId = this.route.snapshot.paramMap.get('id');

    this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
  
    this.shedulelistService.passwordData(this.route.snapshot.paramMap.get('id')).subscribe((data) => {
      this.schedules = data;
      this.dataSource.data = data;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

  sessionDetail(juryScheduleID){
    const formData = new FormData();
    formData.append('jury_schedule_id', juryScheduleID);
    formData.append('end_schedule', '0');
    this.evaluationapi.submitSession(formData).subscribe((data) => {

      let snackBarRef = this.snackBar.open('Schedule ended successfully.', '', { horizontalPosition: 'center', verticalPosition: 'top', duration : 3000 });
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['/JuryPassword/'+this.getId], { relativeTo: this.route });
      
    });
  }

  smsmail(juryId){
    const formData = new FormData();
    formData.append('jury_schedule_id', juryId);
    
    this.evaluationapi.getSmsemail(formData).subscribe((data) => {
        //console.log(data);
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.width = "40%";
        dialogConfig.height = "60%";
        dialogConfig.data = { jevtCode : data.eventCode, jevtDate : data.eventDate, jusername : data.username, jpassword : data.password };
        this.dialog.open(SmsmailComponent,dialogConfig);
        
        setTimeout(() => {
          this.showSpinner = false;
        }, 1500);
      
    });

  }

}

<div class="iq-card">
  <div class="iq-card-header d-flex justify-content-between">
    <div class="iq-header-title">
      <h4 class="card-title">Company List</h4>
    </div>
    <div class="pull-right add">
      <a routerLink="/Company" mat-stroked-button color="primary">Add New Company</a>
   </div>
  </div>
  
  <div class="iq-card-body company-list">
    <div class="form-group row">
      <div class="col-sm-12 col-lg-12">
        <div class="mat-elevation-z8">
          <mat-form-field appearance="outline" class="filter-dt">
              <mat-label>Filter Data</mat-label>
              <input (keyup)="applyFilter($event.target.value)" matInput placeholder="Filter Data">
              <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
              <!-- <mat-hint>Hint</mat-hint> -->
            </mat-form-field>
            
          <table mat-table matSort [dataSource]="dataSource">
        
            <!-- Position Column -->
            <ng-container matColumnDef="sno">
              <th mat-header-cell *matHeaderCellDef> # </th>
              <td mat-cell *matCellDef="let element; let i = index;"> {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (i + 1) }}. </td>
            </ng-container>
        
            <!-- Name Column -->
            <ng-container matColumnDef="companyName">
              <th mat-header-cell *matHeaderCellDef> Company Name </th>
              <td mat-cell *matCellDef="let element"> {{element.companyName}} </td>
            </ng-container>
        
            <!-- Weight Column -->
            <ng-container matColumnDef="companyCode">
              <th mat-header-cell *matHeaderCellDef> Company Code </th>
              <td mat-cell *matCellDef="let element"> {{element.companyCode}} </td>
            </ng-container>
        
            <ng-container matColumnDef="companyId">
              <th mat-header-cell *matHeaderCellDef>Actions </th>
              <td mat-cell *matCellDef="let element">
                  <span class="material-icons icon-dt" title="Edit" routerLink="/Company/{{ element.companyId }}">
                    edit
                  </span>&nbsp;&nbsp;
                  <span class="material-icons text-danger icon-dt" title="Delete" (click)=delete(element)>
                    delete_outline
                  </span>
              </td>
            </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

          <div *ngIf="dataSource.data.length === 0">
              No Records Found!
          </div>
        
          <mat-paginator #paginatorRef [pageSize]="50" [pageSizeOptions]="[50, 100, 150]" showFirstLastButtons></mat-paginator>
        </div>




        <!-- <table class="table">
          <tr>
            <th>#</th>
            <th class="text-left">Company Name</th>
            <th class="text-center">Company Code</th>
            <th class="text-center">Status</th>
          </tr>
          <tr *ngFor="let company of companies; let ci = index">
            <td>{{ ci+1 }}.</td>
            <td class="text-left">{{company.companyName}}</td>
            <td class="text-center">{{company.companyCode}}</td>
            <td class="text-center">
              <div
                class="custom-control custom-switch custom-switch-color custom-control-inline"
              >
                <input
                  type="checkbox"
                  class="custom-control-input bg-info"
                  id="customSwitch06"
                  checked=""
                />
                <label
                  class="custom-control-label"
                  for="customSwitch06"
                ></label>
                <a routerLink="/Company/{{ company.companyId }}" class="edit" title="{{ 'Edit '+company.companyName | titlecase }}"
                  ><i class="lar la-edit"></i
                ></a>
                <a (click)=delete(company) class="delete" title="{{ 'Delete '+company.companyName | titlecase }}"
                  ><i class="lar la-trash-alt"></i
                ></a>
              </div>
            </td>
          </tr>
        </table> -->
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
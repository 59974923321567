import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISearchFilter } from 'src/app/juryevaluation/searchFilter';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private _url: string = environment.api_url;

  constructor(private _http: HttpClient) { }

  getjuryschedule(juryscheduleid:number){
    return this._http.get<ISearchFilter[]>(this._url+'/searchEmployee.php?juryscheduleid='+juryscheduleid);
  }

  getevaluation(juryscheduleid:number){
    return this._http.get<ISearchFilter[]>(this._url+'/getEvaluation.php?juryscheduleid='+juryscheduleid);
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeadermessageService {

  private pageHead = new BehaviorSubject<string>('Dashboard');
  mszHead$ = this.pageHead.asObservable();

  constructor() { }

  sendMessage(message: string) {
    this.pageHead.next(message);
  }

}

<div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between">
       <div class="iq-header-title">
          <h4 class="card-title">{{ pageAct }} Department</h4>
       </div>
       <div class="pull-right add">
         <a routerLink="/Department_List" mat-stroked-button color="primary">Department List</a>
      </div>
    </div>
    <div class="iq-card-body">
      <strong class="red">* Fields are Required</strong>
       <div class="form-horizontal" [formGroup]="departmentForm">

         <div class="form-group row" [ngClass]="{'has-error': (errcompanyId.touched || errcompanyId.dirty) && errcompanyId.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Company <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusCompany()">
                  <mat-label>Company</mat-label>
                  <mat-select formControlName="companyId" (ngModelChange)="getbusiUnit($event)">
                     <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Company" #companySearch (input)="onInputChangeCompany()" />
                     <mat-option value="">-- Select Company --</mat-option>
                    <mat-option *ngFor="let company of companies" [value]="company.companyId">
                     {{company.companyName}}
                    </mat-option>
                  </mat-select>
                  <mat-hint *ngIf="(errcompanyId.touched || errcompanyId.dirty) && errcompanyId.invalid"><span *ngIf="errcompanyId.errors?.required" class="error-msg">Company is Required</span></mat-hint>
                </mat-form-field>
           </div>
         </div>

         <div class="form-group row" [ngClass]="{'has-error': (errplantId.touched || errplantId.dirty) && errplantId.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Plant <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusPlant()">
                  <mat-label>Plant</mat-label>
                  <mat-select formControlName="plantId" (ngModelChange)="chgPlant()">
                     <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Plant" #plantSearch (input)="onInputChangePlant()" />
                     <mat-option value="">-- Select Plant --</mat-option>
                     <mat-optgroup *ngFor="let group of plant" label="{{group.businessUnitName}}">
                        <mat-option *ngFor="let item of group.items" [value]="item.plantId">
                           {{item.plantName}}
                        </mat-option>
                     </mat-optgroup>
                  </mat-select>
                  <mat-hint *ngIf="(errplantId.touched || errplantId.dirty) && errplantId.invalid"><span *ngIf="errplantId.errors?.required" class="error-msg">Plant is Required</span></mat-hint>
                </mat-form-field>
            </div>
         </div>

          <div class="form-group row" [ngClass]="{'has-error': (errdepartmentName.touched || errdepartmentName.dirty) && errdepartmentName.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="department_name">Department Name <sup class="red">*</sup></label>
            <div class="col-sm-5">
              <mat-form-field class="example-full-width" appearance="outline">
                 <mat-label>Department Name</mat-label>
                 <input matInput placeholder="Department Name" formControlName="departmentName" >
                 <mat-hint *ngIf="(errdepartmentName.touched || errdepartmentName.dirty) && errdepartmentName.invalid"><span *ngIf="errdepartmentName.errors?.required" class="error-msg">Department Name is Required</span></mat-hint>
               </mat-form-field>
            </div>
         </div>

          <div class="form-group row" [ngClass]="{'has-error': (errdepartmentCode.touched || errdepartmentCode.dirty) && errdepartmentCode.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="department_name">Department Code <sup class="red">*</sup></label>
            <div class="col-sm-5">
              <mat-form-field class="example-full-width" appearance="outline">
                 <mat-label>Department Code</mat-label>
                 <input matInput placeholder="Department Code" formControlName="departmentCode" >
                 <mat-hint *ngIf="(errdepartmentCode.touched || errdepartmentCode.dirty) && errdepartmentCode.invalid"><span *ngIf="errdepartmentCode.errors?.required" class="error-msg">Department Code is Required</span></mat-hint>
                 <mat-hint *ngIf="(errdepartmentCode.touched || errdepartmentCode.dirty) && errdepartmentCode.errors?.pattern"><span class="error-msg">Department Code can only be Alpha Numeric.</span></mat-hint>
                 <mat-hint *ngIf="errdepartmentCode.pending"><span class="success-msg">Plant Code is Validating...</span></mat-hint>
                 <mat-hint *ngIf="errdepartmentCode.errors?.codeExists"><span class="error-msg">Plant Code is already exists.</span></mat-hint>
               </mat-form-field>
            </div>
         </div>
          
          <div class="form-group row">
              <div class="col-md-7 text-right">
                <input type="hidden" *ngIf="hiddenId" value="" formControlName="departmentId" />
                <button type="button" [disabled]="!departmentForm.valid"  (click)="submitDepartment(pageAct)"  mat-raised-button color="primary">Save</button>
            </div>
          </div>
       </div>
    </div>
 </div>
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IJury } from 'src/app/jury/jury';

@Injectable({
  providedIn: 'root'
})
export class JurylistService {

  private _url: string = environment.api_url;
  constructor(private _http: HttpClient) { }

  juryData(): Observable<IJury[]>{
    return this._http.get<IJury[]>(this._url+'/juryList.php');
  }

  evoluJuryData(sel_id: any): Observable<any>{
    return this._http.get<any>(this._url+'/scheduleJuryList.php?id='+sel_id);
  }

  deleteJury(id){
    return this._http.delete<IJury>(this._url+'/deleteJury.php?id='+id)
  }
}

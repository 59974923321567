import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IPlant } from 'src/app/plant/plant';
import { IOptPlant } from 'src/app/plant/opt_plant';

@Injectable({
  providedIn: 'root'
})
export class PlantlistService {

  private _url: string = environment.api_url;

  constructor(private _http: HttpClient) { }

  plantData(): Observable<IPlant[]>{
    return this._http.get<IPlant[]>(this._url+'/plantList.php');
  }

  plantCode(sel_id: any): Observable<any>{
    return this._http.get<any>(this._url+'/plantCode.php?id='+sel_id);
  }
  
  optPlantData(sel_id: any): Observable<IOptPlant[]>{
    return this._http.get<IOptPlant[]>(this._url+'/optPlantList.php?id='+sel_id);
  }

  deletePlant(id){
    return this._http.delete<IPlant>(this._url+'/deletePlant.php?id='+id)
  }

}

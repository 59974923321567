<div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between">
       <div class="iq-header-title">
          <h4 class="card-title">{{ pageAct }} Business Unit</h4>
       </div>
       <div class="pull-right add">
         <a routerLink="/Business_Unit_List" mat-stroked-button color="primary">Business Unit List</a>
      </div>
    </div>
    <div class="iq-card-body">
      <strong class="red">* Fields are Required</strong>
       <div class="form-horizontal" [formGroup]="businessUnitForm">
         

          <div class="form-group row" [ngClass]="{'has-error': (errCompanyId.touched || errCompanyId.dirty) && errCompanyId.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Company <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusCompany()">
                  <mat-label>Company</mat-label>
                  <mat-select formControlName="companyId" (ngModelChange)="chgCompany()">
                     <input type="text" class="select-search" autocomplete="off" matInput placeholder="Search Company" #companySearch (input)="onInputChangeCompany()" />
                     <mat-option value="">-- Select Company --</mat-option>
                    <mat-option *ngFor="let company of companies" [value]="company.companyId">
                     {{company.companyName}}
                    </mat-option>
                  </mat-select>
                  <mat-hint *ngIf="(errCompanyId.touched || errCompanyId.dirty) && errCompanyId.invalid"><span *ngIf="errCompanyId.errors?.required" class="error-msg">Company is Required</span></mat-hint>
                </mat-form-field>
           </div>
         </div>

          <div class="form-group row" [ngClass]="{'has-error': (errbusinessUnitName.touched || errbusinessUnitName.dirty) && errbusinessUnitName.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="department_name">Business Unit Name <sup class="red">*</sup></label>
            <div class="col-sm-5">
              <mat-form-field class="example-full-width" appearance="outline">
                 <mat-label>Business Unit Name</mat-label>
                 <input matInput placeholder="Business Unit Name" formControlName="businessUnitName" >
                 <mat-hint *ngIf="(errbusinessUnitName.touched || errbusinessUnitName.dirty) && errbusinessUnitName.invalid"><span *ngIf="errbusinessUnitName.errors?.required" class="error-msg">Business Unit Name is Required</span></mat-hint>
               </mat-form-field>
            </div>
         </div>

          <div class="form-group row" [ngClass]="{'has-error': (errbusinessUnitCode.touched || errbusinessUnitCode.dirty) && errbusinessUnitCode.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="department_name">Business Unit Code <sup class="red">*</sup></label>
            <div class="col-sm-5">
              <mat-form-field class="example-full-width" appearance="outline">
                 <mat-label>Business Unit Code</mat-label>
                 <input matInput placeholder="Business Unit Code" formControlName="businessUnitCode" >
                 <mat-hint *ngIf="(errbusinessUnitCode.touched || errbusinessUnitCode.dirty) && errbusinessUnitCode.invalid"><span *ngIf="errbusinessUnitCode.errors?.required" class="error-msg">Business Unit Code is Required</span></mat-hint>
                 <mat-hint *ngIf="(errbusinessUnitCode.touched || errbusinessUnitCode.dirty) && errbusinessUnitCode.errors?.pattern"><span class="error-msg">Business Unit Code can only be Alpha Numeric.</span></mat-hint>
                 <mat-hint *ngIf="errbusinessUnitCode.pending"><span class="success-msg">Business Unit Code is Validating...</span></mat-hint>
                 <mat-hint *ngIf="errbusinessUnitCode.errors?.codeExists"><span class="error-msg">Business Unit Code is already exists.</span></mat-hint>
               </mat-form-field>
            </div>
         </div>
          
          <div class="form-group row">
              <div class="col-md-7 text-right">
                <input type="hidden" *ngIf="hiddenId" value="" formControlName="businessUnitId" />
                <button type="button" [disabled]="!businessUnitForm.valid"  (click)="submitBusinessUnit(pageAct)"  mat-raised-button color="primary">Save</button>
            </div>
          </div>
       </div>
    </div>
 </div>
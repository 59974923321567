import { Component, OnInit } from '@angular/core';
import { JuryEvaluationService } from '../services/JuryEvaluation/jury-evaluation.service';
import { MenuService } from '../services/menu/menu.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  constructor(private messageMenu: MenuService, private evaluationapi: JuryEvaluationService) { }

  public menuDisable: boolean;
  isAdmin: boolean;
  isJury: boolean;
  tokenact: any;
  juryScheduleID: any;
  admin_access: any;

  ngOnInit(): void {

    this.juryScheduleID = window.localStorage.getItem('jsch_j_id');
    this.admin_access = window.localStorage.getItem('admin_access');
    const formData = new FormData();
    formData.append('jury_schedule_id', this.juryScheduleID);

    this.evaluationapi.getDashboard(formData).subscribe((data) => {
      this.messageMenu.sendMessage(data.ResultDisplay);
      this.messageMenu.mszMenu$.subscribe(
        message => {
          this.menuDisable = message;
        }
      );
    });


    this.messageMenu.mszMenu$.subscribe(
      message => {
        this.menuDisable = message;
      }
    );

    this.tokenact = window.localStorage.getItem('tokenact');

    if(this.tokenact == "admin"){
      this.isAdmin = true;
      this.isJury = false;
    } else if(this.tokenact == "jury"){
      this.isAdmin = false;
      this.isJury = true;
    }

  }

}

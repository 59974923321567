  <div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between">
      <div class="iq-header-title">
        <h4 class="card-title">Request List</h4>
      </div>
    </div>
    
    <div class="iq-card-body company-list">
      <div class="form-group row">
        <div class="col-sm-12 col-lg-12">
          <div class="mat-elevation-z8">
            <mat-form-field appearance="outline" class="filter-dt">
                <mat-label>Filter Data</mat-label>
                <input (keyup)="applyFilter($event.target.value)" matInput placeholder="Filter Data">
                <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
                <!-- <mat-hint>Hint</mat-hint> -->
              </mat-form-field>
              
            <table mat-table matSort [dataSource]="dataSource">
          
              <!-- Position Column -->
              <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef> # </th>
                <td mat-cell *matCellDef="let element; let i = index;"> {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (i + 1) }}. </td>
              </ng-container>
          
              <!-- Name Column -->
              <ng-container matColumnDef="requestDate">
                <th mat-header-cell *matHeaderCellDef> Date </th>
                <td mat-cell *matCellDef="let element"> {{element.requestDate}} </td>
              </ng-container>
          
              <!-- Weight Column -->
              <ng-container matColumnDef="eventCode">
                <th mat-header-cell *matHeaderCellDef> Event Code </th>
                <td mat-cell *matCellDef="let element"> {{element.eventCode}} </td>
              </ng-container>

              <ng-container matColumnDef="businessUnit">
                <th mat-header-cell *matHeaderCellDef> Business Unit </th>
                <td mat-cell *matCellDef="let element"> {{element.businessUnit}} </td>
              </ng-container>

              <ng-container matColumnDef="employeeId">
                <th mat-header-cell *matHeaderCellDef> Employee ID </th>
                <td mat-cell *matCellDef="let element"> {{element.employeeId}} </td>
              </ng-container>

              <ng-container matColumnDef="employeeName">
                <th mat-header-cell *matHeaderCellDef> Employee / Team name </th>
                <td mat-cell *matCellDef="let element"> {{element.employeeName}} </td>
              </ng-container>

              <ng-container matColumnDef="juryName">
                <th mat-header-cell *matHeaderCellDef> Jury Name </th>
                <td mat-cell *matCellDef="let element"> {{element.juryName}} </td>
              </ng-container>

              <ng-container matColumnDef="remarks">
                <th mat-header-cell *matHeaderCellDef> Reason </th>
                <td mat-cell *matCellDef="let element"> {{element.remarks}} </td>
              </ng-container>
          
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Actions </th>
                <td mat-cell *matCellDef="let element">
                    <select (change)="ch_act($event)" *ngIf="element.status==0">
                        <option value="">-- Select Action --</option>
                        <option value="A_{{ element.requestId }}">Accepted</option>
                        <option value="D_{{ element.requestId }}">Declined</option>
                    </select>
                    <span *ngIf="element.status==2"><font color="red">D</font></span>
                    <span *ngIf="element.status==1"><font color="green">A</font></span>
                </td>
              </ng-container>
              
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
  
            <div *ngIf="dataSource.data.length === 0">
                No Records Found!
            </div>
          
            <mat-paginator #paginatorRef [pageSize]="50" [pageSizeOptions]="[50, 100, 150]" showFirstLastButtons></mat-paginator>
          </div>

        </div>
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IBusinessUnit } from 'src/app/business-unit/businessunit'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BusinessunitService {

  private _url: string = environment.api_url;
  constructor(private _http: HttpClient) { }

  addBusinessUnit(businessunit: IBusinessUnit):Observable<IBusinessUnit>{
    let httpheaders = new HttpHeaders().set('Content-Type','application/Json');
    let options = {
      headers: httpheaders
    };
    return this._http.post<IBusinessUnit>(this._url+'/addBusinessUnit.php',businessunit, options);
  }

  getBusinessUnit(id:number){
    return this._http.get<IBusinessUnit[]>(this._url+'/eachBusinessUnit.php?id='+id);
  }

  updateBusinessUnit(businessunit: IBusinessUnit){
    return this._http.put(this._url+'/updateBusinessUnit.php?id='+businessunit.businessUnitId, businessunit);
  }
}

<div class="wrapper" *ngIf="isLogin">
    <app-menu></app-menu>
    <app-header></app-header>
    <div id="content-page" class="content-page">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12 col-lg-12">
              <app-breadcrums></app-breadcrums>
              <router-outlet *ngIf="isLogin"></router-outlet>
            </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
</div> 
<router-outlet *ngIf="!isLogin"></router-outlet> 
<div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between">
      <div class="iq-header-title">
        <h4 class="card-title pull-left">Evaluation</h4>
      </div>
    </div>
    


    <div class="iq-card-body company-list">
      <app-spinner *ngIf="showSpinner"></app-spinner>
      <div class="row evu-details">
        <div class="col-md-6"><strong> Business Unit :</strong> {{ businessUnitName }}</div>
        <div class="col-md-3"><strong>Plant :</strong> {{ plantName }}</div>
        <div class="col-md-3"><strong>Evaluation Type :</strong> {{ evaluationType }}</div>
      </div>
      <div class="clearfix"></div>
      <div class="iq-card-body company-list" *ngIf="!msz" style="text-align: center;">
        Session End is Done for this Evaluation. For more Information please contact <strong>"Admin"</strong> for support.
      </div>

      <div *ngIf="!evoId">
      
      <div class="form-group row" *ngIf="msz">
        <div class="col-sm-12 col-lg-12">
            <div class="row">
                <div class="col-md-6 persional-dtls" *ngIf="shJuryDetails">
                    <article>
                    <table class="table">
                        <tr>
                            <td colspan="3" align="center"><h4>Personnel Details</h4> </td>
                        </tr>
                        <tr>
                            <td width="30%">Employee No</td>
                            <td width="2%">:</td>
                            <td width="30%">{{ employeeData.employeeId }}</td>
                        </tr>
                        <tr>
                          <td>Name</td>
                          <td>:</td>
                          <td>{{ employeeData.employeeName }}</td>
                      </tr>
                      <tr>
                        <td>Company</td>
                        <td>:</td>
                        <td>{{ employeeData.companyName }}</td>
                    </tr>
                    <tr>
                      <td>Business Unit</td>
                      <td>:</td>
                      <td>{{ employeeData.businessUnitName }}</td>
                  </tr>
                      <tr>
                        <td>Plant</td>
                        <td>:</td>
                        <td>{{ employeeData.plantName }}</td>
                    </tr>
                    </table>
                </article>  
            </div>
            <div class="col-md-6 persional-dtls" *ngIf="shJuryDetails">
                <article>
                <table class="table">
                    <tr>
                        <td colspan="3" align="center"><h4>Project Details</h4> </td>
                    </tr>
                    <tr>
                        <td width="30%">Project Theme</td>
                        <td width="2%">:</td>
                        <td width="30%">{{ employeeData.projectTheme }}</td>
                    </tr>
                    <tr>
                      <td>Project No</td>
                      <td>:</td>
                      <td>{{ employeeData.projectNo }}</td>
                  </tr>
                  <tr>
                    <td>Base Line</td>
                    <td>:</td>
                    <td>{{ employeeData.baseLine }}</td>
                </tr>
                <tr>
                  <td>Target</td>
                  <td>:</td>
                  <td>{{ employeeData.target }}</td>
              </tr>
                  <tr>
                    <td>Savings</td>
                    <td>:</td>
                    <td>{{ employeeData.savings }}</td>
                </tr>
                </table> 
            </article>
        </div>
    </div>
    <form #evaluationForm="ngForm" (ngSubmit)="submitMarks(evaluationForm);">
      <div *ngIf="shQueDetails">
          <div *ngIf="msz">
            <h2 class="card-title pull-left">Project Rating Details</h2>
            <span class="pull-right down-pdf"><font color="#a5034f">Guidelines :</font> <a href="{{ url }}/assets/images/evolution_guideline/{{ guideLines }}"><i class="las la-file-pdf"></i></a></span>

            <span class="pull-right down-pdf"><font color="#a5034f">Awarded Marks :</font> <strong>{{ givenMarks }}</strong> &nbsp;&nbsp;&nbsp; Maximum Marks : <strong>{{ tot_questions }}</strong></span>
          </div>
          <table class="table" style="margin-bottom:0;">
            <thead>
              <tr>
                <th width="5%">Point No.</th>
                <th width="18.5%">Sub Element</th>
                <th class="inner-table">
                  <table class="table">
                    <tr>
                      <th width="35%" *ngIf="totSubQuestions">Look for</th>
                      <th width="10%" align="center" class="center">Max Marks</th>
                      <th width="15%" align="center" class="center">Awarded Marks</th>
                      <th width="15%" align="center" class="center">Remarks</th>
                      
                    </tr>
                </table>
                </th>
              </tr>
            </thead>
          </table>
          <input type="hidden" name="jury_schedule_id" [(ngModel)]="juryScheduleID" />
          <input type="hidden" name="employee_id" [(ngModel)]="employeeID" />
          <input type="hidden" name="evId" [(ngModel)]="evoId" />
          <div class="pr-details">
          <table class="table">
            <tbody *ngFor="let question of questionData; let i = index;">
              <tr class="tr-hd" *ngIf="question.titleId != null">
                <td align="center"></td>
                <td colspan="6"><strong>{{ question.titleId }}</strong></td>
              </tr>
              <tr>
                <td align="center" width="6%">{{ i+1 }}.</td>
                <td width="19%"><strong>{{ question.questionPhase }}</strong></td>
                <td *ngIf="totSubQuestions" class="inner-td">
                  <tr *ngFor="let subquestion of question.subquestions  let si = index;" ngModelGroup="sq{{ subquestion.subQuestionId }}">
                    <input type="hidden" name="QueId" [(ngModel)]="question.questionId" />
                    <input type="hidden" name="QueMark" [ngModel]="0" />
                    <td width="35%">{{ subquestion.subQuestionPhase }}
                      <input type="hidden" name="SubQueId" [(ngModel)]="subquestion.subQuestionId" />
                    </td>
                    <td width="10%" align="center">{{ subquestion.subQuestionMarks }}</td>
                    <td align="center" width="15%">
                      <div class="form-group row">
                          <mat-form-field class="example-full-width" appearance="outline">
                             <mat-label>Marks</mat-label>
                             <input matInput placeholder="Marks" class="qMark" name="SubQueMark" [ngModel]="" id="hque_{{ subquestion.subQuestionId }}" (click)="q_mark($event,subquestion)" (keyup)="q_mark($event,subquestion)" NumberOnly>
                          </mat-form-field>
                      </div>

                    </td>
                    <td align="center" class="center" width="15%">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Remark</mat-label>
                        <input matInput placeholder="Remark" class="qTxt" name="remark" [ngModel]="">
                      </mat-form-field>
                      <!-- <input type="text" class="qTxt" name="remark" [ngModel]="" /> -->
                    </td>
                    
                  </tr>
                </td>
                <td align="center" *ngIf="question.subquestions.length==0" class="inner-td">
                  <tr ngModelGroup="q{{ question.questionId }}">
                    <input type="hidden" name="QueId" [(ngModel)]="question.questionId" />
                    <td width="15%">{{ question.questionMarks }}</td>
                    <input type="hidden" name="SubQueId" [ngModel]="0" />
                    <input type="hidden" name="SubQueMark" [ngModel]="0" />
                    <td align="center" width="15%" id="que_{{ question.questionId }}">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Marks</mat-label>
                        <input matInput placeholder="Marks" class="qMark" name="QueMark" [ngModel]="question.questionEvMarks" (click)="q_mark($event,question)" (keyup)="q_mark($event,question)" NumberOnly>
                      </mat-form-field>
                      <!-- <input type="text" class="qMark" name="QueMark" [ngModel]="" (click)="q_mark($event)" (keyup)="q_mark($event)" /> -->
                    </td>
                    <td align="center" width="15%" class="remarks" >
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Remark</mat-label>
                        <input matInput placeholder="Remark" class="qTxt" name="remark" [ngModel]="question.questionEvRemark">
                      </mat-form-field>
                      <!-- <input type="text" class="qTxt" name="remark" [ngModel]="" /> -->
                    </td>
                    
                  </tr>
                </td>
                
              </tr>
              
            </tbody>
          </table>
          </div>

          <div class="col-md-12 text-right save">
            <button type="submit" mat-raised-button color="primary">Update</button>
          </div>

          </div>
        </form>


        <!-- <pre>{{ evaluationForm.value | json }}</pre> -->
   
        </div>
      </div>
    </div>

    <div *ngIf="evoId">

      <div *ngIf="msz">
        <h2 class="card-title pull-left">Project Rating Details</h2>
        <span class="pull-right down-pdf"><font color="#a5034f">Guidelines :</font> <a href="{{ url }}/assets/images/evolution_guideline/{{ guideLines }}"><i class="las la-file-pdf"></i></a></span>
        <span class="pull-right down-pdf"><font color="#a5034f">Awarded Marks :</font> <strong>{{ givenMarks }}</strong> &nbsp;&nbsp;&nbsp; Maximum Marks : <strong>{{ tot_questions }}</strong></span>
      </div>
      <div style="clear:both;"></div>

      
      <mat-vertical-stepper>
        
        
        <div *ngFor="let question_top of questionData; let ti = index;">
        <mat-step *ngIf="question_top.titleId != null">

          <table class="table" style="margin-bottom:0;" >
            <thead>
              <tr>
                <th width="5%">Point No.</th>
                <th width="18.5%">Sub Element</th>
                <th class="inner-table">
                  <table class="table">
                    <tr>
                      <th width="30%" *ngIf="totSubQuestions">Look for</th>
                      <th width="10%" align="center" class="center">Max Marks</th>
                      <th width="15%" align="center" class="center">Awarded Marks</th>
                      <th width="15%" align="center" class="center">Remarks</th>
                    </tr>
                </table>
                </th>
              </tr>
            </thead>
          </table>
            <ng-template matStepLabel  >{{ question_top.titleId }}</ng-template>
            <form #evaluationForm="ngForm" (ngSubmit)="submitMarks(evaluationForm);">
              <input type="hidden" name="jury_schedule_id" [(ngModel)]="juryScheduleID" />
              <input type="hidden" name="employee_id" [(ngModel)]="employeeID" />
              <input type="hidden" name="evId" [(ngModel)]="evoId" />
              <input type="hidden" name="titId" [(ngModel)]="question_top.chk_title_id" />
            <table>
              <tbody *ngFor="let question of questionData; let i = index;">
                <tr *ngIf="question_top.chk_title_id == question.chk_title_id">
                  <td align="center" width="6%">{{ i+1 }}.</td>
                  <td width="19%"><strong>{{ question.questionPhase }}</strong></td>
                  <td *ngIf="totSubQuestions" class="inner-td">
                    <tr *ngFor="let subquestion of question.subquestions  let si = index;" ngModelGroup="sq{{ subquestion.subQuestionId }}">
                      <input type="hidden" name="QueId" [(ngModel)]="question.questionId" />
                      <input type="hidden" name="QueMark" [ngModel]="0" />
                      <td width="35%">{{ subquestion.subQuestionPhase }}
                        <input type="hidden" name="SubQueId" [(ngModel)]="subquestion.subQuestionId" />
                      </td>
                      <td width="10%" align="center">{{ subquestion.subQuestionMarks }}</td>
                      <td align="center" width="15%" *ngIf="subquestion.marksh">
                        <div class="form-group row">
                            <mat-form-field class="example-full-width" appearance="outline">
                               <mat-label>Marks</mat-label>
                               <input matInput placeholder="Marks" class="qMark" name="SubQueMark" [ngModel]="" id="hque_{{ subquestion.subQuestionId }}" (click)="q_mark($event,subquestion)" (keyup)="q_mark($event,subquestion)" NumberOnly>
                            </mat-form-field>
                        </div>
                      </td>
                      <td align="center" width="15%"  *ngIf="!subquestion.marksh">
                        <div class="form-group row">
                            <mat-form-field class="example-full-width" appearance="outline">
                               <mat-label>Marks</mat-label>
                               <input matInput placeholder="Marks" class="qMark" name="SubQueMark" [ngModel]="subquestion.questionEvMarks" id="hque_{{ subquestion.subQuestionId }}" (click)="q_mark($event,subquestion)" (keyup)="q_mark($event,subquestion)" NumberOnly>
                            </mat-form-field>
                        </div>
                      </td>
                      <td align="center" class="center" class="remarks" width="15%">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Remark</mat-label>
                          <input matInput placeholder="Remark" class="qTxt" name="remark" [ngModel]="subquestion.questionEvRemark">
                        </mat-form-field>
                      </td>
                    </tr>
                  </td>

                  <td align="center" *ngIf="question.subquestions.length==0">
                    <tr ngModelGroup="q{{ question.questionId }}">
                      <input type="hidden" name="QueId" [(ngModel)]="question.questionId" />
                      <td>{{ question.questionMarks }}</td>
                      <input type="hidden" name="SubQueId" [ngModel]="0" />
                      <input type="hidden" name="SubQueMark" [ngModel]="0" />
                      <td align="center" id="que_{{ question.questionId }}">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Marks</mat-label>
                          <input matInput placeholder="Marks" class="qMark" name="QueMark" [ngModel]="question.questionEvMarks" (click)="q_mark($event,question)" (keyup)="q_mark($event,question)" NumberOnly>
                        </mat-form-field>
                        <!-- <input type="text" class="qMark" name="QueMark" [ngModel]="" (click)="q_mark($event)" (keyup)="q_mark($event)" /> -->
                      </td>
                      <td align="left" >
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Remark</mat-label>
                          <input matInput placeholder="Remark" class="qTxt" name="remark" [ngModel]="question.questionEvRemark">
                        </mat-form-field>
                        <!-- <input type="text" class="qTxt" name="remark" [ngModel]="" /> -->
                      </td>
                      
                    </tr>
                  </td>


                </tr>
              </tbody>
            </table>
            <div>
              <span class="pull-left"><button mat-button matStepperPrevious mat-raised-button color="warn" *ngIf="ti != 0">Previous Title</button></span>
              <span class="pull-right">
              <button type="submit" mat-button matStepperNext mat-raised-button color="primary">Save & Submit</button></span>
            </div>
            </form>
            
        </mat-step>

        </div>




      </mat-vertical-stepper>
    </div>

    </div>
  </div>
  
  <router-outlet></router-outlet>
<div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between">
       <div class="iq-header-title">
          <h4 class="card-title">Plant Average Report</h4>
       </div>
    </div>
    <div class="iq-card-body">
       <div class="form-horizontal">
           
        <form #reportForm="ngForm" (ngSubmit)="submitReport(reportForm);">

        <div class="form-group row">
            <label class="control-label col-sm-2 align-self-center mb-0" for="event_date">From Date <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field (click)="fromDate.open()">
                  <input matInput readonly [matDatepicker]="fromDate" is-date name="fromDate" [ngModel]="" #rfromdate="ngModel">
                  <mat-datepicker-toggle [for]="fromDate" matSuffix></mat-datepicker-toggle>
                  <mat-datepicker #fromDate></mat-datepicker>
               </mat-form-field>
            </div>
         </div>

         <div class="form-group row">
            <label class="control-label col-sm-2 align-self-center mb-0" for="event_date">To Date <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field (click)="toDate.open()">
                  <input matInput readonly [matDatepicker]="toDate" name="toDate" [ngModel]="" #rtodate="ngModel">
                  <mat-datepicker-toggle [for]="toDate" matSuffix></mat-datepicker-toggle>
                  <mat-datepicker #toDate></mat-datepicker>
               </mat-form-field>
            </div>
         </div>

         <div class="form-group row">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Company <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusCompany()" >
                  <mat-label>Company</mat-label>
                  <mat-select name="company" [ngModel]="" (ngModelChange)="chgCompany($event)" #rcompany="ngModel">
                     <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Company" #companySearch (input)="onInputChangeCompany()" />
                     <mat-option value="">-- Select Company --</mat-option>
                    <mat-option *ngFor="let com of company" [value]="com.companyId">
                     {{com.companyName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
           </div>
         </div>

         <div class="form-group row">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Business Unit <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusBusinessUnit()" >
                  <mat-label>Business Unit</mat-label>
                  <mat-select name="businessUnit" [ngModel]="" (ngModelChange)="chgBusinessUnit($event)" #resetbusinessunit="ngModel">
                     <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Business Unit" #businessUnitSearch (input)="onInputChangeBusinessUnit()" />
                     <mat-option value="">-- Select Business Unit --</mat-option>
                    <mat-option *ngFor="let bu of businessunit" [value]="bu.businessUnitId">
                     {{bu.businessUnitName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
           </div>
         </div>

         <div class="form-group row">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Plant <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusPlant()" >
                  <mat-label>Plant</mat-label>
                  <mat-select name="plant" [ngModel]="" #resetplant="ngModel">
                     <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Plant" #plantSearch (input)="onInputChangePlant()" />
                     <mat-option value="">-- Select Plant --</mat-option>
                    <mat-option *ngFor="let p of plant" [value]="p.plantId">
                     {{p.plantName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
           </div>
         </div>

         <div class="form-group row">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Evaluation <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline" (click)="onfocusEvaluation()">
                  <mat-label>Evaluation</mat-label>
                  <mat-select name="evaluation" [ngModel]="" (ngModelChange)="chgEvaluation($event)" #resetevaluation="ngModel">
                     <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Evaluation" #evaluationSearch (input)="onInputChangeEvaluation()" />
                     <mat-option value="">-- Select Evaluation --</mat-option>
                    <mat-option *ngFor="let ev of evaluation" [value]="ev.evaluationId">
                     {{ev.evaluationName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
           </div>
         </div>
          
          <div class="form-group row">
              <div class="col-md-7 text-right">
                <button type="submit" [disabled]="disableReportBtn || rfromdate.pristine || rtodate.pristine || rcompany.pristine || resetbusinessunit.pristine || resetevaluation.pristine" mat-raised-button color="primary">Get Report</button>
            </div>
          </div>

        </form>
       </div>

       <div *ngIf="shreport" class="row report-pdf">
         <div class="col-md-6">
            <div>
               <div style="display:block;">
                   <canvas baseChart
                      [datasets]="barChartData"
                      [labels]="barChartLabels"
                      [options]="barChartOptions"
                      [plugins]="barChartPlugins"
                      [legend]="barChartLegend"
                      [chartType]="barChartType">
                   </canvas>
               </div>
            </div>
         </div>
         <div class="col-md-6">
         
            <a target="_blank" href="{{ url }}/generatePDF/pdf/PlantAverageReportPDF.php?evaluation_id={{PDFevaluation_id}}&company_id={{PDFcompany_id}}&businessunit_id={{PDFbusinessunit_id}}&plant_id={{PDFplant_id}}&fromDate={{PDFfromDate}}&toDate={{PDFtoDate}}" mat-raised-button color="primary">Generate PDF</a>&nbsp; &nbsp;

           <a target="_blank" href="{{ url }}/generateExcel/PlantAverageReport.php?evaluation_id={{PDFevaluation_id}}&company_id={{PDFcompany_id}}&businessunit_id={{PDFbusinessunit_id}}&plant_id={{PDFplant_id}}&fromDate={{PDFfromDate}}&toDate={{PDFtoDate}}" mat-raised-button color="primary">Generate Excel</a>
           <br>
            <table style="margin-top: 10px;">
               <tr><td><strong>Company</strong></td> <td>:</td> <td>{{ filCompanyName }}</td></tr>
               <tr><td><strong>Business Unit</strong></td> <td>:</td> <td>{{ filBusinessUnitName }}</td></tr>
               <tr><td><strong>Plant</strong></td> <td>:</td> <td>{{ filPlantName }}</td></tr>
               <tr><td><strong>Evaluation</strong></td> <td>:</td> <td>{{ filEvaluationName }}</td></tr>
               <tr><td><strong>Dates</strong> <td>:</td> <td>{{ filFromDate }} - {{ filToDate }}</td></tr>
            </table>
         </div>
         <div class="large-table">
           <table class="table" *ngIf="disrec">
               <tr *ngFor="let r of report_data; let i = index;">
                   <th *ngIf="i > 1">{{ (i+1)-2 }}.</th>
                   <th *ngIf="i <= 1"></th>
                   <td *ngFor="let a of report_fields; let f = index">{{ r[f] }}</td>
               </tr>
           </table>
         </div>
           <table class="table" *ngIf="!disrec">
            
            <tr>
                <td colspan="5">No Records Found</td>
            </tr>
        </table>
       </div>


    </div>
 </div>
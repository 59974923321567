import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ISchedule } from 'src/app/schedule/schedule';

@Injectable({
  providedIn: 'root'
})
export class SchedulelistService {

  private _url: string = environment.api_url;
  constructor(private _http: HttpClient) { }

  scheduleData(): Observable<ISchedule[]>{
    return this._http.get<ISchedule[]>(this._url+'/scheduleList.php');
  }

  passwordData(id): Observable<any>{
    return this._http.get<any>(this._url+'/schedulePasswordList.php?id='+id);
  }

  deleteSchedule(id){
    return this._http.delete<ISchedule>(this._url+'/deleteSchedule.php?id='+id)
  }
}


<!-- <app-spinner *ngIf="showSpinner"></app-spinner> -->
<h4 class="hdng">Feedback</h4>
<!-- <mat-card>Simple card</mat-card> -->

<!-- <mat-grid-list cols="3" rowHeight="2:1">
    <mat-grid-tile>1</mat-grid-tile>
    <mat-grid-tile>2</mat-grid-tile>
    <mat-grid-tile>3</mat-grid-tile>
  </mat-grid-list> -->

<mat-dialog-actions>
    <div class="session-popup" [formGroup]="questionForm">
    
    
    
    <table class="chartform table">
        <tr>
            <td><strong>{{ data.juryName }}</strong></td>
            <td><strong>{{ data.juryMobile }}</strong></td>
        </tr>
        <tr>
            <td><strong>{{ data.audit }}</strong></td>
            <td><strong>{{ data.session_end_date }}</strong></td>
        </tr>    
    </table>
        
    <table>
        <tr>
            <td>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Comments *</mat-label>
                    <textarea matInput placeholder="Comments *" formControlName="comment" id="sub_question_phase_criteria" style="resize:none; height: 150px;"></textarea>
                </mat-form-field>
            </td>
        </tr>
    </table>

    <div class="form-group row">
        <div class="col-md-7 text-right">
            <input type="hidden" value="" formControlName="scheduleJuryId" />
            <input type="hidden" value="" formControlName="te" />
          <button type="button" [disabled]="!questionForm.valid" (click)="submitFeedback()"  mat-raised-button color="primary">Submit</button>
      </div>
   </div>

    

</div>
   
</mat-dialog-actions>
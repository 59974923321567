import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { AbstractControl, AsyncValidatorFn, FormControl, FormControlDirective, FormGroup } from "@angular/forms";
import { Observable, timer } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root"
})
export class UserValidators {

    public _url: string = environment.api_url;
    companyName: any = "";
    companyId: any = "";
    businessunitId: any = "";
    plantId: any = "";
    departmentId: any = "";
    formvals = [];

  constructor(private http: HttpClient) {}

  chkCompanyCode(all_fields) {
    return timer(1000).pipe(
      switchMap(() => {
        const formData = new FormData();
        formData.append('company_id', all_fields.cname);
        formData.append('code', all_fields.code);
        return this.http.post<any>(this._url+'/chkcompanycode.php', formData);
      })
    );
  }

  companyValidator(): AsyncValidatorFn {
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
        this.companyName = <FormControl>control.root.get('companyName').value;
        this.formvals['code'] = control.value;
        this.formvals['cname'] = this.companyName;
      return this.chkCompanyCode(this.formvals).pipe(
        map(res => {
          if (res.code_status) {
            return { codeExists: true };
          }
        })
      );
    };
  }

  chkEditCompanyCode(all_fields) {
    return timer(1000).pipe(
      switchMap(() => {
        const formData = new FormData();
        formData.append('id', all_fields.id);
        formData.append('company_id', all_fields.cname);
        formData.append('code', all_fields.code);
        return this.http.post<any>(this._url+'/chkcompanycode.php', formData);
      })
    );
  }

  companyEditValidator(id): AsyncValidatorFn {
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
        this.companyName = <FormControl>control.root.get('companyName').value;
        this.formvals['id'] = id;
        this.formvals['code'] = control.value;
        this.formvals['cname'] = this.companyName;
      return this.chkEditCompanyCode(this.formvals).pipe(
        map(res => {
          if (res.code_status) {
            return { codeExists: true };
          }
        })
      );
    };
  }

  chkBusinessUnitCode(all_fields) {
    return timer(1000).pipe(
      switchMap(() => {
        const formData = new FormData();
        formData.append('company_id', all_fields.cid);
        formData.append('code', all_fields.code);
        return this.http.post<any>(this._url+'/chkbusinessunitcode.php', formData);
      })
    );
  }

  BusinessUnitValidator(): AsyncValidatorFn {
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
        this.companyId = <FormControl>control.root.get('companyId').value;
        this.formvals['code'] = control.value;
        this.formvals['cid'] = this.companyId;
      return this.chkBusinessUnitCode(this.formvals).pipe(
        map(res => {
          if (res.code_status) {
            return { codeExists: true };
          }
        })
      );
    };
  }

  chkEditBusinessUnitCode(all_fields) {
    return timer(1000).pipe(
      switchMap(() => {
        const formData = new FormData();
        formData.append('id', all_fields.id);
        formData.append('company_id', all_fields.cid);
        formData.append('code', all_fields.code);
        return this.http.post<any>(this._url+'/chkbusinessunitcode.php', formData);
      })
    );
  }

  businessUnitEditValidator(id): AsyncValidatorFn {
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
        this.companyId = <FormControl>control.root.get('companyId').value;
        this.formvals['id'] = id;
        this.formvals['code'] = control.value;
        this.formvals['cid'] = this.companyId;
      return this.chkEditBusinessUnitCode(this.formvals).pipe(
        map(res => {
          if (res.code_status) {
            return { codeExists: true };
          }
        })
      );
    };
  }

  chkPlantCode(all_fields) {
    return timer(1000).pipe(
      switchMap(() => {
        const formData = new FormData();
        formData.append('businessunit_id', all_fields.buid);
        formData.append('code', all_fields.code);
        return this.http.post<any>(this._url+'/chkplantcode.php', formData);
      })
    );
  }

  PlantValidator(): AsyncValidatorFn {
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
        this.businessunitId = <FormControl>control.root.get('businessUnitId').value;
        this.formvals['code'] = control.value;
        this.formvals['buid'] = this.businessunitId;
      return this.chkPlantCode(this.formvals).pipe(
        map(res => {
          if (res.code_status) {
            return { codeExists: true };
          }
        })
      );
    };
  }

  chkEditPlantCode(all_fields) {
    return timer(1000).pipe(
      switchMap(() => {
        const formData = new FormData();
        formData.append('id', all_fields.id);
        formData.append('businessunit_id', all_fields.buid);
        formData.append('code', all_fields.code);
        return this.http.post<any>(this._url+'/chkplantcode.php', formData);
      })
    );
  }

  plantEditValidator(id): AsyncValidatorFn {
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
        this.formvals['id'] = id;
        this.businessunitId = <FormControl>control.root.get('businessUnitId').value;
        this.formvals['code'] = control.value;
        this.formvals['buid'] = this.businessunitId;
      return this.chkEditPlantCode(this.formvals).pipe(
        map(res => {
          if (res.code_status) {
            return { codeExists: true };
          }
        })
      );
    };
  }















  chkDepartmentCode(all_fields) {
    return timer(1000).pipe(
      switchMap(() => {
        const formData = new FormData();
        formData.append('company_id', all_fields.cid);
        formData.append('plant_id', all_fields.pid);
        formData.append('code', all_fields.code);
        return this.http.post<any>(this._url+'/chkdepartmentcode.php', formData);
      })
    );
  }

  DepartmentValidator(): AsyncValidatorFn {
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
        this.companyId = <FormControl>control.root.get('companyId').value;
        this.plantId = <FormControl>control.root.get('plantId').value;
        this.formvals['code'] = control.value;
        this.formvals['cid'] = this.companyId;
        this.formvals['pid'] = this.plantId;
      return this.chkDepartmentCode(this.formvals).pipe(
        map(res => {
          if (res.code_status) {
            return { codeExists: true };
          }
        })
      );
    };
  }

  chkEditDepartmentCode(all_fields) {
    return timer(1000).pipe(
      switchMap(() => {
        const formData = new FormData();
        formData.append('id', all_fields.id);
        formData.append('company_id', all_fields.cid);
        formData.append('plant_id', all_fields.pid);
        formData.append('code', all_fields.code);
        return this.http.post<any>(this._url+'/chkdepartmentcode.php', formData);
      })
    );
  }

  departmentEditValidator(id): AsyncValidatorFn {
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
        this.formvals['id'] = id;
        this.companyId = <FormControl>control.root.get('companyId').value;
        this.plantId = <FormControl>control.root.get('plantId').value;
        this.formvals['code'] = control.value;
        this.formvals['cid'] = this.companyId;
        this.formvals['pid'] = this.plantId;
      return this.chkEditDepartmentCode(this.formvals).pipe(
        map(res => {
          if (res.code_status) {
            return { codeExists: true };
          }
        })
      );
    };
  }

}

<div class="iq-card">

        <h4 class="card-title pull-left">Evaluations Results</h4>

    <div class="iq-card-body company-list">

        <div class="session-popup">
            <div class="row threepop">
                <div class="col-md-4 assigned"><h4>Assigned - 10,000</h4></div>
                <div class="col-md-4 completed"><h4>Completed - 10,000</h4></div>
                <div class="col-md-4 pending"><h4>Pending - 10,000</h4></div>
            </div>

           <div class="clearfix"></div>
            <table class="table">
                <tr>
                    <td width="20%" align="right"><strong>Remarks</strong></td>
                    <td width="5%" align="center">:</td>
                    <td width="60%" align="left"><textarea></textarea></td>
                </tr>
            </table>
        <div class="btn-grp">
            <div class="pull-right add">
                <span class="info"><i class="las la-info-circle"></i></span> <button mat-raised-button color="primary">Submit</button>
            </div>
            </div>  
        </div>

    </div>
  </div>
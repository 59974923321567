import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ReportService } from 'src/app/services/reports/report.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-feedbackreport',
  templateUrl: './feedbackreport.component.html',
  styleUrls: ['./feedbackreport.component.css']
})
export class FeedbackreportComponent implements OnInit {

  feedbackJury: any;
  eventcodeid: any;

  url: string = environment.api_url
  img_url: string = environment.url;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService, private reportapi: ReportService) { }

  ngOnInit(): void {

    const formData = new FormData();
      this.eventcodeid = this.route.snapshot.paramMap.get('id');
      formData.append('event_id', this.route.snapshot.paramMap.get('id'));
      this.reportapi.feedbackReport(formData).subscribe((data: any) => {
        this.feedbackJury = data
        console.log(data);
      });

  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { IDepartment } from '../department/department';
import { Router } from '@angular/router';
import { DepartmentlistService } from '../services/department/departmentlist.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-departmentlist',
  templateUrl: './departmentlist.component.html',
  styleUrls: ['./departmentlist.component.css']
})
export class DepartmentlistComponent implements OnInit {

  displayedColumns: string[] = ['sno','companyId', 'businessUnitId', 'plantId', 'departmentName', 'departmentCode', 'departmentId'];
  dataSource = new MatTableDataSource;

  departments: IDepartment[];
  constructor(private router: Router, private departmentlistService: DepartmentlistService, private toastr: ToastrService) { }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  token: any;
  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.token = window.localStorage.getItem('token');
    if(!this.token){
      this.router.navigate(['']);
    }

    this.dataSource.paginator = this.paginator;

    this.departmentlistService.departmentData().subscribe((data: IDepartment[]) => {
      this.departments = data;
      this.dataSource.data = data;
    });
  }

  delete(departments: IDepartment):void {
    this.departmentlistService.deleteDepartment(departments.departmentId ).subscribe(data => { 
      this.departments = this.departments.filter(u => u !== departments)

      this.dataSource = new MatTableDataSource(this.departments);
      this.dataSource.paginator = this.paginator;

      this.toastr.success('Department Deleted Successfully');
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

}

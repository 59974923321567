import { Component, OnInit, ViewChild } from '@angular/core';
import { ISchedule } from '../schedule/schedule';
import { Router } from '@angular/router';
import { SchedulelistService } from '../services/schedule/schedulelist.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
//import { MatSort } from '@angular/material/sort';



let datalist: any = "";

@Component({
  selector: 'app-schedulelist',
  templateUrl: './schedulelist.component.html',
  styleUrls: ['./schedulelist.component.css']
})
export class SchedulelistComponent implements OnInit {
  
  displayedColumns: string[] = ['sno','evaluationId', 'plantId', 'departmentId', 'eventCode', 'eventDate', 'evaluationType', 'scheduleId'];
  dataSource = new MatTableDataSource;
  schedules: ISchedule[];
  shdis: boolean = true;
  hidis: boolean = false;
  popoverTitle = "Delete Confirmation";
  popoverMessage = "Dear Admin would you like to erase this schedule permanently";
  confirmClicked = false;
  cancelClicked = false;

  constructor(private router:Router, private shedulelistService: SchedulelistService, private toastr: ToastrService) { }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  //@ViewChild(MatSort) sort: MatSort;
  token: any;
  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.token = window.localStorage.getItem('token');
    if(!this.token){
      this.router.navigate(['']);
    }

    this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
  
    this.shedulelistService.scheduleData().subscribe((data: ISchedule[]) => {
      this.schedules = data;
      this.dataSource.data = data;
    });
  }

  delete(schedules: ISchedule):void {
    this.shedulelistService.deleteSchedule(schedules.scheduleId ).subscribe(data => { 
      this.schedules = this.schedules.filter(u => u !== schedules)
      
      this.dataSource = new MatTableDataSource(this.schedules);
      this.dataSource.paginator = this.paginator;

      this.toastr.success('Schedule Deleted Successfully');
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

}
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IEvaluation } from 'src/app/evaluation/evaluation';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EvaluationService {

  private _url: string = environment.api_url;
  constructor(private _http: HttpClient) { }

  addEvaluation(evaluation){
    // let httpheaders = new HttpHeaders().set('Content-Type','application/Json');
    // let options = {
    //   headers: httpheaders
    // };
    return this._http.post<any>(this._url+'/addEvaluation.php',evaluation);
  }

  getEvaluation(id:number){
    return this._http.get<any>(this._url+'/eachEvaluation.php?id='+id);
  }

  updateEvaluation(evaluation){
    return this._http.post(this._url+'/updateEvaluation.php', evaluation);
  }
}

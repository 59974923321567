import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IJurySchedule } from 'src/app/juryschedule/juryschedule';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JuryscheduleService {

  private _url: string = environment.api_url;

  constructor(private _http: HttpClient) { }

  getjuryschedule(juryscheduleid:number){
    return this._http.get<IJurySchedule[]>(this._url+'/juryScheduleList.php?juryscheduleid='+juryscheduleid);
  }
}

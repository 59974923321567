import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ICompany } from 'src/app/company/company';
import { IEvaluation } from 'src/app/evaluation/evaluation';
import { BusinessunitlistService } from 'src/app/services/businessunit/businessunitlist.service';
import { CompanylistService } from 'src/app/services/company/companylist.service';
import { EvaluationlistService } from 'src/app/services/evaluation/evaluationlist.service';
import { ReportService } from 'src/app/services/reports/report.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-plantyearlyaverage',
  templateUrl: './plantyearlyaverage.component.html',
  styleUrls: ['./plantyearlyaverage.component.css']
})
export class PlantyearlyaverageComponent implements OnInit {

  evaluation: IEvaluation[];
  _evaluation: IEvaluation[];
  company: ICompany[];
  _company: ICompany[];
  businessunit: any;
  _businessunit: any;
  plant: any;
  _plant: any;
  disableReportBtn: boolean = false;
  shreport: boolean = false;
  filBusinessUnitName: any;
  filEvaluationName: any;
  filFromDate: any;
  filToDate: any;
  filPlantName: any;
  filCompanyName: any;
  report_data: any;
  disrec: boolean = false;
  report_fields: any;
  PDFReport: any;
  PDFevaluation_id: any;
  PDFcompany_id: any;
  PDFbusinessunit_id: any;
  PDFplant_id: any;
  PDFfromDate: any;
  PDFtoDate: any;

  url: string = environment.api_url;

  constructor(private evaluationservice: EvaluationlistService, private businessunitlistService: BusinessunitlistService, private reportapi: ReportService, private companylistService: CompanylistService) { }

  @ViewChild('evaluationSearch') evaluationSearchInput: ElementRef;
  @ViewChild('plantSearch') plantSearchInput: ElementRef;
  @ViewChild('businessUnitSearch') businessUnitSearchInput: ElementRef;
  @ViewChild('companySearch') companySearchInput: ElementRef;

  @ViewChild('reportForm') reportForm: NgForm;
  @ViewChild('resetbusinessunit') resetbusinessunit: ElementRef;

  ngOnInit(): void {

    this.companylistService.companyData().subscribe((data: ICompany[]) => {
      this.company = data;
      this._company = data;
    });

    // this.businessunitlistService.businessUnitData().subscribe((data: IBusinessUnit[]) => {
    //   this.businessunit = data;
    //   this._businessunit = data;
    // });

  }

  onfocusEvaluation() {
    this.evaluationSearchInput.nativeElement.focus();
  }
  onInputChangeEvaluation() {
    const searchInput = this.evaluationSearchInput.nativeElement.value ? this.evaluationSearchInput.nativeElement.value.toLowerCase() : '';
    this.evaluation = this._evaluation.filter(c_data => {
      const c_name: any = c_data.evaluationName.toLowerCase();
      return c_name.indexOf(searchInput) > -1;
    })
  }

  onfocusCompany() {
    this.companySearchInput.nativeElement.focus();
  }
  onInputChangeCompany() {
    const searchInput = this.companySearchInput.nativeElement.value ? this.companySearchInput.nativeElement.value.toLowerCase() : '';
    this.company = this._company.filter(c_data => {
      const c_name: any = c_data.companyName.toLowerCase();
      return c_name.indexOf(searchInput) > -1;
    })
  }

  onfocusBusinessUnit() {
    this.businessUnitSearchInput.nativeElement.focus();
  }
  onInputChangeBusinessUnit() {
    const searchInput = this.businessUnitSearchInput.nativeElement.value ? this.businessUnitSearchInput.nativeElement.value.toLowerCase() : '';
    this.businessunit = this._businessunit.filter(c_data => {
      const c_name: any = c_data.businessUnitName.toLowerCase();
      return c_name.indexOf(searchInput) > -1;
    })
  }

  onfocusPlant() {
    this.plantSearchInput.nativeElement.focus();
  }
  onInputChangePlant() {
    const searchInput = this.plantSearchInput.nativeElement.value ? this.plantSearchInput.nativeElement.value.toLowerCase() : '';
    this.plant = this._plant.filter(c_data => {
      const c_name: any = c_data.plantName.toLowerCase();
      return c_name.indexOf(searchInput) > -1;
    })
  }

  chgCompany(company_id) {
    if(company_id != "") {
      const formData = new FormData();
      formData.append('company_id', company_id);
      this.reportapi.businessUnits(formData).subscribe((data: any) => {
        if(data.other['msz']) {
          this.businessunit = data.dataList;
          this._businessunit = data.dataList;
        } else {
          this.businessunit = [];
          this._businessunit = [];
        }
      });
    } else {
      this.businessunit = [];
      this._businessunit = [];
      this.plant = [];
      this._plant = [];
      this.reportForm.control.get('businessUnit').reset();
      this.reportForm.control.get('plant').reset();
    }
  }

  chgBusinessUnit(businessunit_id) {
    if(businessunit_id != "") {
      const formData = new FormData();
      formData.append('businessunit_id', businessunit_id);
      this.reportapi.plants(formData).subscribe((data: any) => {
        if(data.other['msz']) {
          this.plant = data.dataList;
          this._plant = data.dataList;
        } else {
          this.plant = [];
          this._plant = [];
        }
      });

      const formDataa = new FormData();
      formDataa.append('pa_plant_id', businessunit_id);
      this.reportapi.pav_evaluationList(formDataa).subscribe((data: any) => {
        if(data.other['msz']) {
          this.evaluation = data.dataList;
          this._evaluation = data.dataList;
        } else {
          this.evaluation = [];
          this._evaluation = [];
        }
      });

    } else {
      this.plant = [];
      this._plant = [];
      this.reportForm.control.get('plant').reset();

      this.evaluation = [];
      this._evaluation = [];
      this.reportForm.control.get('evaluation').reset();
    }
  }

  chgEvaluation(evaluation_id) {
    if(evaluation_id == "") {
      this.reportForm.control.get('evaluation').reset();
    }
  }

  submitReport(reportForm: NgForm){
    this.disableReportBtn = true;

    this.reportapi.plantYearlyAverageReport(reportForm.form.value).subscribe((data) => {
      this.filBusinessUnitName = data.businessUnitName;
      this.filEvaluationName = data.evaluationName;
      this.filPlantName = data.plantName;
      this.filCompanyName = data.companyName;
      this.filFromDate = data.rfromDate;
      this.filToDate = data.rtoDate;
      this.PDFevaluation_id = data.field_data['evaluationId'];
      this.PDFcompany_id = data.field_data['companyId'];
      this.PDFbusinessunit_id = data.field_data['businessUnitId'];
      this.PDFplant_id = data.field_data['plantId'];
      this.PDFfromDate = data.field_data['fromDate'];
      this.PDFtoDate = data.field_data['toDate'];
      this.report_data = data.dataRecords;
      this.report_fields = new Array(data.report_fields);

      if(data.records) {
        this.disrec = true;
      } else {
        this.disrec = false;
      }

      this.disableReportBtn = false;
      this.shreport = true;
    });
  }

}

<div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between">
       <div class="iq-header-title">
          <h4 class="card-title">{{ pageAct }} Plant</h4>
       </div>
       <div class="pull-right add">
         <a routerLink="/Plant_List" mat-stroked-button color="primary">Plant List</a>
      </div>
    </div>
    <div class="iq-card-body">
      <strong class="red">* Fields are Required</strong>
       <div class="form-horizontal" [formGroup]="plantForm">

          <div class="form-group row" [ngClass]="{'has-error': (errbusinessUnitId.touched || errbusinessUnitId.dirty) && errbusinessUnitId.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="pwd1">Business Unit <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline" (click)="onfocus()">
                  <mat-label>Business Unit</mat-label>
                  <mat-select formControlName="businessUnitId" (ngModelChange)="chgBusinessUnit()">
                     <input type="text" autocomplete="off" class="select-search" matInput placeholder="Search Business Unit" #businessUnitSearch (input)="onInputChange()" />
                     <mat-option value="">-- Select Business Unit --</mat-option>
                     <mat-optgroup *ngFor="let group of businessunit" label="{{group.companyName}}">
                        <mat-option *ngFor="let item of group.items" [value]="item.businessUnitId">
                           {{item.businessUnitName}}
                        </mat-option>
                     </mat-optgroup>
                  </mat-select>
                  <mat-hint *ngIf="(errbusinessUnitId.touched || errbusinessUnitId.dirty) && errbusinessUnitId.invalid"><span *ngIf="errbusinessUnitId.errors?.required" class="error-msg">Business Unit is Required</span></mat-hint>
                </mat-form-field>
            </div>
         </div>

          <div class="form-group row" [ngClass]="{'has-error': (errplantName.touched || errplantName.dirty) && errplantName.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="department_name">Plant Name <sup class="red">*</sup></label>
            <div class="col-sm-5">
              <mat-form-field class="example-full-width" appearance="outline">
                 <mat-label>Plant Name</mat-label>
                 <input matInput placeholder="Plant Name" formControlName="plantName" >
                 <mat-hint *ngIf="(errplantName.touched || errplantName.dirty) && errplantName.invalid"><span *ngIf="errplantName.errors?.required" class="error-msg">Plant Name is Required</span></mat-hint>
               </mat-form-field>
            </div>
         </div>

         <div class="form-group row" [ngClass]="{'has-error': (errplantCode.touched || errplantCode.dirty) && errplantCode.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="department_name">Plant Code <sup class="red">*</sup></label>
            <div class="col-sm-5">
              <mat-form-field class="example-full-width" appearance="outline">
                 <mat-label>Plant Code</mat-label>
                 <input matInput placeholder="Plant Code" formControlName="plantCode" >
                 <mat-hint *ngIf="(errplantCode.touched || errplantCode.dirty) && errplantCode.invalid"><span *ngIf="errplantCode.errors?.required" class="error-msg">Plant Code is Required</span></mat-hint>
                 <mat-hint *ngIf="(errplantCode.touched || errplantCode.dirty) && errplantCode.errors?.pattern"><span class="error-msg">Plant Code can only be Alpha Numeric.</span></mat-hint>
                 <mat-hint *ngIf="errplantCode.pending"><span class="success-msg">Plant Code is Validating...</span></mat-hint>
                 <mat-hint *ngIf="errplantCode.errors?.codeExists"><span class="error-msg">Plant Code is already exists.</span></mat-hint>
               </mat-form-field>
            </div>
         </div>

          <div class="form-group row" [ngClass]="{'has-error': (errplantLocation.touched || errplantLocation.dirty) && errplantLocation.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="department_name">Location <sup class="red">*</sup></label>
            <div class="col-sm-5">
              <mat-form-field class="example-full-width" appearance="outline">
                 <mat-label>Location</mat-label>
                 <input matInput placeholder="Location" formControlName="plantLocation" >
                 <mat-hint *ngIf="(errplantLocation.touched || errplantLocation.dirty) && errplantLocation.invalid"><span *ngIf="errplantLocation.errors?.required" class="error-msg">Location is Required</span></mat-hint>
               </mat-form-field>
            </div>
         </div>
          
          <div class="form-group row">
              <div class="col-md-7 text-right">
                <input type="hidden" *ngIf="hiddenId" value="" formControlName="plantId" />
                <button type="button" [disabled]="!plantForm.valid"  (click)="submitPlant(pageAct)"  mat-raised-button color="primary">Save</button>
            </div>
          </div>
       </div>
    </div>
 </div>
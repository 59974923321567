import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../services/login/login.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  logDetails: FormGroup;
  message: any = "";
  token: any;
  errorMSG : boolean = false;
  hide: any = false;
  
  constructor(private fb: FormBuilder,private loginService: LoginService,private router:Router) {
    
   }
 
  ngOnInit() {
    this.token = window.localStorage.getItem('token');
    if(this.token){
      this.router.navigate(['/Dashboard']);
    }
    
    this.logDetails = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', Validators.required]
    });
  }
  login()
  {
    if(this.logDetails.invalid)
    {
      return;
    }

    const logDetails = {
      username: this.logDetails.controls.username.value,
      password: this.logDetails.controls.password.value
    };

    this.loginService.userlogin(logDetails)
    .subscribe((data: any) => {
        this.message = data.message;
        this.errorMSG = true;
        if(data.token) {
          window.localStorage.setItem('token', data.token);
          window.localStorage.setItem('jsch_j_id', '');
          window.localStorage.setItem('tokenact', 'admin');
          window.localStorage.setItem('admin_access', data.access);
          this.router.navigate(['/Dashboard']);
        }
    }, (error) => {
      this.message = "Invalid Details";
    });
  }

}

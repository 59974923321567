<div class="clred pull-right" mat-dialog-close><strong ><i class="las la-times"></i></strong></div>

<h4 class="hdng">Evaluation Request</h4>


<mat-dialog-actions>
    <form #requestForm="ngForm" (ngSubmit)="submitRequest(requestForm);">
        <input type="hidden" name="jury_schedule_id" [(ngModel)]="data.jury_schedule_id" />
        <input type="hidden" name="employee_id" [(ngModel)]="data.empId" />
    <div class="session-popup">
    <div class="form-group row">
        <div class="col-sm-12">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Request Reason</mat-label>
                <textarea matInput placeholder="Reason for request" name="request_reason" [ngModel]="" style="resize:none; height:100px;"></textarea>
            </mat-form-field>
        </div>
    </div>
    
    <div class="btn-grp">
        <div class="pull-left add">
            <button type="submit" mat-button mat-raised-button color="primary" [disabled]="disableReqBtn">Send Request</button>
            <!-- <span class="info"><i class="las la-info-circle"></i></span> -->
        </div>
    </div>
   
</div>
</form>
   
</mat-dialog-actions>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from '../services/dashboard/dashboard.service';
import { HeadermessageService } from '../services/dashboard/headermessage.service';
import { MessageService } from '../services/JuryEvaluation/message.service';
import { JurylogoutService } from '../services/jurylogout/jurylogout.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  logDetails: any;
  pageHeading: string = "Dashboard";
  juryScheduleID: any;
  profileName: any;
  profileMobile: any;

  isAdmin: boolean;
  isJury: boolean;
  tokenact: any;
  pageHead: string;

  constructor(private router: Router, private JurylogoutService: JurylogoutService, private dashboardapi: DashboardService, private headerData: HeadermessageService, private messageData: MessageService) {
    router.events.subscribe(val => {
      let pageName = this.router.url.split("/");
      let pn = pageName[1].replace(/_/g, ' ');
      this.pageHeading = pn.replace('List', '');
    })
  }
  ngOnInit(): void {

    this.tokenact = window.localStorage.getItem('tokenact');

    this.headerData.mszHead$.subscribe(
      message => {
        this.pageHead = message;
      }
    );

    this.messageData.mszAdminMobile$.subscribe(
      message => {
        this.profileMobile = message;
      }
    );

    if(this.tokenact == 'admin') {
      const formData = new FormData();
      formData.append('admin_id', '1');
      this.dashboardapi.getProfile(formData).subscribe((data) => {
          this.isAdmin = true;
          this.isJury = false;
          this.profileName = data.adminName;
          this.profileMobile = data.adminPhone;
      });
    } else {
      this.juryScheduleID = window.localStorage.getItem('jsch_j_id');
      const formData = new FormData();
      formData.append('jury_schedule_id', this.juryScheduleID);
      this.dashboardapi.getJuryProfile(formData).subscribe((data) => {
          this.profileName = data.juryName;
          this.profileMobile = data.juryPhone;
          this.isAdmin = false;
          this.isJury = true;
      });
    }
    
  }

  logOut() {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('jtoken');
    if(window.localStorage.getItem('tokenact') == "admin")
    {
      this.router.navigate(['']);
    } else if(window.localStorage.getItem('tokenact') == "jury")
    {
      const logDetails = {
        schedule_jury_id: window.localStorage.getItem('jsch_j_id')
      };
  
      this.JurylogoutService.jurylogout(logDetails)
      .subscribe((data: any) => {
          if(data.message == "Successful Logout") {
            this.router.navigate(['/JuryLogin']);
          }
      });
      
    }
  }

}

<!-- loader Start -->
<!-- <div id="loading">
         
</div> -->
<!-- loader END -->
  <!-- Sign in Start -->
  <section class="sign-in-page">
    <!-- <div class="top-logo"> <img src="assets/images/logo2.png"></div> -->
      <div class="container p-0 align-self-center">
          <div class="row no-gutters s-page-bg">
              <div class="col-md-7"></div>
              <div class="col-md-5 s-page-form">
                  <div class="">
                     <div class="text-center logo"> <img src="assets/images/logo-amaron.png"></div>
                     <h2>SAMEEKSHA</h2>
                     <h4>Jury Login</h4>
                      <form [formGroup]="logDetails" (ngSubmit)="login()" class="mt-4">
                          <div [ngStyle]='{color:"red", padding:"5px 5px"}' *ngIf="errorMSG"><strong>Login Failed!! </strong>{{ message }}</div>
                          <div class="form-group inp-txt">
                            <i class="fa fa-user icon"> </i>
                              <input type="text" formControlName="username" class="form-control mb-3 log-inp" placeholder="User Name">
                          </div>
                          <div class="form-group inp-txt">
                            <i class="fa fa-lock icon"> </i>
                              <input type="password" [type]="hide ? 'text':'password'" formControlName="password" class="form-control mb-3 log-inp" placeholder="Password">
                              <i class="material-icons eyeicon icon" matSuffix (click)="hide = !hide" >
                                {{ hide ? 'visibility_off':'visibility' }}
                              </i>
                          </div>
                          <div class="d-inline-block mt-3 px-3 w-100">
                            <a routerLink="/" class="float-left w-10 jl"><i class="fa fa-arrow-left"></i> Back to Admin</a>
                            <button type="submit" [disabled]="!logDetails.valid" class="btn btn-primary float-right w-10">Login</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
      <!-- <div class="btm-imgs"><img src="assets/images/btm-imgs.jpg"></div> -->
  </section>
  <router-outlet></router-outlet>
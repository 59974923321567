<div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between">
       <div class="iq-header-title">
          <h4 class="card-title">Schedule List</h4>
       </div>
       <!-- <div class="pull-right add">
          <a routerLink="/Schedule" class="btn btn-primary custom-btn">Add New Schedule</a>
       </div> -->
    </div>
    <div class="iq-card-body company-list">
    <div class="form-group row">
        <div class="col-sm-12 col-lg-12">
            <div class="mat-elevation-z8">
                <mat-form-field appearance="outline" class="filter-dt">
                    <mat-label>Filter Data</mat-label>
                    <input (keyup)="applyFilter($event.target.value)" matInput placeholder="Filter Data">
                    <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
                    <!-- <mat-hint>Hint</mat-hint> -->
                  </mat-form-field>

                <table mat-table matSort [dataSource]="dataSource">
              
                  <!-- Position Column -->
                  <ng-container matColumnDef="sno">
                    <th mat-header-cell *matHeaderCellDef> # </th>
                    <td mat-cell *matCellDef="let element; let i = index;"> {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (i + 1) }}. </td>
                  </ng-container>
              
                  <!-- Name Column -->
                  <ng-container matColumnDef="evaluationId">
                    <th mat-header-cell *matHeaderCellDef> Evaluation </th>
                    <td mat-cell *matCellDef="let element"> {{element.evaluationId}} </td>
                  </ng-container>
              
                  <!-- Weight Column -->
                  <ng-container matColumnDef="plantId">
                    <th mat-header-cell *matHeaderCellDef> Plant </th>
                    <td mat-cell *matCellDef="let element"> {{element.plantId}} </td>
                  </ng-container>
              
                  <!-- Symbol Column -->
                  <ng-container matColumnDef="departmentId">
                    <th mat-header-cell *matHeaderCellDef> Department </th>
                    <td mat-cell *matCellDef="let element"> {{element.departmentId}} </td>
                  </ng-container>

                  <!-- Symbol Column -->
                  <ng-container matColumnDef="eventDate">
                    <th mat-header-cell *matHeaderCellDef> Event Date </th>
                    <td mat-cell *matCellDef="let element"> {{element.eventDate}} </td>
                  </ng-container>
                  
                  <!-- Symbol Column -->
                  <ng-container matColumnDef="eventCode">
                    <th mat-header-cell *matHeaderCellDef> Event Code </th>
                    <td mat-cell *matCellDef="let element"> {{element.eventCode}} </td>
                  </ng-container>

                  <!-- Symbol Column -->
                  <ng-container matColumnDef="evaluationType">
                    <th mat-header-cell *matHeaderCellDef> Evaluation Type </th>
                    <td mat-cell *matCellDef="let element"> {{element.evaluationType}} </td>
                  </ng-container>

                  <ng-container matColumnDef="scheduleId">
                    <th mat-header-cell *matHeaderCellDef align="center" style="text-align: center;">Actions </th>
                    <td mat-cell *matCellDef="let element" align="center">
                        <span class="" style="font-size: 18px; color: green; position: relative; top: -4px; left: 8px; cursor:pointer;" routerLink="/JuryPassword/{{ element.scheduleId }}"><i class="las la-lock"></i></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span class="material-icons icon-dt shview" title="Preview" routerLink="/ScheduleView/{{ element.scheduleId }}">
                          preview
                        </span>&nbsp;&nbsp;&nbsp;&nbsp;
                        <span class="material-icons icon-dt" title="Edit" routerLink="/Schedule/{{ element.scheduleId }}">
                          edit
                        </span>&nbsp;&nbsp;&nbsp;&nbsp;
                        <span mwlConfirmationPopover [popoverTitle]="popoverTitle" [popoverMessage]="popoverMessage" placement="left" (confirm)="delete(element)" (cancel)="cancelClicked=true"  class="material-icons text-danger icon-dt" title="Delete">
                          delete_outline
                        </span>                        
                    </td>
                  </ng-container>
                  
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <div *ngIf="dataSource.data.length === 0">
                    No Records Found!
                </div>
              
                <mat-paginator #paginatorRef [pageSize]="50" [pageSizeOptions]="[50, 100, 150]" showFirstLastButtons></mat-paginator>
              </div>

            <!-- <table class="table">
                <tr>
                    <th>#</th>
                    <th class="text-left">Evaluation</th>
                    <th class="text-left">Plant</th>
                    <th class="text-left">Department</th>
                    <th class="text-left">Event Code</th>
                    <th class="text-center">Evaluation Type</th>
                    <th class="text-center">Status</th>
                </tr>
                <tr *ngFor="let schedule of schedules; let sch = index">
                    <td>{{ sch+1 }}.</td>
                    <td class="text-left">{{ schedule.evaluationId }}</td>
                    <td class="text-left">{{ schedule.plantId }}</td>
                    <td class="text-left">{{ schedule.departmentId }}</td>
                    <td class="text-left">{{ schedule.eventCode }}</td>
                    <td class="text-center">{{ schedule.evaluationType }}</td>
                    <td class="text-center">
                        <div class="custom-control custom-switch custom-switch-color custom-control-inline">
                            <input type="checkbox" class="custom-control-input bg-info" id="customSwitch06" checked="">
                            <label class="custom-control-label" for="customSwitch06"></label>
                            <a routerLink="/ScheduleView/{{ schedule.scheduleId }}" title="View Schedule" class="edit"><i class="lar la-eye"></i></a>
                            <a routerLink="/Schedule/{{ schedule.scheduleId }}" title="Edit Schedule" class="edit"><i class="lar la-edit"></i></a>
                            <a (click)=delete(schedule) class="delete" title="Delete Schedule"><i class="lar la-trash-alt"></i></a>
                        </div>
                    </td>
                </tr>
            </table> -->
        </div>
    </div>
                                  
    </div>
 </div>
import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  isHome: boolean;
  isLogin: boolean;

  constructor(private location: Location, private router: Router){
    router.events.subscribe(val => {
      if(location.path() == "" || location.path() == "/Login" || location.path() == "/JuryLogin")
      {
        //alert(location.path());
        this.isHome = true;
        this.isLogin = false;
      } else if(location.path() != "" || location.path() != "/Login" || location.path() != "/JuryLogin") {
        //alert(location.path());
        this.isHome = false;
        this.isLogin = true;
      }
    })
  }
  ngOnInit(): void {
    //console.log(this.isHome);
  }

}

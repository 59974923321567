import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IScheduleView } from 'src/app/scheduleview/scheduleview';

@Injectable({
  providedIn: 'root'
})
export class ScheduleviewlistService {

  private _url: string = environment.api_url;
  constructor(private _http: HttpClient) { }

  scheduleViewData(id:number): Observable<IScheduleView[]>{
    return this._http.get<IScheduleView[]>(this._url+'/scheduleViewList.php?id='+id);
  }

  deleteScheduleView(id){
    return this._http.delete<IScheduleView>(this._url+'/deleteScheduleView.php?id='+id)
  }
}

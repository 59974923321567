<div class="iq-card">
    <div class="iq-card-header d-flex justify-content-between">
       <div class="iq-header-title">
          <h4 class="card-title">{{ pageAct }} Evaluation</h4>
       </div>
       <div class="pull-right add">
         <a routerLink="/Evaluation_List" mat-stroked-button color="primary">Evaluation List</a>
      </div>
    </div>
    <div class="iq-card-body">
      <strong class="red">* Fields are Required</strong>
       <div class="form-horizontal" [formGroup]="evaluationForm">

          <div class="form-group row" [ngClass]="{'has-error': (evaluationName.touched || evaluationName.dirty) && evaluationName.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="department_name">Evaluation Name <sup class="red">*</sup></label>
            <div class="col-sm-5">
              <mat-form-field class="example-full-width" appearance="outline">
                 <mat-label>Evaluation Name</mat-label>
                 <input matInput placeholder="Evaluation Name" formControlName="evaluationName" >
                 <mat-hint *ngIf="(evaluationName.touched || evaluationName.dirty) && evaluationName.invalid"><span *ngIf="evaluationName.errors?.required" class="error-msg">Evaluation Name is Required</span></mat-hint>
               </mat-form-field>
            </div>
         </div>

          <div class="form-group row" [ngClass]="{'has-error': (evaluationCode.touched || evaluationCode.dirty) && evaluationCode.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="department_name">Evaluation Code <sup class="red">*</sup></label>
            <div class="col-sm-5">
              <mat-form-field class="example-full-width" appearance="outline">
                 <mat-label>Evaluation Code</mat-label>
                 <input matInput placeholder="Evaluation Code" formControlName="evaluationCode" >
                 <mat-hint *ngIf="(evaluationCode.touched || evaluationCode.dirty) && evaluationCode.invalid"><span *ngIf="evaluationCode.errors?.required" class="error-msg">Evaluation Code is Required</span></mat-hint>
               </mat-form-field>
            </div>
         </div>


          <div class="form-group row" [ngClass]="{'has-error': (evaluationLogo.touched || evaluationLogo.dirty) && evaluationLogo.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="errupload_excel">Upload Logo <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <img [src]="imgSrcLogo" (click)='uploadlogo.click()' width="150" />
              <div class="input-group mb-3">
               <div class="custom-file">
                 <input type="file" class="custom-file-input" id="inputGroupFile01" #uploadlogo (change)="onSelectLogo($event)">
                 <label class="custom-file-label" for="inputGroupFile01">{{selectedFileNameLogo != '' ? selectedFileNameLogo : 'Choose file'}}</label>
               </div>
                  <span class="help-block" *ngIf="(evaluationLogo.touched || evaluationLogo.dirty) && evaluationLogo.invalid">
                     <span *ngIf="evaluationLogo.errors?.required">Logo is Required</span>
                  </span>
             </div>
            </div>
         </div>

          <div class="form-group row" [ngClass]="{'has-error': (evaluationType.touched || evaluationType.dirty) && evaluationType.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="evaluation_type">Evaluation Type <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>-- Select Evaluation Type --</mat-label>
                  <mat-select formControlName="evaluationType">
                     <mat-option value="Individual">Individual</mat-option>
                     <mat-option value="Team">Team</mat-option>
                     <mat-option value="Plant">Plant</mat-option>
                  </mat-select>
                  <mat-hint *ngIf="(evaluationType.touched || evaluationType.dirty) && evaluationType.invalid"><span *ngIf="evaluationType.errors?.required" class="error-msg">Evaluation Type is Required</span></mat-hint>
                </mat-form-field>
           </div>
         </div>

          <div class="form-group row" [ngClass]="{'has-error': (evaluationGuidline.touched || evaluationGuidline.dirty) && evaluationGuidline.invalid}">
            <label class="control-label col-sm-2 align-self-center mb-0" for="errupload_excel">Upload Guidlines <sup class="red">*</sup></label>
            <div class="col-sm-5">
               <!-- <img [src]="imgSrcGuidline" (click)='uploadguidline.click()' width="150" /> -->
              <div class="input-group mb-3">
               <div class="custom-file">
                 <input type="file" class="custom-file-input" id="inputGroupFile01" #uploadguidline (change)="onSelectGuidline($event)">
                 <label class="custom-file-label" for="inputGroupFile01">{{selectedFileNameGuidline != '' ? selectedFileNameGuidline : 'Choose file'}}</label>
               </div>
                  <span class="help-block" *ngIf="(evaluationGuidline.touched || evaluationGuidline.dirty) && evaluationGuidline.invalid">
                     <span *ngIf="evaluationGuidline.errors?.required">Guidlines is Required</span>
                  </span>
             </div>
            </div>
         </div>

          <div class="form-group row">
              <div class="col-md-7 text-right">
               <input type="hidden" *ngIf="hiddenId" value="" id="evaluationId" />
                <button type="button" [disabled]="!evaluationForm.valid"  (click)="submitEvaluation(pageAct)"  mat-raised-button color="primary">Save</button>
            </div>
          </div>
       </div>
    </div>
 </div>
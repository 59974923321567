import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IQuestion } from 'src/app/question/question';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  private _url: string = environment.api_url;
  constructor(private _http: HttpClient) { }

  addQuestion(question: IQuestion):Observable<IQuestion>{
    let httpheaders = new HttpHeaders().set('Content-Type','application/Json');
    let options = {
      headers: httpheaders
    };
    return this._http.post<IQuestion>(this._url+'/addQuestion.php',question, options);
  }

  getQuestion(id:number){
    return this._http.get<IQuestion[]>(this._url+'/eachQuestion.php?id='+id);
  }

  updateQuestion(question: IQuestion){
    return this._http.put(this._url+'/updateQuestion.php?id='+question.questionId, question);
  }
}

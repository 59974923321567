import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { JurypopupComponent } from '../jurypopup/jurypopup.component';
import { DashboardService } from '../services/dashboard/dashboard.service';
import { HeadermessageService } from '../services/dashboard/headermessage.service';
import { JuryEvaluationService } from '../services/JuryEvaluation/jury-evaluation.service';
import { MenuService } from '../services/menu/menu.service';

@Component({
  selector: 'app-jurydashboard',
  templateUrl: './jurydashboard.component.html',
  styleUrls: ['./jurydashboard.component.css']
})
export class JurydashboardComponent implements OnInit {

  showSpinner: boolean = true;
  juryScheduleID: any;

  constructor(private router:Router, 
              public dialog: MatDialog, 
              private evaluationapi: JuryEvaluationService,
              private dashboardapi: DashboardService,
              private messageMenu: MenuService,
              private headerData: HeadermessageService) { }

  jtoken: any;

  public menuDisable: boolean = false;
  totalSchedules: number = 0;
  completedSchedules: number = 0;
  pendingSchedules: number = 0;
  pageHead: string;

  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.jtoken = window.localStorage.getItem('jtoken');
    this.juryScheduleID = window.localStorage.getItem('jsch_j_id');
  
    if(!this.jtoken){
      this.router.navigate(['/JuryLogin']);
    }
    this.headerData.sendMessage('Manage Dashboard');

    const formData = new FormData();
    formData.append('jury_schedule_id', this.juryScheduleID);

    this.dashboardapi.getDashboard(formData).subscribe((data) => {
      this.totalSchedules = data.totalSchedules;
      this.completedSchedules = data.completedSchedules;
      this.pendingSchedules = data.pendingSchedules;
      //console.log(data);
    });

    this.evaluationapi.getDashboard(formData).subscribe((data) => {
      
      this.messageMenu.sendMessage(data.ResultDisplay);

      this.messageMenu.mszMenu$.subscribe(
        message => {
          this.menuDisable = message;
        }
      );
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.width = "50%";
      dialogConfig.height = "62%";
      dialogConfig.data = { dashboardData : data };
      this.dialog.open(JurypopupComponent,dialogConfig);
      
      setTimeout(() => {
        this.showSpinner = false;
      }, 1500);
      
    });

    setTimeout(() => {
      this.showSpinner = false;
    }, 1500);

  }

}

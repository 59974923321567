import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IQuestion } from 'src/app/question/question';
import { IOptQuestion } from 'src/app/question/opt_question';

@Injectable({
  providedIn: 'root'
})
export class QuestionlistService {

  private _url: string = environment.api_url;
  constructor(private _http: HttpClient) { }
  
  questionData(): Observable<IQuestion[]>{
    return this._http.get<IQuestion[]>(this._url+'/questionList.php');
  }

  optQuestionData(): Observable<IOptQuestion[]>{
    return this._http.get<IOptQuestion[]>(this._url+'/optQuestionList.php');
  }

  deleteQuestion(id){
    return this._http.delete<IQuestion>(this._url+'/deleteQuestion.php?id='+id)
  }
}

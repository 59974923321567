import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IEmployee } from 'src/app/employee/employee';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  private _url: string = environment.api_url;
  constructor(private _http: HttpClient) { }

  addTeam(team: IEmployee): Observable<IEmployee>{
    let httpheaders = new HttpHeaders().set('Content-Type','application/Json');
    let options = {
      headers: httpheaders
    };
    return this._http.post<IEmployee>(this._url+'/addTeam.php',team, options);
  }

  getTeam(id:number){
    return this._http.get<IEmployee[]>(this._url+'/eachTeam.php?id='+id);
  }

  updateTeam(team: IEmployee){
    return this._http.put(this._url+'/updateTeam.php?id='+team.excelUploadId, team);
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IOptPlant } from '../plant/opt_plant';
import { Router, ActivatedRoute } from '@angular/router';
import { DepartmentService } from '../services/department/department.service';
import { PlantlistService } from '../services/plant/plantlist.service';
import { CompanylistService } from '../services/company/companylist.service';
import { ICompany } from '../company/company';
import { ToastrService } from 'ngx-toastr';
import { UserValidators } from '../services/custom_validation';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.css']
})
export class DepartmentComponent implements OnInit {

  message: any;
  pageAct: string = "Create";
  hiddenId: boolean = false;
  departmentForm: FormGroup;
  plant: IOptPlant[];
  _plant: IOptPlant[];
  companies: ICompany[];
  _companies: ICompany[];
  nodata: boolean = false;
  plantselected: number = 0;
  constructor(private fb: FormBuilder,
              private router: Router,
              private departmentapi: DepartmentService,
              private route: ActivatedRoute,
              private plantservice: PlantlistService, 
              private companylistService: CompanylistService, 
              private toastr: ToastrService,
              private service: UserValidators) { }

  token: any;

  @ViewChild('companySearch') companySearchInput: ElementRef;
  @ViewChild('plantSearch') plantSearchInput: ElementRef;

  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.token = window.localStorage.getItem('token');
    if(!this.token){
      this.router.navigate(['']);
    }
    
    this.companylistService.companyData().subscribe((data: ICompany[]) => {
      this.companies = data;
      this._companies = data;
    });

    if(this.route.snapshot.paramMap.get('id'))
    {
      let id = parseInt(this.route.snapshot.paramMap.get('id'));
      this.pageAct = "Update";
      this.hiddenId = true;
      this.departmentapi.getDepartment(id).subscribe((data: any) => {
        this.departmentForm.patchValue(data);
        this.plantservice.optPlantData(data.companyId).subscribe((data: IOptPlant[]) => {
          this.plant = data;
          this._plant = data;
        }, (error) => {
          this.plant = [];
          this._plant = [];
        });
      })

      this.departmentForm = this.fb.group({
        departmentId: [''],
        companyId: ['', [Validators.required]],
        plantId: ['', [Validators.required]],
        departmentName: ['', [Validators.required]],
        departmentCode: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9]*$')], this.service.departmentEditValidator(id)]
      });
      
    } else {
      this.pageAct = "Create";
      this.hiddenId = false;

      this.departmentForm = this.fb.group({
        companyId: ['', [Validators.required]],
        plantId: ['', [Validators.required]],
        departmentName: ['', [Validators.required]],
        departmentCode: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9]*$')], this.service.DepartmentValidator()]
      });

    }

  }

  get errcompanyId() { return this.departmentForm.get('companyId'); }
  get errplantId() { return this.departmentForm.get('plantId'); }
  get errdepartmentName() { return this.departmentForm.get('departmentName'); }
  get errdepartmentCode() { return this.departmentForm.get('departmentCode'); }

  onfocusCompany() {
    this.companySearchInput.nativeElement.focus();
  }
  onInputChangeCompany() {
    const searchInput = this.companySearchInput.nativeElement.value ? this.companySearchInput.nativeElement.value.toLowerCase() : '';
    this.companies = this._companies.filter(c_data => {
      const c_name: any = c_data.companyName.toLowerCase();
      return c_name.indexOf(searchInput) > -1;
    })
  }

  chgPlant() {
    this.departmentForm.get('departmentCode').setValue('');
  }

  onfocusPlant() {
    this.plantSearchInput.nativeElement.focus();
  }
  onInputChangePlant() {
    const searchInputPlant = this.plantSearchInput.nativeElement.value ? this.plantSearchInput.nativeElement.value.toLowerCase() : '';
    this.plant = this._plant.filter(bu_data => {
      return (bu_data.items.some(i => i.plantName.toLowerCase().indexOf(searchInputPlant.toLowerCase()) > -1));
    })
  }

  getbusiUnit(event: any){
    this.departmentForm.get('departmentCode').setValue('');
    this.plantservice.optPlantData(event).subscribe((data: IOptPlant[]) => {
      this.plant = data;
      this._plant = data;
    }, (error) => {
      this.plant = [];
      this._plant = [];
    });
  }

  submitDepartment(act){
    if(this.departmentForm.invalid)
    {
      return;
    }
    if(act == "Create") {
      this.departmentapi.addDepartment(this.departmentForm.value)
      .subscribe(data => {
        this.router.navigate(['/Department_List']);
        this.toastr.success('Department Added Successfully');
      })
    }
    if(act == "Update") {
      this.departmentapi.updateDepartment(this.departmentForm.value).subscribe(() => {
        this.router.navigate(['/Department_List']);
        this.toastr.success('Department Updated Successfully');
      })
    }
  }

}


<h4 class="hdng">Evaluation Confirmation</h4>
<!-- <mat-card>Simple card</mat-card> -->

<!-- <mat-grid-list cols="3" rowHeight="2:1">
    <mat-grid-tile>1</mat-grid-tile>
    <mat-grid-tile>2</mat-grid-tile>
    <mat-grid-tile>3</mat-grid-tile>
  </mat-grid-list> -->

<mat-dialog-actions>
    <div class="session-popup">
    <table class="table">
        <tr>
            <td width="20%"><label>Evaluation</label></td>
            <td width="5%">:</td>
            <td width="20%">{{ data.dashboardData.EvaluationName }}</td>
        </tr>
        <tr>
            <td><label>Company</label></td>
            <td>:</td>
            <td>{{ data.dashboardData.CompanyName }}</td>
        </tr>
        <tr>
            <td><label>Business Unit</label></td>
            <td>:</td>
            <td>{{ data.dashboardData.BusinessUnitName }}</td>
        </tr>
        <tr>
            <td><label>Plant/Department</label></td>
            <td>:</td>
            <td>{{ data.dashboardData.PlantName }}<span *ngIf="data.dashboardData.DepartmentName">/</span>{{ data.dashboardData.DepartmentName }}</td>
        </tr>
        <tr>
            <td><label>Event Date</label></td>
            <td>:</td>
            <td>{{ data.dashboardData.Date }}</td>
        </tr>
        <tr>
            <td><label>Event Code</label></td>
            <td>:</td>
            <td>{{ data.dashboardData.EventCode }}</td>
        </tr>
        <tr>
            <td><label>Evaluation Type</label></td>
            <td>:</td>
            <td>{{ data.dashboardData.EvaluationType }}</td>
        </tr>
    </table>
    <div class="row threepop" *ngIf="data.dashboardData.DisplayCount">
        <div class="col-md-4 assigned"><h4>Assigned - {{ data.dashboardData.Assigned }}</h4></div>
        <div class="col-md-4 completed"><h4>Completed - {{ data.dashboardData.Completed }}</h4></div>
        <div class="col-md-4 pending"><h4>Pending - {{ data.dashboardData.InProgress }}</h4></div>
    </div>
      
</div>
    <!-- <ul >
        <li><label>Evaluation</label></li>
        <li><label>Company</label></li>
        <li><label>Business</label></li>
        <li><label>Plant/Department</label></li>
        <li><label>Date</label></li>
        <li><label>Event Code</label></li>
        <li><label>Evaluation Type</label></li>
    </ul> -->
   <div class="btns-grup">
    <span class="pull-left"><button mat-stroked-button color="primary" mat-dialog-close (click)="assignPend()">Yes, Continue</button></span>
    <span class="pull-right"><button mat-stroked-button color="warn" mat-dialog-close (click)="logOut()">LogOut</button></span></div>   
</mat-dialog-actions>
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { JuryEvaluationService } from '../services/JuryEvaluation/jury-evaluation.service';
import { RequestService } from '../services/request/request.service';
import { IRequest } from './request';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.css']
})
export class RequestComponent implements OnInit {

  displayedColumns: string[] = ['sno','requestDate', 'eventCode', 'businessUnit', 'employeeId', 'employeeName', 'juryName', 'remarks', 'status'];
  dataSource = new MatTableDataSource;
  requests: IRequest[];

  constructor(private router: Router, private requestlistService: RequestService, private toastr: ToastrService, private evaluationapi: JuryEvaluationService, private snackBar: MatSnackBar) { }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  
  token: any;

  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.token = window.localStorage.getItem('token');
    if(!this.token){
      this.router.navigate(['']);
    }

    this.dataSource.paginator = this.paginator;

    this.requestlistService.requestData().subscribe((data: IRequest[]) => {
      this.requests = data;
      this.dataSource.data = data;
    });

  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

  ch_act(event)
  {
    if(event.target.value != '')
    {
      const formData = new FormData();
      formData.append('req_id', event.target.value);
      
      this.evaluationapi.reqpermission(formData).subscribe((data) => {
        if(data.resp) {
          let snackBarRef = this.snackBar.open(data.message,'', { duration : 3000, horizontalPosition: 'center', verticalPosition: 'top' });
        } else {
          let snackBarRef = this.snackBar.open('Something went wrong. Please try after some time.','', { duration : 3000, horizontalPosition: 'center', verticalPosition: 'top' });
        }
      });
    }
    
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IBusinessUnit } from '../business-unit/businessunit';
import { BusinessunitlistService } from '../services/businessunit/businessunitlist.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-business-unit-list',
  templateUrl: './business-unit-list.component.html',
  styleUrls: ['./business-unit-list.component.css']
})
export class BusinessUnitListComponent implements OnInit {

  displayedColumns: string[] = ['sno','companyId', 'businessUnitName', 'businessUnitCode', 'businessUnitId'];
  dataSource = new MatTableDataSource;
  businessunit: IBusinessUnit[];
  constructor(private router: Router, private businessunitlistService: BusinessunitlistService, private toastr: ToastrService) { }
  
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  token: any;
  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.token = window.localStorage.getItem('token');
    if(!this.token){
      this.router.navigate(['']);
    }

    this.dataSource.paginator = this.paginator;

    this.businessunitlistService.businessUnitData().subscribe((data: IBusinessUnit[]) => {
      this.businessunit = data;
      this.dataSource.data = data;
    });

  }

  delete(businessunit: IBusinessUnit):void {
    this.businessunitlistService.deletebusinessUnit(businessunit.businessUnitId ).subscribe(data => {
      this.businessunit = this.businessunit.filter(u => u !== businessunit)

      this.dataSource = new MatTableDataSource(this.businessunit);
      this.dataSource.paginator = this.paginator;

      this.toastr.success('Business Unit Deleted Successfully');
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

}

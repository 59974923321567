import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators, AsyncValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { CompanyService } from '../services/company/company.service';
import { ToastrService } from 'ngx-toastr';
import { UserValidators } from '../services/custom_validation';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {
  
  message: any;
  pageAct: string = "Create";
  hiddenId: boolean = false;
  companyForm: FormGroup;
  constructor(private fb: FormBuilder, 
              private router:Router, 
              private companyapi: CompanyService, 
              private route: ActivatedRoute,
              private toastr: ToastrService, 
              private service: UserValidators) { }
  token: any;
  ngOnInit(): void {
    if(window.localStorage.getItem('admin_access') == "1") {
      this.router.navigate(['/Dashboard']);
    }
    this.token = window.localStorage.getItem('token');
    if(!this.token){
      this.router.navigate(['']);
    }

    if(this.route.snapshot.paramMap.get('id'))
    {
      let id = parseInt(this.route.snapshot.paramMap.get('id'));
      this.pageAct = "Update";
      this.hiddenId = true;
      this.companyapi.getCompany(id).subscribe((data: any) => {
        this.companyForm.patchValue(data);
      })

      this.companyForm = this.fb.group({
        companyId: [''],
        companyName: ['', [Validators.required]],
        companyCode: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9]*$')], this.service.companyEditValidator(id)]
      });
      
    } else {
      this.pageAct = "Create";
      this.hiddenId = false;

      this.companyForm = this.fb.group({
        companyName: ['', [Validators.required]],
        companyCode: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9]*$')], this.service.companyValidator()]
      });
      
    }
    
  }

  get errCompanyName() { return this.companyForm.get('companyName'); }
  get errCompanyCode() { return this.companyForm.get('companyCode'); }

  submitCompany(act){
    if(this.companyForm.invalid)
    {
      return;
    }
    if(act == "Create") {
      this.companyapi.addCompany(this.companyForm.value)
      .subscribe(data => {
        this.router.navigate(['/Company_List']);
        this.toastr.success('Company Added Successfully');
      })
    }
    if(act == "Update") {
      this.companyapi.updateCompany(this.companyForm.value).subscribe(() => {
        this.router.navigate(['/Company_List']);
        this.toastr.success('Company Updated Successfully');
      })
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breadcrums',
  templateUrl: './breadcrums.component.html',
  styleUrls: ['./breadcrums.component.css']
})
export class BreadcrumsComponent implements OnInit {

  breadcrumb: string = "Dashboard";
  isAdmin: boolean;
  isJury: boolean;
  tokenact: any;
  
  constructor(private router: Router) { 
    router.events.subscribe(val => {
      let pageName = this.router.url.split("/");
      this.breadcrumb = pageName[1].replace(/_/g, ' ');
    })
  }
  
  ngOnInit(): void {

    this.tokenact = window.localStorage.getItem('tokenact');

    if(this.tokenact == "admin"){
      this.isAdmin = true;
      this.isJury = false;
    } else if(this.tokenact == "jury"){
      this.isAdmin = false;
      this.isJury = true;
    }
    
  }
  
}
